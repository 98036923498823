import ContextMenu from '../../components/ContextMenu';
import styles from './FileCard.module.scss';
import { Icon } from '@mui/material';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { openTextInputModal } from '../../graphql/cache/modal';
import { useCurrentUser } from '../../providers/UserProvider';
import classNames from 'classnames';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { printableTypes } from '../../constants/strings';

const updateFileMutation = loader(
  '../../components/files/FileCard.updateFile.graphql',
);
const updateDirectoryMutation = loader(
  '../../components/files/FileCard.updateDirectory.graphql',
);
const deleteFileMutation = loader(
  '../../components/files/FileCard.deleteFile.graphql',
);
const deleteDirectoryMutation = loader(
  '../../components/files/FileCard.deleteDirectory.graphql',
);

export default function FileOptionsMenu({
  file,
  simple = false,
  openDetails = () => {},
  refetchFiles = () => {},
  readOnly = false,
  card = true,
  onDelete,
  onRename,
}) {
  const { t } = useTranslation();
  const { isWorkspaceAdmin } = useWorkspace();
  const { user } = useCurrentUser();
  const [updateFile] = useMutation(updateFileMutation);
  const [updateDirectory] = useMutation(updateDirectoryMutation);
  const [deleteFile] = useMutation(deleteFileMutation);
  const [deleteDirectory] = useMutation(deleteDirectoryMutation);
  const [open, setOpen] = useState(false);
  const { openModal, openConfirmationModal } = useModal();

  const isDirectory = !file.url;

  const renameOption = {
    title: t(keys.files.RENAME, { variable: null }),
    icon: 'edit',
    disabled: !(isWorkspaceAdmin || file?.creator?.id === user.id) && !simple,
    onClick: () => {
      openTextInputModal({
        title: t(keys.files.RENAME, { variable: file.name }),
        inputs: ['File Name'],
        submitText: t(keys.action.SUBMIT),
        onSubmit: (value) => {
          if (onRename) {
            onRename(value['File Name']);
          } else {
            if (isDirectory) {
              updateDirectory({
                variables: { id: file.id, name: value['File Name'] },
              }).then(() => refetchFiles());
            } else {
              updateFile({
                variables: {
                  id: file.id,
                  name: value['File Name'],
                },
              }).then(() => refetchFiles());
            }
          }
        },
      });
    },
  };

  const downloadOption = {
    title: t(keys.action.DOWNLOAD),
    icon: 'download',
    disabled: !file?.downloadAllowed,
    onClick: () => {
      openConfirmationModal({
        title: t(keys.action.DOWNLOAD_VARIABLE, { variable: file.name }),
        description: t(keys.action.DOWNLOAD_CONFIRMATION, {
          variable: file.name,
        }),
        variant: 'warning',
        onSubmit: () => {
          const downloadFile = (url, name) => {
            fetch(url)
              .then((response) => response.blob())
              .then((blob) => {
                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                const awsExtension = new URL(file.url).pathname.split('.')[1];
                if (name.split('.').length > 1) {
                  link.download = name;
                } else {
                  link.download = name + '.' + awsExtension;
                }
                link.href = objectUrl;
                link.click();
                URL.revokeObjectURL(objectUrl);
              })
              .catch((error) => console.error(error));
          };
          downloadFile(file.url, file.name);
        },
      });
    },
  };

  const printOption = {
    title: t(keys.files.PRINT),
    icon: 'print',
    disabled: !printableTypes.includes(file?.fileType),
    onClick: () => {
      const iframe = document.createElement('iframe');
      iframe.src = file.url;
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      };
    },
  };

  const uploadRevisionOption = {
    title: t(keys.files.UPLOAD_REVISION),
    icon: 'cloud_upload',
    disabled: !(isWorkspaceAdmin || file?.creator?.id === user.id),
    onClick: () => {
      openModal({
        modalName: modals.fileUpload,
        variables: {
          modalTitle: t(keys.files.UPLOAD_REVISION),
          onSubmit: ({
            url,
            size,
            fileType,
            extension,
            name,
            expires,
            vehicleId,
            userId,
            status,
            downloadAllowed,
            type,
            workspaceId,
          }) =>
            updateFile({
              variables: {
                id: file.id,
                url,
                size,
                extension,
                fileType,
                name,
                userId,
                vehicleId,
                expires: expires
                  ? moment(parseInt(expires)).format('YYYY-MM-DD')
                  : null,
                downloadAllowed,
                status,
              },
            }),
        },
      });
    },
  };

  const deleteOption = {
    title: t(keys.action.DELETE),
    icon: 'delete',
    variant: 'red',
    disabled: !(isWorkspaceAdmin || file?.creator?.id === user.id) && !simple,
    onClick: () => {
      openConfirmationModal({
        title: t(keys.action.DELETE_VARIABLE, { variable: file.name }),
        description: `${t(keys.action.DELETE_CONFIRMATION, {
          variable: file.name,
        })} ${!file.url ? t(keys.files.DELETE_DIRECTORY_WARNING) : ''}`,
        variant: 'danger',
        buttonText: t(keys.action.DELETE),
        onSubmit: () => {
          if (onDelete) {
            onDelete();
          } else {
            if (isDirectory) {
              deleteDirectory({
                variables: {
                  deleteDirectoryId: file.id,
                },
              }).then(() => refetchFiles());
            } else {
              deleteFile({
                variables: {
                  deleteFileId: file.id,
                },
              }).then(() => refetchFiles());
            }
          }
        },
      });
    },
  };
  const detailsOption = {
    title: t(keys.common.DETAILS),
    icon: 'info',
    onClick: () => openDetails(),
  };

  const contextMenuOptions = useMemo(() => {
    if (isDirectory) {
      return [renameOption, deleteOption];
    }
    if (simple) {
      return readOnly
        ? [downloadOption]
        : [renameOption, deleteOption, downloadOption];
    } else {
      return [
        downloadOption,
        printOption,
        uploadRevisionOption,
        renameOption,
        deleteOption,
        detailsOption,
      ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, simple]);

  return (
    <div
      className={classNames(styles.contextMenuContainer, card && styles.card)}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(!open);
      }}
    >
      <Icon className={styles.contextMenuIcon}>more_horiz</Icon>
      <ContextMenu
        className={styles.contextMenu}
        open={open}
        options={contextMenuOptions}
        setOpen={setOpen}
      />
    </div>
  );
}
