import React from 'react';
import './App.scss';
import Auth0Login from './pages/Auth0Login';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ApiProvider from './graphql/ApiProvider';
import Register from './pages/Register';
import packageJson from '../package.json';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import WebApp from './WebApp';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateVehicle from './pages/fleet/CreateVehicle';
import Inspection from './pages/inspection_page/Inspection';
import CreateQuestions from './pages/fleet/CreateQuestions';
import VehicleDetailsPage from './pages/fleet/VehicleDetailsPage';
import Auth0Landing from './pages/Auth0Landing';
import { NotFound404 } from './pages/NotFound404';
import Inspections from './pages/fleet/Inspections';
import { paths } from './constants/strings';
import VehiclesPage from './pages/fleet/VehiclesPage';
import SettingsTabSystem from './pages/SettingsTabSystem';
import Incidents from './pages/incidents/Incidents';
import IncidentReport from './pages/incidents/IncidentReport';
import FilesPage from './pages/files/FilesPage';
import ErrorBoundaryPage from './pages/ErrorBoundaryPage';
import WorkspaceDetailsPage from './pages/workspace/WorkspaceDetailsPage';
import WorkspaceRoutes from './WorkspaceRoutes';
import Analytics from './pages/analytics/Analytics';
import Boards from './pages/analytics/Boards';
import Board from './pages/analytics/Board';
import Timecards from './pages/hr/Timecards.jsx';
import AddTimecard from './pages/hr/AddTimecard';
import Expenses from './pages/hr/Expenses.jsx';
import Expense from './pages/hr/Expense';
import { useOnlineStatus } from './hooks/offline-hooks/offline-misc.js';
import { UserProvider } from './providers/UserProvider.jsx';
import ErrorAccountNotFound from './pages/ErrorAccountNotFound.jsx';
import Announcements from './pages/announcements/Announcements.jsx';
import CompanySignUp from './pages/CompanySignUp/CompanySignUp.jsx';
import CompanyRegistration from './pages/CompanyRegistration/CompanyRegistration.jsx';
import SignOut from './pages/SignOut.jsx';
import UserProfile from './pages/user/UserProfile.jsx';
import Tasks from './pages/Tasks/Tasks.jsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DashboardV2 from './pages/dashboard/desktop/DashboardV2.jsx';
import Observations from './pages/observations/Observations.jsx';
import ObservationPage from './pages/observations/ObservationPage.jsx';
import Toolbox from './pages/toolbox_talks/Toolbox.jsx';
import ToolboxTalkGenerator from './pages/toolbox_talks/ToolboxTalkGenerator.jsx';
import Audits from './pages/audit/Audits.jsx';
import ActionItemList from './pages/action_items/ActionItemList.jsx';
import CPAPage from './pages/audit/CPAPage.jsx';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
  appVersion: packageJson.version,
  notifyReleaseStages: ['production', 'staging'],
  appType: 'client',
  onError: function (event) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    event.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`);
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function App() {
  const online = useOnlineStatus();
  const { enableCompanySignUpCaptcha } = useFlags();
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
      <ApiProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/403" element={<ErrorAccountNotFound />} />
            <Route path="/login" element={<Auth0Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/signout" element={<SignOut />} />
            <Route
              path="/company-sign-up"
              element={
                enableCompanySignUpCaptcha ? (
                  <CompanySignUp />
                ) : (
                  <CompanyRegistration />
                )
              }
            />
            <Route
              path="/"
              element={
                <UserProvider>
                  <WebApp />
                </UserProvider>
              }
            >
              <Route path={paths.announcements} element={<Announcements />} />
              <Route path="/redirect" element={<Auth0Landing />} />

              <Route
                path="/workspace/:workspaceId"
                element={<WorkspaceRoutes />}
              >
                <Route path={paths.dashboard} element={<DashboardV2 />} />
                <Route
                  path={paths.actionItems}
                  element={<ActionItemList disabled={!online} />}
                />
                <Route path={paths.assessments} element={<Inspections />} />
                <Route path={paths.incidents} element={<Incidents />} />
                <Route
                  path={paths.files}
                  element={<FilesPage disabled={!online} />}
                />
                <Route
                  path={paths.assets}
                  element={<VehiclesPage disabled={!online} />}
                />
                <Route path="asset/create" element={<CreateVehicle />} />
                <Route path="asset/:id" element={<VehicleDetailsPage />} />
                <Route path="assessment/:id" element={<Inspection />} />
                <Route path={paths.observations} element={<Observations />} />
                <Route
                  path={`${paths.observation}/:id`}
                  element={<ObservationPage />}
                />
                <Route path="incident/:id" element={<IncidentReport />} />
                <Route
                  path="incidentTemplate/:id"
                  element={<CreateQuestions />}
                />
                <Route path={paths.announcements} element={<Announcements />} />
                <Route
                  path={`${paths.expenses}/:tab`}
                  element={<Expenses disabled={!online} />}
                />
                <Route
                  path="workspacesettings"
                  element={<WorkspaceDetailsPage />}
                />
                <Route path="report/:id" element={<Inspection />} />
                <Route path="templates/:id" element={<CreateQuestions />} />
                <Route
                  path="files/:type"
                  element={<FilesPage disabled={!online} />}
                />
              </Route>
              <Route path="" element={<Navigate to="/redirect" replace />} />
              <Route
                path={paths.timecards}
                element={<Navigate to="/timecards/personal" replace />}
              />
              <Route
                path={`${paths.timecards}/:tab`}
                element={<Timecards disabled={!online} />}
              />
              <Route path={`${paths.timecard}`} element={<AddTimecard />} />
              <Route
                path={paths.expenses}
                element={<Navigate to="/expenses/personal" replace />}
              />
              <Route
                path={`${paths.expenses}/:tab`}
                element={<Expenses disabled={!online} />}
              />
              <Route path={`${paths.expense}`} element={<Expense />} />
              <Route path={paths.tasks} element={<Tasks />} />
              <Route
                path={paths.settings}
                element={<Navigate to="/settings/profile" replace />}
              />
              <Route
                path="settings/:category"
                element={<SettingsTabSystem />}
              />
              <Route path={paths.analytics} element={<Boards />} />
              <Route path="cpa/:id" element={<CPAPage />} />
              <Route path={paths.audits} element={<Audits />} />
              <Route
                path={`${paths.analytics}/board/:boardId`}
                element={<Board />}
              />
              <Route path="user/:id" element={<UserProfile />} />
              <Route
                path={`${paths.analytics}/addgraph`}
                element={<Analytics />}
              />
              <Route path={paths.toolbox} element={<Toolbox />} />
              <Route
                path={`${paths.toolbox}/:id`}
                element={<ToolboxTalkGenerator />}
              />
            </Route>
            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </BrowserRouter>
      </ApiProvider>
    </ErrorBoundary>
  );
}

export default App;
