import Signature from 'react-signature-canvas';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import styles from './SignatureModal.module.scss';
import { Text } from '../typography';
import {
  defaultSignatureModalState,
  signatureModalVar,
} from '../../graphql/cache/modal';
import Button from '../Button';
import { useRef, useState, useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useApolloClient } from '@apollo/client';
import { CF_DOMAIN } from '../../constants/aws';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useCurrentUser } from '../../providers/UserProvider';
const uploadUrlQuery = loader('../../graphql/queries/s3.graphql');

export default function SignatureModal({
  show = false,
  title,
  variant,
  message,
  onSubmit,
  buttonText,
  additionalInput = null,
}) {
  const client = useApolloClient();
  const { user } = useCurrentUser();
  const [onSign, setOnSign] = useState(true);
  const signatureRef = useRef();

  const getSignatureUrl = async () => {
    const fileUUID = uuidv4();
    const fileName = fileUUID;
    const fileType = 'png';
    const sigCanvas = signatureRef?.current?.getCanvas();

    const {
      data: { simpleStorageUploadUrl: signedUrl },
    } = await client.query({
      query: uploadUrlQuery,
      variables: {
        fileName: `assets/${user.company.id}/${fileName}.${fileType}`,
        fileType,
      },
    });

    const options = {
      headers: {
        'Content-Type': fileType,
      },
    };

    const sig = await new Promise((resolve) => {
      sigCanvas.toBlob((blob) => {
        const file = new File([blob], `${fileName}.${fileType}`);
        axios.put(signedUrl, file, options).then((result) => {
          if (result.status === 200) {
            const newSignature = `${CF_DOMAIN(user)}${fileName}.${fileType}`;

            resolve(newSignature);
          }
        });
      });
    });

    return sig;
  };

  const clearSignature = () => {
    signatureRef?.current?.clear();
  };

  const onClose = () => {
    clearSignature();
    setOnSign(true);
    signatureModalVar({ ...signatureModalVar, show: false });
  };

  useEffect(() => {
    setTimeout(() => {
      if (!show) {
        signatureModalVar(defaultSignatureModalState);
        onClose();
      }
    }, 1000); // Time delay for animation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return show ? (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.header}>
            <Text
              className={styles.header}
              size="lg"
              weight="semiBold"
              noMargin
            >
              {title}
            </Text>
            <Icon
              className={styles.closeIcon}
              onClick={() => {
                signatureModalVar({
                  ...signatureModalVar(),
                  show: false,
                });
                onClose();
              }}
            >
              close
            </Icon>
          </div>
          <div>
            {additionalInput}
            <Text color="secondary">{message}</Text>
            <div id="addBlock">
              <Signature
                penColor="black"
                ref={signatureRef}
                canvasProps={{
                  className: styles.signature,
                  clearonresize: 'false',
                }}
                onBegin={() => setOnSign(false)}
              />
            </div>
          </div>
          <Button
            className={styles.button}
            variant={variant}
            value={buttonText}
            disabled={onSign}
            onClick={() => {
              getSignatureUrl().then((url) => {
                onSubmit(url);
                onClose();
              });
            }}
          />
        </div>
      </div>
    </div>
  ) : null;
}
