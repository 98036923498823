import React, { createContext, useState, useEffect } from 'react';

const defaultUser = {
  sub: 'auth0|default',
  name: 'Default User',
  email: 'defaultuser@example.com',
};

export const Auth0Context = createContext();

export const Auth0ProviderMock = ({ children }) => {
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userJson = params.get('user');

    if (userJson) {
      try {
        const userData = JSON.parse(decodeURIComponent(userJson));
        setUser(userData);
      } catch (error) {
        console.error('Error parsing user data from URL:', error);
      }
    }
  }, []);

  const contextValue = {
    isAuthenticated: true,
    user,
    isLoading: false,
    getAccessTokenSilently: () => Promise.resolve('fake-token'),
    loginWithRedirect: () => Promise.resolve(),
    logout: () => Promise.resolve(),
  };

  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  );
};

export const useAuth0 = () => React.useContext(Auth0Context);
