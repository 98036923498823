import { commentStyles } from '../styles';
import { styles } from '../constants';
import { Text, View, Image, Link } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { keys } from '../../translator/translation_keys';

export const AssessmentComments = ({ answer }) => {
  const comments = answer?.comments || [];
  return comments?.length ? (
    <View style={commentStyles.commentContainer}>
      {comments.map((comment, index) => (
        <View key={index} style={commentStyles.comment}>
          <Text style={commentStyles.semiBold}>
            {`${comment.creator?.firstName} ${comment.creator?.lastName}`}
          </Text>
          {comment.attachmentUrl ? (
            <Link style={commentStyles.commentLink} src={comment.attachmentUrl}>
              {comment.text}
            </Link>
          ) : (
            <Text style={commentStyles.text}>{comment.text}</Text>
          )}
        </View>
      ))}
    </View>
  ) : null;
};

export const FailReport = ({ answer }) => {
  const { t } = useTranslation();
  return answer?.failed && !!answer?.failNotes?.length ? (
    <View style={commentStyles.failReport}>
      <View>
        <Text style={commentStyles.red}>{answer.failNotes}</Text>
      </View>
      <View>
        <Text style={commentStyles.text}>{`Action Item Assigned: ${
          !!answer.user
            ? `${answer.user.firstName} ${answer.user.lastName}`
            : t(keys.common.NONE)
        }`}</Text>
      </View>
      <View>
        {!!answer.failImage && (
          <Image style={styles.failImage} src={answer.failImage} />
        )}
      </View>
    </View>
  ) : null;
};
