import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import styles from './Roles.module.scss';
import { useURLParams } from '../../providers/URLParamProvider';
import { loader } from 'graphql.macro';
import { NetworkStatus, useQuery } from '@apollo/client';
import MTTable from '../../components/tables/MTable';
import { useEffect, useMemo } from 'react';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import SimpleUserCard from '../../components/SimpleUserCard';
import { Spinner } from 'react-bootstrap';
import { useTableComponents } from '../../components/tables/MTableComponents';

const rolesQuery = loader('./RoleRequirementPage.getAllRolesFilters.graphql');

export default function Roles() {
  const { page, pageSize, filters, sort, subfilters } = useURLParams();
  const { t } = useTranslation();
  const { openModal, modalState } = useModal();
  const { userFilterOptions, textCell } = useTableComponents();

  const {
    refetch,
    networkStatus,
    loading,
    previousData: { rolesCount: oldCount } = {},
    data: { roles = [], rolesCount = 0 } = {},
  } = useQuery(rolesQuery, {
    skip: !page || !pageSize || !sort,
    variables: {
      options: {
        page,
        pageSize,
        filters,
        subfilters,
        sort,
      },
    },
  });

  const tableColumns = useMemo(
    () => [
      {
        accessorFn: (row) => row?.title,
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        minSize: 150,
        grow: true,
        enableSorting: false,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorFn: (row) => row?.description,
        accessorKey: 'description',
        header: t(keys.common.DESCRIPTION),
        minSize: 150,
        grow: true,
        enableSorting: false,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        grow: true,
        size: 150,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('workspace'),
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
    ],
    [t, userFilterOptions, textCell],
  );

  const returnTable = () => {
    return (
      <div className={styles.tableContainer}>
        <MTTable
          className={styles.tableContainer}
          isLoading={networkStatus !== NetworkStatus.ready}
          data={roles || []}
          columns={tableColumns || 0}
          onRowClick={(role) => {
            openModal({
              modalName: modals.role,
              variables: {
                roleId: role.id,
                roleTitle: role.title,
                roleDescription: role.description,
              },
            });
          }}
          rowCount={rolesCount || oldCount}
        />
      </div>
    );
  };

  const { show } = modalState.roleModal;
  useEffect(() => !show && refetch && refetch(), [show, refetch]);

  return (
    <div>
      {loading ? (
        <Spinner
          className={styles.spinner}
          animation="border"
          variant="primary"
        />
      ) : (
        returnTable()
      )}
    </div>
  );
}
