import React from 'react';
import { Page, Document, Text, Image, View, Link } from '@react-pdf/renderer';
import { styles, PageHeader, PageHeaderLogo, PageFooter } from './constants.js';
import { formatTime, getLocalTime } from '../time.js';
import { incidentDetailQuestions } from '../incidents.js';
import { paths } from '../../constants/strings.js';
import { getRoute } from '../../constants/strings.js';
import { Notes } from './constants.js';
import { appEndpoints } from '../../constants/endpoints.js';
import { isProd } from '../misc.js';
import { useTranslation } from 'react-i18next';
import { keys } from '../translator/translation_keys.js';
import { getTranslationKey } from '../translator/translation_keys.js';

export const IncidentDocument = ({
  incident,
  incidentImages,
  inspections,
  workspace,
}) => {
  const {
    creator: { company },
  } = incident;
  const { t, i18n, ready } = useTranslation();
  const language = i18n.resolvedLanguage;
  const dropdownKeys = i18n.getResourceBundle(
    language,
    'incidents',
  ).dropdownOptions;

  const translate = (values) => {
    let newValues = [];
    if (language !== 'en') {
      values.forEach((val) => {
        newValues.push(dropdownKeys[val]);
      });
      return newValues.join(', ');
    }
    return values.join(', ');
  };

  const convertBoolean = (value) => {
    if (value === true) {
      return t(keys.common.YES);
    }
    if (value === false) {
      return t(keys.common.NO);
    }
    return dropdownKeys[value] ? dropdownKeys[value] : value;
  };

  const formatValue = (type, value) => {
    if (value) {
      switch (type) {
        case 'DATE':
          return <Text style={styles.semiBoldText}>{formatTime(value)}</Text>;
        case 'SELECT_MULTI':
          return (
            <Text style={styles.semiBoldText}>
              {translate(value?.split('|'))}
            </Text>
          );
        case 'USER':
          return (
            <Text style={styles.semiBoldText}>
              {`${value?.firstName} ${value?.lastName}`}
            </Text>
          );
        case 'SIGNATURE':
          return <Image style={styles.signature} src={value} />;
        default:
          return (
            <Text style={styles.semiBoldText}>{convertBoolean(value)}</Text>
          );
      }
    } else {
      return (
        <Text style={styles.semiBoldText}>
          {value === false ? t(keys.common.NO) : t(keys.common.NONE)}
        </Text>
      );
    }
  };

  return (
    incident &&
    ready && (
      <Document>
        <Page size="A4" style={styles.page} wrap>
          <PageHeaderLogo company={company} />
          <PageHeader
            company={company}
            data={incident}
            title={incident.title}
            workspace={workspace}
          />
          <View>
            <Text style={styles.titleHeader}>{incident.title}</Text>
          </View>
          <View style={styles.section} wrap={false}>
            <Text style={styles.categoryHeader}>
              {t(keys.incidents.INCIDENT_DETAILS)}
            </Text>
            <View style={styles.row} wrap={false}>
              <Text style={styles.text}>{t(keys.incidents.TYPE)}</Text>
              <Text style={styles.semiBoldText}>{`${t(
                getTranslationKey(incident.type, 'incidents'),
              )} - ${t(
                getTranslationKey(incident.subtype, 'incidents'),
              )}`}</Text>
            </View>
            {incidentDetailQuestions.map((value, index) => (
              <View style={styles.row} key={`${value}-${index}`}>
                <Text style={styles.text}>{t(value.title)}</Text>
                <Text style={styles.semiBoldText}>
                  {formatValue(value.inputType, incident[value.convertToQuery])}
                </Text>
              </View>
            ))}
          </View>
          {incident?.reviewers?.length && (
            <View style={styles.section} wrap={false}>
              <Text style={styles.categoryHeader}>
                {t(keys.incidents.REVIEWER_INFORMATION)}
              </Text>
              <View style={styles.row}>
                <Text style={styles.text}>
                  {t(keys.incidents.REVIEWED_DATE)}
                </Text>
                <Text style={styles.semiBoldText}>
                  {getLocalTime(incident?.reviewers[0].dateModified).format(
                    'dddd, MMMM Do YYYY',
                  )}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.text}>{t(keys.incidents.REVIEWED_BY)}</Text>
                <Text style={styles.semiBoldText}>
                  {`${incident?.reviewers[0]?.user.firstName} ${incident?.reviewers[0]?.user?.lastName}`}
                </Text>
              </View>
            </View>
          )}

          <View style={styles.section} wrap={false}>
            <Text style={styles.categoryHeader}>
              {t(keys.incidents.INCIDENT_CLOSURE_DETAILS)}
            </Text>
            <View style={styles.row}>
              <Text style={styles.text}>
                {t(keys.incidents.DATE_INCIDENT_CLOSED)}
              </Text>
              <Text style={styles.semiBoldText}>
                {getLocalTime(incident?.closedAt).format('dddd, MMMM Do YYYY')}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.text}>{t(keys.incidents.CLOSED_BY)}</Text>
              <Text style={styles.semiBoldText}>
                {incident?.closer?.firstName} {incident?.closer?.lastName}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.text}>
                {t(keys.incidents.CLOSER_SIGNAURE)}
              </Text>
              <Image
                src={`${incident.closerSignatureUrl}`}
                style={styles.signature}
              ></Image>
            </View>
          </View>
          <View break style={styles.section}>
            <Text style={styles.categoryHeader}>
              {t(keys.incidents.INCIDENT_IMAGES)}
            </Text>
            {incidentImages?.length ? (
              incidentImages?.map((image, index) => (
                <View
                  wrap={false}
                  style={styles.row}
                  key={`${image.id}-${index}`}
                >
                  <Text style={styles.semiBoldTextHeader}>
                    {image.description ??
                      t(keys.incidents.NO_IMAGE_DESCRIPTION)}
                  </Text>
                  <Image src={image.imageUrl} style={styles.image} />
                </View>
              ))
            ) : (
              <Text style={styles.text}>
                {t(keys.incidents.NO_INCIDENT_IMAGES)}
              </Text>
            )}
          </View>
          <View style={styles.section}>
            <Text style={styles.categoryHeader}>
              {t(keys.incidents.INCIDENT_ASSESSMENTS)}
            </Text>

            {inspections?.length ? (
              inspections?.map((inspection, index) => (
                <View style={styles.row} key={`${inspection.id}-${index}`}>
                  <Text style={styles.text}>
                    {`${inspection.creator.firstName} ${
                      inspection.creator.lastName
                    }, ${getLocalTime(inspection.dateCreated).format(
                      'dddd, MMMM Do YYYY',
                    )}`}
                  </Text>
                  <Link
                    key={`${inspection} + ${index}`}
                    style={styles.linkLeft}
                    src={
                      appEndpoints[isProd ? 'prod' : 'dev'] +
                      getRoute(
                        inspection.workspaceId,
                        paths.assessment,
                        inspection.id,
                      )
                    }
                  >
                    {inspection.template.title}
                  </Link>
                </View>
              ))
            ) : (
              <Text style={styles.text}>
                {t(keys.incidents.NO_INCIDENT_ASSESSMENTS)}
              </Text>
            )}
            <Notes notes={incident?.notes} style={{ marginTop: 3 }} />
          </View>
          <PageFooter />
        </Page>
      </Document>
    )
  );
};
