import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { Button } from '../../components';
import icons from '../../assets/icons';
import styles from './EditUsers.module.scss';
import classNames from 'classnames';
import { appEndpoints } from '../../constants/endpoints';
import { isProd } from '../../utilities';
import { Text } from '../../components/typography';
import { showToast } from '../../graphql/cache/modal';
import { toastLength } from '../../constants/misc';
import { emailRegex } from '../../utilities/validation';
import { userRoles, userRoleStrings } from '../../constants/strings';
import { cache } from '../../graphql/cache';
import DropdownCombo from '../../components/dropdowns/DropdownCombo';
import { Icon } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { toastVariant } from '../../constants/misc';
import { dropdownTypes } from '../../components/dropdowns/dropdown';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useModal } from '../../providers/ModalProvider';
import UsersTable from '../AdminConsole/UsersTable';

const userQuery = loader('./EditUsers.graphql');
const companyQuery = loader('./EditCompany.graphql');
const deleteInviteMutation = loader('./EditUsers.deleteInvite.graphql');
const inviteMutation = loader('../../graphql/mutations/user_invite.graphql');
const updateinviteMutation = loader('./EditUsers.updateInvite.graphql');

function InviteCard({ invite, deleteInvite, link }) {
  const { enableActivities, enableTimecards } = useFlags();
  const [copied, setCopied] = useState(false);
  const { id, email, expires, access } = invite;

  const [newResourceId, setNewResourceId] = useState(null);
  const [newPayrollId, setNewPayrollId] = useState(null);
  const [resourceEdit, setResourceEdit] = useState('');
  const [payrollEdit, setPayrollEdit] = useState('');
  const [updateInvite] = useMutation(updateinviteMutation);
  const { t } = useTranslation();
  const { openConfirmationModal } = useModal();

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  return (
    <tr key={email}>
      <td className={styles.wrap}>{email}</td>
      <td>{new moment(parseInt(expires, 10)).fromNow()}</td>
      <td>{userRoleStrings[access]}</td>
      <td className={styles.wrap}>{invite.workspace?.title || 'None'}</td>
      <td>
        <div
          className={classNames(styles.smallSpacing, styles.linkContainer)}
          onClick={() => {
            const url = appEndpoints[isProd ? 'prod' : 'dev'] + link;
            console.log({ url }); // Here to support non SSL copy failures
            navigator.clipboard.writeText(url);
            setCopied(true);
          }}
        >
          {copied ? (
            <p>{t(keys.action.COPIED)}</p>
          ) : (
            <img alt="link" src={icons.black.link} className={styles.link} />
          )}
        </div>
      </td>
      {enableActivities && enableTimecards && (
        <>
          <td>
            {resourceEdit === invite.id ? (
              <div className={styles.p6Container}>
                <Icon
                  sx={{ fontSize: 'small' }}
                  baseClassName="material-icons-outlined"
                  onClick={() => setResourceEdit(null)}
                >
                  cancel
                </Icon>
                <Icon
                  className={styles.editIcon}
                  sx={{ fontSize: 'small' }}
                  onClick={() => {
                    updateInvite({
                      variables: { id: invite.id, resourceId: newResourceId },
                    }).then((errors) => {
                      setResourceEdit('');
                      if (errors?.length > 0) {
                        showToast({
                          variant: toastVariant.warning,
                          time: toastLength.md,
                          message:
                            'Check the user table below, the user may have already registered.',
                          title: 'Invite not found.',
                        });
                      }
                    });
                  }}
                >
                  save
                </Icon>
                <Form.Control
                  value={newResourceId}
                  onChange={(e) => setNewResourceId(e.target.value)}
                />
              </div>
            ) : (
              <div className={styles.p6Container}>
                <Icon
                  className={styles.editIcon}
                  sx={{ fontSize: 'small' }}
                  onClick={() => {
                    setResourceEdit(invite.id);
                  }}
                >
                  edit
                </Icon>
                <Text noMargin>{invite.resourceId}</Text>
              </div>
            )}
          </td>
          <td>
            {payrollEdit === invite.id ? (
              <div className={styles.p6Container}>
                <Icon
                  sx={{ fontSize: 'small' }}
                  baseClassName="material-icons-outlined"
                  onClick={() => setPayrollEdit(null)}
                >
                  cancel
                </Icon>
                <Icon
                  className={styles.editIcon}
                  sx={{ fontSize: 'small' }}
                  onClick={() => {
                    updateInvite({
                      variables: {
                        id: invite.id,
                        payrollId: parseInt(newPayrollId),
                      },
                    }).then(({ errors }) => {
                      setPayrollEdit('');
                      if (errors?.length > 0) {
                        showToast({
                          variant: toastVariant.warning,
                          time: toastLength.md,
                          message:
                            'Check the user table below, the user may have already registered.',
                          title: 'Invite not found.',
                        });
                      }
                    });
                  }}
                >
                  save
                </Icon>
                <Form.Control
                  type="number"
                  value={newPayrollId}
                  onChange={(e) => setNewPayrollId(e.target.value)}
                />
              </div>
            ) : (
              <div className={styles.p6Container}>
                <Icon
                  className={styles.editIcon}
                  sx={{ fontSize: 'small' }}
                  onClick={() => {
                    setPayrollEdit(invite.id);
                  }}
                >
                  edit
                </Icon>
                <Text noMargin>{invite.payrollId}</Text>
              </div>
            )}
          </td>
        </>
      )}

      <td>
        <Button
          className={styles.tableButton}
          size="sm"
          icon="delete"
          variant="danger"
          outlined={true}
          onClick={() => {
            openConfirmationModal({
              title: t(keys.settings.REVOKE_INVITE),
              description: t(keys.settings.REVOKE_INVITE_CONFIRMATION, {
                variable: email,
              }),
              buttonText: t(keys.action.REVOKE),
              variant: 'danger',
              onSubmit: () => {
                deleteInvite({
                  variables: { id },
                }).then(() => {
                  cache.evict(cache.identify(invite));
                });
              },
            });
          }}
        />
      </td>
    </tr>
  );
}

export default function EditUsers() {
  const { openConfirmationModal } = useModal();
  const { data: { users = [], invites = [] } = {} } = useQuery(userQuery, {
    variables: {
      userOptions: {
        sort: [{ field: 'lastName', order: 'asc' }],
      },
      inviteOptions: {
        sort: [{ field: 'expires', order: 'desc' }],
      },
    },
  });
  const { availableWorkspaces } = useWorkspace();
  const [inviteAccess, setInviteAccess] = useState(userRoles.user);
  const [newUserWorkspace, setNewUserWorkspace] = useState(null);
  const { enableActivities, enableTimecards } = useFlags();
  const { t } = useTranslation();

  const { data: { company = {} } = {} } = useQuery(companyQuery);
  const [inviteUsers] = useMutation(inviteMutation, {
    refetchQueries: [userQuery, 'getUsersForSettings'],
    awaitRefetchQueries: true,
  });
  const [deleteInvite] = useMutation(deleteInviteMutation);
  const [inviteText, setInviteText] = useState('');
  const handleInvites = ({ goodEmails, badEmails }) => {
    inviteUsers({
      variables: {
        emails: goodEmails,
        access: inviteAccess,
        workspaceId: newUserWorkspace?.id,
      },
    }).then(({ data }) => {
      let results = data.inviteUsers.results;
      if (results?.length) {
        let errorEmails = [];
        // eslint-disable-next-line array-callback-return
        results.map((res) => {
          errorEmails.push(res.email);
        });
        badEmails.push(errorEmails.join(', '));
        showToast({
          title: `${t(keys.common.ERROR)}:` + results[0].reason,
          message: errorEmails.join(', ') + ` ${t(keys.settings.CANNOT_ADD)}`,
          variant: 'warning',
          time: toastLength.lg,
        });
      }
      badEmails.length
        ? setInviteText(badEmails.join(', '))
        : setInviteText('');
    });
  };

  const onSubmit = () => {
    const regex = new RegExp(emailRegex);
    const emails = inviteText
      .toLowerCase()
      .split(',')
      .map((email) => email.trim());
    const goodEmails = [];
    const badEmails = [];
    emails.forEach((email) => {
      if (regex.test(email)) {
        goodEmails.push(email);
      } else {
        badEmails.push(email);
      }
    });
    if (badEmails.length) {
      showToast({
        title: `Invalid email format`,
        message: `${badEmails.join(', ')} could not be added.`,
        variant: 'warning',
        time: toastLength.lg,
      });
    }
    if (company.registrationComplete && users?.length > 9) {
      openConfirmationModal({
        title: t(keys.action.REMINDER),
        description: t(keys.settings.BILLING_WARNING),
        variant: 'warning',
        buttonText: t(keys.action.CONTINUE),
        icon: 'paid',
        onSubmit: () => {
          handleInvites({ goodEmails, badEmails });
        },
      });
    } else {
      handleInvites({ goodEmails, badEmails });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <Text size="lg" weight="semiBold">
          {t(keys.action.ADD_VARIABLE, { variable: t(keys.common.USERS) })}
        </Text>
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            setInviteText('');
          }}
        >
          <Row className="mb-3">
            <Form.Group>
              <Form.Label>{t(keys.settings.EMAIL)}</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.settings.INVITE_PLACEHOLDER)}
                value={inviteText}
                onChange={(e) => setInviteText(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>{t(keys.common.ACCESS)}</Form.Label>
                <Form.Select
                  value={inviteAccess}
                  onChange={(e) => setInviteAccess(e.target.value)}
                  aria-label="Invite access select"
                >
                  <option value={userRoles.user}>
                    {userRoleStrings[userRoles.user]}
                  </option>
                  <option value={userRoles.admin}>
                    {userRoleStrings[userRoles.admin]}
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Label className="mb-0">
                {t(keys.common.WORKSPACE)}
              </Form.Label>
              <div className={styles.workspaceDropdown}>
                <DropdownCombo
                  type={dropdownTypes.WORKSPACE}
                  items={availableWorkspaces || []}
                  onChange={setNewUserWorkspace}
                  selected={newUserWorkspace}
                />
              </div>
            </Col>
          </Row>
          <br />
          <Button
            disabled={!inviteText || !newUserWorkspace}
            className={styles.addButton}
            value={t(keys.action.INVITE)}
            onClick={onSubmit}
          />
        </Form>
        <br />
        <Text size="lg" weight="semiBold">
          {t(keys.common.USER_HAS, { variable: t(keys.settings.INVITES) })}
        </Text>
        {invites?.length ? (
          <Table className={styles.scrollableTable} hover striped>
            <thead>
              <tr>
                <th className={styles.tableCell}>{t(keys.settings.EMAIL)}</th>
                <th>{t(keys.action.EXPIRES)}</th>
                <th>{t(keys.common.ACCESS)}</th>
                <th className={styles.tableCell}>{t(keys.common.WORKSPACE)}</th>
                <th>
                  {t(keys.action.COPY, { variable: t(keys.common.LINK) })}
                </th>
                {enableActivities && enableTimecards && (
                  <>
                    <th className={styles.tableCell}>Resource ID</th>
                    <th className={styles.tableCell}>Payroll ID</th>
                  </>
                )}
                <th style={{ minWidth: '5rem' }} />
              </tr>
            </thead>
            <tbody>
              {invites.map((invite) => (
                <InviteCard
                  key={`invite-${invite.id}`}
                  invite={invite}
                  deleteInvite={deleteInvite}
                  link={`/register?token=${
                    invite.token
                  }&email=${encodeURIComponent(invite.email)}&logo=${
                    company.logoUrl
                  }`}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <Text
            noMargin
            size="md"
            weight="semiBold"
            color="secondary"
            textAlign="center"
          >
            {t(keys.settings.NO_INVITES)}
          </Text>
        )}
        <br />
        <Text size="lg" weight="semiBold">
          {t(keys.common.USERS)}
        </Text>

        <UsersTable />
      </div>
    </div>
  );
}
