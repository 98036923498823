import moment from 'moment';
import 'moment/locale/fr-ca';
import i18n from '../i18n';

export function getLocalTime(timestamp) {
  if (typeof timestamp === 'string' && timestamp.includes('-')) {
    const m = new moment(timestamp);
    return m;
  }
  const epoch = parseInt(`${timestamp}`);
  const m = new moment(epoch);
  const currentLanguage = i18n.resolvedLanguage;
  moment.locale(currentLanguage || 'en');
  return m;
}

export async function sleep(milliseconds = 2500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}
export function getDayDescription(timestamp) {
  const time = getLocalTime(timestamp);
  const now = moment();

  if (time.isSame(now, 'day')) {
    return 'Today';
  } else if (time.isSame(now.clone().subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  } else {
    return time.format('DD/MM/YYYY');
  }
}

export function getDaysFromNow(date) {
  const endDate = getLocalTime(date);
  let diff = endDate.diff(moment(), 'days');
  let string = 'days';
  if (diff === 0) {
    diff = endDate.diff(moment(), 'hours');
    string = 'hours';
  }
  if (diff === 1) {
    string = 'day';
  }

  return `${diff < 0 ? 'Overdue' : 'Due in'} ${Math.abs(diff)} ${string}`;
}

export function formatTime(date, customOptions = {}) {
  let options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  let newDate = new Date(date);
  return newDate.toLocaleDateString(
    i18n.resolvedLanguage,
    customOptions || options,
  );
}
export const monthSelector = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];
