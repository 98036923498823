import React from 'react';
import TabSystem from '../../components/TabSystem';
import { Text } from '../../components/typography';
import styles from './Inspections.module.scss';
import { Button } from '../../components';
import InspectionsList from './InspectionsList';
import TemplatesList from './TemplatesList';
import { useQueryParams } from '../../hooks/misc';
import { assessmentTypes, searchParamKeys } from '../../constants/strings';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { loader } from 'graphql.macro';
import { gql } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { fetchAllData } from '../../utilities/excel_export/table_excel_export.js';
import { useState } from 'react';
import { showToast } from '../../graphql/cache/modal.js';
import { toastVariant } from '../../constants/misc.js';
import { exportToExcel } from '../../components/excel/ExcelExport.jsx';
import {
  excelHeaderLookup,
  formatAssessment,
} from '../../utilities/excel_export/data_converter.js';
import format from 'date-fns/format';
import ProgressModal from '../../components/modals/ProgressModal.jsx';
import { useURLParams } from '../../providers/URLParamProvider.jsx';

const defaultTab = 'assessments';
const inspectionExportQuery = loader('./Inspections.fetch.graphql');

export default function Inspections({ disabled = false }) {
  const { user } = useCurrentUser();
  const { getParam } = useQueryParams();
  const { openModal } = useModal();
  const tab = getParam(searchParamKeys.tab, defaultTab);
  const { filters, subfilters } = useURLParams();
  const { t } = useTranslation();
  const client = useApolloClient();
  const [isFetchingAllData, setIsFetchingAllData] = useState(false);
  const [progress, setProgress] = useState(0);
  const { workspace, availableWorkspaces, allWorkspaces } = useWorkspace();

  const handleExport = async () => {
    const query = gql`
      ${inspectionExportQuery}
    `;
    await fetchAllData({
      query,
      client,
      setIsFetching: setIsFetchingAllData,
      table: 'inspections',
      count: 'inspectionsCount',
      filters,
      subfilters,
      setProgress,
    }).then((response, errors) => {
      if (errors?.length) {
        showToast({
          title: 'Error Exporting Data',
          variant: toastVariant.danger,
          message: `An error occurred: ${errors[0].message}`,
        });
      }

      if (response?.length) {
        const data = response.map((r) => {
          const workspaceData = availableWorkspaces?.find(
            (w) => w.id === r.workspaceId,
          );
          return {
            ...r,
            workspace: workspaceData,
          };
        });
        const flattenedData = data.flatMap((d) => {
          const formattedData = formatAssessment({ ...d, allWorkspaces });
          const creatorId = d.creator.id;
          const filteredParticipants = (d.participants || []).filter(
            (participant) => participant.id !== creatorId,
          );
          const rowsWithParticipants = filteredParticipants?.map(
            (participant) => ({
              ...formattedData,
              participant: 'TRUE',
              creator: `${participant.firstName} ${participant.lastName}`,
              creatorId: `${participant.id}`,
              creatorEmail: participant.email,
              workspace: `${
                allWorkspaces.find((w) => w.id === participant.lastWorkspaceId)
                  .title
              }`,
            }),
          );
          return [formattedData, ...rowsWithParticipants];
        });

        exportToExcel({
          data: flattenedData,
          sheetName: 'Assessment Data',
          exportedBy: `${user.firstName} ${user.lastName}`,
          type: 'Assessment',
          fileName: `Assessment_Export_${format(new Date(), 'yyyy-MM-dd')}`,
          reportType: ['Report Type', 'Assessment Table Export'],
          headerMap: excelHeaderLookup,
          workspace: `${workspace.title}`,
        });
      }
      if (!response.length) {
        showToast({
          title: 'Error Exporting Data',
          variant: toastVariant.danger,
          message: `No Data to export.`,
        });
      }
    });
  };

  const { isAdmin } = useCurrentUser();
  const options = [
    {
      title: user.company?.assessmentName || t(keys.common.ASSESSMENTS),
      element: InspectionsList,
      key: 'assessments',
    },
    ...(isAdmin
      ? [
          {
            title: t(keys.common.TEMPLATES),
            element: TemplatesList,
            key: 'templates',
          },
        ]
      : []),
  ];
  const { isWorkspaceAdmin } = useWorkspace();
  return (
    <div className={disabled ? styles.disabled : null}>
      <div className={styles.headerContainer}>
        <Text size="lg" weight="bold">
          {user.company?.assessmentName || t(keys.common.ASSESSMENTS)}
        </Text>
        {(isWorkspaceAdmin || tab === 'assessments') && (
          <div className={styles.buttons}>
            <Button
              value={t(keys.common.NEW)}
              variant="primary"
              icon="add"
              size="md"
              onClick={() => {
                switch (tab) {
                  case 'assessments':
                    openModal({
                      modalName: modals.createAssessment,
                      variables: {
                        participants: [user],
                        types: Object.values(assessmentTypes).filter(
                          (t) =>
                            t.key !== 'INCIDENT' &&
                            t.key !== 'OBSERVATION' &&
                            t.key !== 'AUDIT',
                        ),
                      },
                    });
                    break;
                  case 'templates':
                    openModal({
                      modalName: modals.createTemplate,
                      variables: {
                        types: Object.values(assessmentTypes).filter(
                          (t) =>
                            t.key !== 'INCIDENT' &&
                            t.key !== 'OBSERVATION' &&
                            t.key !== 'AUDIT',
                        ),
                      },
                    });
                    break;
                  default:
                    throw new Error(`Invalid type ${tab}`);
                }
              }}
            />
            {tab === defaultTab && isWorkspaceAdmin && (
              <Button
                outlined
                value={t(keys.action.EXPORT)}
                onClick={handleExport}
                icon="file_upload"
              />
            )}
          </div>
        )}
      </div>
      <TabSystem type={tab} options={options} />
      <ProgressModal
        show={isFetchingAllData}
        progress={progress}
        title="Exporting Data"
        message="One moment while we fetch your data. Please don't close this window!"
        icon="import_export"
      />
    </div>
  );
}
