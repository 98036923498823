import styles from './DropdownText.module.scss';
import { useSelect } from 'downshift';
import { Text } from '../typography';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { dropdownTypes } from './dropdown';
import { Icon } from '@mui/material';

export function DropdownText({
  onChange,
  onRemove = () => {},
  items = [],
  title,
  selected,
  disabled,
  highlight = false,
  placeholder = null,
  namespace = 'incidents',
  opacityOnDisabled = false,
  type = null,
  required = false,
  className,
}) {
  const { t } = useTranslation();

  function itemToString(item) {
    if (Array.isArray(item)) {
      return (
        item
          .map((value) => t(getTranslationKey(value, namespace)))
          .join(', ') || item.join(', ')
      );
    }
    if (typeof item === 'object') {
      return item.title ? item.title : item;
    }
    const key = getTranslationKey(item, namespace);
    return t(key) || item;
  }

  function Select() {
    const {
      isOpen,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getItemProps,
      selectItem,
    } = useSelect({
      items,
      itemToString,
      onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
    });

    return (
      <div className={classNames(styles.container, className)}>
        <div className={title ? styles.title : styles.hide}>
          <Text {...getLabelProps()} noMargin weight="semiBold">
            {title}
          </Text>
        </div>
        <button
          {...getToggleButtonProps()}
          className={classNames(
            disabled
              ? styles.disabled
              : highlight && !selected
              ? styles.highlight
              : styles.selector,
          )}
          style={
            opacityOnDisabled
              ? {
                  opacity: '1',
                  backgroundColor: 'ebedf0',
                  border: '1px solid #ced4da',
                  minWidth: '13rem',
                }
              : {}
          }
          disabled={disabled}
        >
          {selected ? (
            type === dropdownTypes.ICONS ? (
              <Icon>{selected}</Icon>
            ) : (
              <div className={styles.selected}>
                <Text noMargin>{itemToString(selected)}</Text>
                {!required && (
                  <Icon
                    onClick={(e) => {
                      selectItem(null);
                      e.stopPropagation();
                      e.preventDefault();
                      onRemove();
                    }}
                    sx={{ fontSize: '1rem', color: '#6e6e73' }}
                  >
                    close
                  </Icon>
                )}
              </div>
            )
          ) : (
            <Text noMargin>
              {t(keys.action.SELECT_VARIABLE, {
                variable: placeholder || title,
              })}
            </Text>
          )}
          {!disabled && <div>{isOpen ? <ExpandLess /> : <ExpandMore />}</div>}
        </button>
        <div className={styles.dropdownContainer} {...getMenuProps()}>
          <div
            className={
              type === dropdownTypes.ICONS ? styles.icons : styles.options
            }
          >
            {isOpen &&
              items?.map((item, index) =>
                type === dropdownTypes.ICONS ? (
                  <div
                    className={styles.iconOptions}
                    key={`${item.id}-${index}`} // Use `id` for unique keys
                    {...getItemProps({ item, index })}
                  >
                    <Icon>{item}</Icon>
                  </div>
                ) : (
                  <div
                    className={styles.option}
                    key={`${item.id}-${index}`} // Use `id` for unique keys
                    {...getItemProps({ item, index })}
                  >
                    {itemToString(item)}
                  </div>
                ),
              )}
          </div>
        </div>
      </div>
    );
  }
  return <Select />;
}
