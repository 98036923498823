import React from 'react';
import { Text } from '../components/typography';
import styles from './ErrorAccountNotFound.module.scss';
import logo from '../assets/logo_blue_black.svg';
import Button from '../components/Button';
import { contact } from '../constants/endpoints';
import { useAuth0 } from '../providers/Auth0Provider';
import { paths } from '../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';

export default function ErrorAccountNotFound() {
  const { isAuthenticated, logout } = useAuth0();
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img src={logo} alt="logo" className={styles.logo} />
      <div className={styles.subContainer}>
        <Text textAlign="center" size="xl" weight="bold" color="accentPrimary">
          {t(keys.utilities.NOT_FOUND_403_TITLE)}
        </Text>
        <Text textAlign="center" weight="semiBold">
          {t(keys.utilities.NOT_FOUND_403_DESCRIPTION)}
        </Text>
        <img
          src="https://app.opasmobile.com/assets/graphics/404_magnifying_glass.png"
          alt="robot"
          className={styles.robot}
        />
        <div className={styles.buttonContainer}>
          <Button
            value={t(keys.utilities.REFRESH)}
            onClick={() => (window.location = '/')}
          />
          {isAuthenticated && (
            <Button
              variant="secondary"
              value={t(keys.utilities.SIGNOUT)}
              onClick={() =>
                logout({
                  returnTo: `${window.location.origin}/${paths.login}`,
                })
              }
            />
          )}
        </div>
        <br />
        <Text textAlign="center" color="secondary">
          {t(keys.utilities.NOT_FOUND_403_HELP_ONE)}
        </Text>
        <Text textAlign="center" noMargin color="secondary">
          <span
            className={styles.link}
            onClick={() => (window.location.href = contact)}
          >
            {t(keys.utilities.NOT_FOUND_403_HELP_LINK)}
          </span>
          {t(keys.utilities.NOT_FOUND_403_HELP_TWO)}
        </Text>
      </div>
    </div>
  );
}
