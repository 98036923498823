import { loader } from 'graphql.macro';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useTableComponents } from '../../components/tables/MTableComponents';
import { useQueryParams } from '../../hooks/misc';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents';
import MTTable from '../../components/tables/MTable';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { cpaStatusTypes, riskLevels } from '../../utilities/audits';
import { useURLParams } from '../../providers/URLParamProvider';
import { Text } from '../../components/typography';

const CPAQuery = loader('./CPATable.fetch.graphql');
export default function CPATable() {
  const { t } = useTranslation();
  const { textCell, userCell, dateCell, userFilterOptions, labelCell } =
    useTableComponents();
  const navigate = useNavigate();
  const { getParam } = useQueryParams();
  const { page, pageSize, filters, subfilters, sort } = useURLParams();
  const [dateSelectors, setDateSelectors] = useState({
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
    dateAcceptedMin: getParam('dateAccepted')?.split('__')[0] || '',
    dateAcceptedMax: getParam('dateAccepted')?.split('__')[1] || '',
    dateCompletedMin: getParam('dateCompleted')?.split('__')[0] || '',
    dateCompletedMax: getParam('dateCompleted')?.split('__')[1] || '',
  });
  const {
    networkStatus,
    data: { correctiveActions = [], correctiveActionCount = 0 } = {},
  } = useQuery(CPAQuery, {
    variables: {
      options: {
        page,
        pageSize,
        filters,
        subfilters,
        sort,
      },
    },
  });
  const columns = useMemo(
    () => [
      {
        accessorKey: 'riskLevel',
        header: 'Risk',
        size: 90,
        Cell: ({ cell }) => (
          <Text
            noMargin
            size="sm"
            weight="bold"
            color={riskLevels[cell.getValue()]?.color}
          >
            {riskLevels[cell.getValue()]?.tableText}
          </Text>
        ),
        filterVariant: 'select',
        filterSelectOptions: Object.keys(riskLevels).map((key) => ({
          label: key,
          value: key,
        })),
      },
      {
        accessorKey: 'externalId',
        header: 'ID',
        size: 120,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'narrative',
        header: 'Narrative',
        grow: true,
        enableSorting: false,
        minSize: 250,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'assignee',
        header: t(keys.common.ASSIGNEE),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        Cell: ({ cell }) => userCell({ cell }),
        filterSelectOptions: userFilterOptions('all'),
      },
      {
        accessorFn: (row) => row?.inspection?.template?.title,
        accessorKey: 'inspection.template.title',
        header: 'Audit',
        grow: true,
        enableSorting: false,
        minSize: 250,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        size: 120,
        Cell: ({ cell }) =>
          labelCell({
            color: cpaStatusTypes[cell.getValue()]?.color,
            name: cpaStatusTypes[cell.getValue()]?.title,
          }),
        filterVariant: 'select',
        filterSelectOptions: Object.entries(cpaStatusTypes).map(
          ([key, value]) => ({
            label: value.title,
            value: key,
          }),
        ),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        Cell: ({ cell }) => userCell({ cell }),
        filterSelectOptions: userFilterOptions('all'),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        Cell: ({ cell }) => dateCell({ cell }, { showTime: true }),
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateAccepted',
        header: 'Accepted',
        Cell: ({ cell }) => dateCell({ cell }, { showTime: true }),
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateAcceptedMin}
            maxDate={dateSelectors.dateAcceptedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateAcceptedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateAcceptedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCompleted',
        header: t(keys.common.DATE_COMPLETED),
        Cell: ({ cell }) => dateCell({ cell }, { showTime: true }),
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCompletedMin}
            maxDate={dateSelectors.dateCompletedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCompletedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCompletedMax: value })
            }
          />
        ),
      },
    ],
    [
      dateCell,
      dateSelectors,
      labelCell,
      t,
      textCell,
      userCell,
      userFilterOptions,
    ],
  );
  const isLoading = networkStatus !== NetworkStatus.ready;
  return (
    <MTTable
      rightToolbar="cpa"
      isLoading={isLoading}
      data={correctiveActions || []}
      columns={columns}
      rowCount={correctiveActionCount}
      onClearAllFilters={() => {
        setDateSelectors({});
      }}
      onRowClick={(row) => {
        navigate(`/${paths.cpa}/${row.id}`);
      }}
    />
  );
}
