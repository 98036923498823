import Select from 'react-select';
import { Text } from './typography';

export default function TimezoneDropdown({ onChange, value, readOnly }) {
  const timezones = [
    { key: 'GMT-12:00', label: 'GMT-1200 BIT' }, // Baker Island Time
    { key: 'GMT-11:00', label: 'GMT-1100 NUT/ST' }, // Niue Time (NUT), Samoa Standard Time (ST)
    { key: 'GMT-10:00', label: 'GMT-1000 HAST' }, // Hawaii-Aleutian Standard Time (HAST)
    { key: 'GMT-09:30', label: 'GMT-0930 MQT' }, // Marquesas Time
    { key: 'GMT-09:00', label: 'GMT-0900 AKST' }, // Alaska Standard Time (AKST)
    { key: 'GMT-08:00', label: 'GMT-0800 PST' }, // Pacific Standard Time (PST)
    { key: 'GMT-07:00', label: 'GMT-0700 MST' }, // Mountain Standard Time (MST)
    { key: 'GMT-06:00', label: 'GMT-0600 CST' }, // Central Standard Time (CST)
    { key: 'GMT-05:00', label: 'GMT-0500 EST' }, // Eastern Standard Time (EST)
    { key: 'GMT-04:00', label: 'GMT-0400 AST' }, // Atlantic Standard Time (AST)
    { key: 'GMT-03:30', label: 'GMT-0330 NST' }, // Newfoundland Standard Time (NST)
    { key: 'GMT-03:00', label: 'GMT-0300 ART/BRT' }, // Argentina Time (ART), Brazil Standard Time (BRT)
    { key: 'GMT-02:00', label: 'GMT-0200 GST' }, // South Georgia Time
    { key: 'GMT-01:00', label: 'GMT-0100 AZOT' }, // Azores Standard Time (AZOT)
    { key: 'GMT+00:00', label: 'GMT' }, // Greenwich Mean Time (GMT)
    { key: 'GMT+01:00', label: 'GMT+0100 CET/WAT' }, // Central European Time (CET), West Africa Time (WAT)
    { key: 'GMT+02:00', label: 'GMT+0200 EET/CAT' }, // Eastern European Time (EET), Central Africa Time (CAT)
    { key: 'GMT+03:00', label: 'GMT+0300 EAT/AST' }, // East Africa Time (EAT), Arabian Standard Time (AST)
    { key: 'GMT+03:30', label: 'GMT+0330 IRST' }, // Iran Standard Time (IRST)
    { key: 'GMT+04:00', label: 'GMT+0400 GST' }, // Gulf Standard Time (GST)
    { key: 'GMT+04:30', label: 'GMT+0430 AFT' }, // Afghanistan Time (AFT)
    { key: 'GMT+05:00', label: 'GMT+0500 PKT' }, // Pakistan Standard Time (PKT)
    { key: 'GMT+05:30', label: 'GMT+0530 IST' }, // Indian Standard Time (IST)
    { key: 'GMT+05:45', label: 'GMT+0545 NPT' }, // Nepal Time (NPT)
    { key: 'GMT+06:00', label: 'GMT+0600 BST/BTT' }, // Bangladesh Standard Time (BST), Bhutan Time (BTT)
    { key: 'GMT+06:30', label: 'GMT+0630 MMT' }, // Myanmar Time (MMT)
    { key: 'GMT+07:00', label: 'GMT+0700 ICT' }, // Indochina Time (ICT)
    { key: 'GMT+08:00', label: 'GMT+0800 CST/AWST' }, // China Standard Time (CST), Australian Western Standard Time (AWST)
    { key: 'GMT+08:45', label: 'GMT+0845 CWST' }, // Southeastern Western Australia Time (CWST)
    { key: 'GMT+09:00', label: 'GMT+0900 JST/KST' }, // Japan Standard Time (JST), Korea Standard Time (KST)
    { key: 'GMT+09:30', label: 'GMT+0930 ACST' }, // Australian Central Standard Time (ACST)
    { key: 'GMT+10:00', label: 'GMT+1000 AEST/VLAT' }, // Australian Eastern Standard Time (AEST), Vladivostok Time (VLAT)
    { key: 'GMT+11:00', label: 'GMT+1100 SBT/MAGT' }, // Solomon Islands Time (SBT), Magadan Time (MAGT)
    { key: 'GMT+12:00', label: 'GMT+1200 FJT/NZST' }, // Fiji Time (FJT), New Zealand Standard Time (NZST)
    { key: 'GMT+13:00', label: 'GMT+1300 TOT' }, // Tonga Time (TOT)
    { key: 'GMT+14:00', label: 'GMT+1400 LINT' }, // Line Islands Time (LINT)
  ];
  return readOnly ? (
    <Text noMargin>{value?.label ?? 'No Timezone Selected'}</Text>
  ) : (
    <Select
      isClearable
      placeholder={'Timezone'}
      value={value}
      options={timezones}
      onChange={onChange}
      isSearchable
    />
  );
}
