import styles from './SettingsTabSystem.module.scss';
import EditProfile from './settings_pages/EditProfile';
import EditUsers from './settings_pages/EditUsers';
import EditCompany from './settings_pages/EditCompany';
import { Text } from '../components/typography';
import classNames from 'classnames';
import { searchParamKeys } from '../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { useCurrentUser } from '../providers/UserProvider';
import Roles from './role_requirement/Roles';
import WorkspacesTable from './AdminConsole/WorkspaceTable';
import { useQueryParams } from '../hooks/misc';
import { Button } from '../components';
import { modals } from '../providers/modals';
import { useModal } from '../providers/ModalProvider';
import TabSystem from '../components/TabSystem';
import { useApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';
import { fetchAllData } from '../utilities/excel_export/table_excel_export';
import { showToast } from '../graphql/cache/modal';
import { exportToExcel } from '../components/excel/ExcelExport';
import {
  excelHeaderLookup,
  formatUser,
} from '../utilities/excel_export/data_converter';
import { loader } from 'graphql.macro';
import { useURLParams } from '../providers/URLParamProvider';
import { useState } from 'react';
import { toastVariant } from '../constants/misc';
import format from 'date-fns/format';
import ProgressModal from '../components/modals/ProgressModal';

const userExportQuery = loader('./SettingsTabSystem.usersExportQuery.graphql');
const defaultTab = 'profile';

export default function SettingsTabSystem({ disabled = false, type, parent }) {
  const { isAdmin, user } = useCurrentUser();
  const { getParam } = useQueryParams();
  const { t } = useTranslation();
  const client = useApolloClient();
  const tab = getParam(searchParamKeys.tab, defaultTab);
  const { openModal } = useModal();
  const { filters } = useURLParams();
  const [isFetchingAllData, setIsFetchingAllData] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleExportUsers = async () => {
    const query = gql`
      ${userExportQuery}
    `;
    await fetchAllData({
      query,
      client,
      setIsFetching: setIsFetchingAllData,
      table: 'users',
      count: 'usersCount',
      filters,
      setProgress,
    }).then((response, errors) => {
      if (errors?.length) {
        showToast({
          title: 'Error Exporting Data',
          variant: toastVariant.danger,
          message: `An error occurred: ${errors[0].message}`,
        });
      }
      if (response?.length) {
        exportToExcel({
          data: response.map((d) => formatUser(d)),
          sheetName: 'Users Data',
          exportedBy: `${user.firstName} ${user.lastName}`,
          type: 'Users',
          fileName: `Users_Export_${format(new Date(), 'yyyy-MM-dd')}`,
          reportType: ['Report Type', 'User Table Export'],
          headerMap: excelHeaderLookup,
        });
      }
    });
  };

  const userOptions = [
    {
      title: t(keys.settings.PROFILE),
      element: EditProfile,
      key: 'profile',
    },
  ];

  const adminOptions = [
    {
      title: t(keys.settings.COMPANY),
      element: EditCompany,
      key: 'company',
    },
    {
      title: t(keys.common.USERS),
      element: EditUsers,
      key: 'users',
    },
    {
      title: t(keys.common.ROLES),
      element: Roles,
      key: 'roles',
    },
    {
      title: t(keys.common.WORKSPACES),
      element: WorkspacesTable,
      key: 'workspaces',
    },
  ];

  const options = [...userOptions, ...(isAdmin ? adminOptions : [])];

  return (
    <div
      className={classNames(
        styles.container,
        disabled ? styles.disabled : null,
      )}
    >
      <div className={styles.header}>
        <Text size="lg" weight="bold">
          {t(keys.settings.SETTINGS)}
        </Text>
        {tab === 'roles' && (
          <Button
            align={'right'}
            value={t(keys.action.NEW_VARIABLE, {
              variable: t(keys.common.ROLE),
            })}
            icon={'add'}
            onClick={() =>
              openModal({
                modalName: modals.role,
              })
            }
          />
        )}
        {tab === 'users' && (
          <Button
            align={'right'}
            outlined
            icon="file_upload"
            value={t(keys.action.EXPORTDYNAMIC, {
              variable: t(keys.common.USERS),
            })}
            onClick={handleExportUsers}
          />
        )}
      </div>
      <div>
        <div className={styles.subPage}>
          <TabSystem
            type={tab}
            options={options}
            justifyContent={'flexCenter'}
          />
          <ProgressModal
            show={isFetchingAllData}
            progress={progress}
            title="Exporting Data"
            message="One moment while we fetch your data. Please don't close this window!"
            icon="import_export"
          />
        </div>
      </div>
    </div>
  );
}
