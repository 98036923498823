import styles from './ExtensionRequests.module.scss';
import { Text } from '../../components/typography';
import { getLocalTime } from '../../utilities/time';
import { useState } from 'react';
import Label from '../../components/action_items/Label';
import Modal from '../../components/modals/Modal';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import Icon from '../../components/Icon';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const addExtensionMutation = loader('./CorrectiveAction.addExtension.graphql');
const updateExtensionMutation = loader(
  './CorrectiveAction.updateExtension.graphql',
);
const updateActionItemMutation = loader(
  './CorrectiveAction.updateActionItem.graphql',
);
function diffInDays(request, extension) {
  const requestDateStr = request;
  if (!requestDateStr) {
    return null;
  }
  const requestDate = moment(requestDateStr);
  const dueDate = moment(parseInt(extension));
  const differenceInDays = requestDate.diff(dueDate, 'days');
  return isNaN(differenceInDays)
    ? null
    : `${differenceInDays} ${differenceInDays === 1 ? 'day' : 'days'}`;
}

export default function ExtensionRequests({
  extensions,
  showModal,
  setShowModal,
  isAssignee,
  isCreator,
  actionItem,
}) {
  const initialState = {
    id: null,
    originalDate: null,
    requestedDate: null,
    extensionDate: null,
    creatorComments: '',
    status: null,
    approverComments: '',
  };
  const [selectedExtension, setSelectedExtension] = useState(initialState);
  const [addExtension] = useMutation(addExtensionMutation);
  const [updateExtension] = useMutation(updateExtensionMutation);
  const [extensionApproval, setExtensionApproval] = useState(true);
  const [updateActionItem] = useMutation(updateActionItemMutation);

  const resetForm = () => {
    setExtensionApproval(true);
    setSelectedExtension(initialState);
  };

  const handleModalClose = () => {
    setShowModal(false);
    resetForm();
  };

  const handleSubmit = () => {
    if (isAssignee && !selectedExtension?.id) {
      addExtension({
        variables: {
          actionItemId: actionItem.id,
          ...selectedExtension,
        },
      });
    } else {
      updateExtension({
        variables: {
          ...selectedExtension,
          actionItemId: actionItem.id,
        },
      }).then(
        ({
          data: {
            updateActionItemExtension: { extension },
          },
        }) => {
          const ext = extension.find((e) => e.id === selectedExtension.id);
          const dueDate = moment(parseInt(ext.extensionDate));
          updateActionItem({
            variables: {
              id: actionItem.id,
              dueDate,
              actionItemId: actionItem.id,
            },
          });
        },
      );
    }
    handleModalClose();
  };

  return (
    <div className={styles.row}>
      <Text size="sm" color="secondary" noMargin>
        Extension Requests
      </Text>
      {!extensions?.length ? (
        <Text color="secondary" noMargin>
          None
        </Text>
      ) : (
        extensions?.map((extension) => (
          <div
            className={styles.extensions}
            key={extension.id}
            onClick={() => {
              setSelectedExtension(extension);
              setShowModal(true);
            }}
          >
            <div className={styles.header}>
              <Text noMargin weight="semiBold" size="sm">
                {`From: ${getLocalTime(extension.originalDate)?.format(
                  'MMM Do, YYYY',
                )}`}
              </Text>
              <Icon color={extension.status === 'COMPLETE' ? 'green' : 'red'}>
                {extension.status === 'COMPLETE'
                  ? 'check_circle'
                  : 'error_outline'}
              </Icon>
            </div>
            <div className={styles.header}>
              <Text size="sm" noMargin weight="semiBold">
                {`To: ${getLocalTime(
                  extension.extensionDate || extension.requestedDate,
                ).format('MMM Do, YYYY')}`}
              </Text>
              <Text noMargin size="sm">
                {diffInDays(
                  parseInt(extension.extensionDate) ||
                    parseInt(extension.requestedDate),
                  parseInt(extension.originalDate),
                )}
              </Text>
            </div>
          </div>
        ))
      )}
      <Modal
        open={showModal}
        title="Extension Request"
        titleImage={<Icon>timer</Icon>}
        onClose={handleModalClose}
        onSubmit={handleSubmit}
        className={styles.modal}
        submitDisabled={
          selectedExtension.status === 'PENDING'
            ? !selectedExtension.approverComments ||
              (!extensionApproval && !selectedExtension.extensionDate)
            : !selectedExtension.creatorComments ||
              !selectedExtension.requestedDate
        }
        hideCancel={selectedExtension?.status === 'COMPLETE'}
        hideSubmit={selectedExtension?.status === 'COMPLETE'}
        subtitle={`Approval required by ${actionItem.creator.firstName} ${actionItem.creator.lastName}`}
      >
        <div className={styles.modalContent}>
          <RequestDetails
            selectedExtension={selectedExtension}
            actionItem={actionItem}
            setSelectedExtension={setSelectedExtension}
          />
          {isCreator && selectedExtension?.status === 'PENDING' && (
            <CreatorResponse
              extensionApproval={extensionApproval}
              setExtensionApproval={setExtensionApproval}
              setSelectedExtension={setSelectedExtension}
              selectedExtension={selectedExtension}
              actionItem={actionItem}
            />
          )}
          {selectedExtension?.status === 'COMPLETE' && (
            <div className={styles.row}>
              <Text weight="semiBold" noMargin>
                Response
              </Text>
              <Text>{selectedExtension?.approverComments}</Text>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

function RequestDetails({
  selectedExtension,
  setSelectedExtension,
  actionItem,
}) {
  return (
    <>
      {selectedExtension?.status === 'PENDING' ? (
        <div className={styles.header}>
          <Text size="lg" noMargin weight="semiBold">
            {`Extension: ${diffInDays(
              parseInt(selectedExtension?.requestedDate),
              parseInt(selectedExtension?.originalDate),
            )}`}
          </Text>

          {selectedExtension?.id ? (
            <Label
              rowSized={false}
              name={selectedExtension?.status}
              color={
                selectedExtension?.status === 'PENDING' ? 'orange' : 'green'
              }
            />
          ) : null}
        </div>
      ) : null}

      {selectedExtension?.status === 'COMPLETE' ? (
        <div className={styles.row}>
          <div className={styles.flex}>
            <Icon color="green">check_circle</Icon>
            <Text
              noMargin
              weight="semiBold"
              size="lg"
            >{`Approved Extension of ${diffInDays(
              parseInt(selectedExtension?.extensionDate),
              parseInt(selectedExtension?.originalDate),
            )}`}</Text>
          </div>
          <Text noMargin>{`Due Date: ${getLocalTime(
            selectedExtension?.extensionDate,
          ).format('dddd MMMM Do YYYY')}`}</Text>
        </div>
      ) : null}

      <div className={styles.row}>
        <Text noMargin weight="semiBold">
          Original Due Date
        </Text>
        <div className={styles.iconText}>
          <Icon>access_alarm</Icon>
          <Text noMargin>{`${getLocalTime(
            selectedExtension?.originalDate || actionItem.dueDate,
          ).format('dddd MMMM Do YYYY')}`}</Text>
        </div>
      </div>
      <div className={styles.row}>
        <Text noMargin weight="semiBold">
          Requested Extension Date
        </Text>
        {selectedExtension?.id ? (
          <div className={styles.iconText}>
            <Icon>access_alarm</Icon>
            <Text noMargin>
              {getLocalTime(selectedExtension?.requestedDate).format(
                'dddd MMMM Do YYYY',
              )}
            </Text>
          </div>
        ) : (
          <div className={styles.dateDisplay}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={
                  selectedExtension?.requestedDate
                    ? moment(selectedExtension.requestedDate)
                    : null
                }
                minDate={moment(parseInt(actionItem.dueDate))}
                defaultValue={moment(parseInt(actionItem.dueDate))}
                onChange={(newValue) => {
                  const requestedDate = newValue.set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                  });
                  if (newValue && newValue.isValid()) {
                    setSelectedExtension({
                      ...selectedExtension,
                      requestedDate,
                    });
                  }
                }}
              />
            </LocalizationProvider>
            <Text noMargin weight="semiBold">
              {diffInDays(selectedExtension?.requestedDate, actionItem.dueDate)}
            </Text>
          </div>
        )}
      </div>
      <div className={styles.row}>
        <Text noMargin weight="semiBold">
          Reason for Request
        </Text>
        {selectedExtension?.id ? (
          <Text noMargin>{selectedExtension?.creatorComments}</Text>
        ) : (
          <Form.Control
            as="textarea"
            rows={3}
            value={selectedExtension?.creatorComments}
            onChange={(e) =>
              setSelectedExtension({
                ...selectedExtension,
                creatorComments: e.target.value,
              })
            }
          />
        )}
      </div>
    </>
  );
}

function CreatorResponse({
  extensionApproval,
  setExtensionApproval,
  setSelectedExtension,
  selectedExtension,
  actionItem,
}) {
  return (
    <>
      <div className={styles.row}>
        <Text weight="semiBold" noMargin>
          Approve Extension Request
        </Text>
        <div className={styles.check}>
          <Form.Check
            label="Yes"
            name="approval"
            type={'radio'}
            defaultChecked={extensionApproval}
            onChange={() => {
              setExtensionApproval(true);
              setSelectedExtension({
                ...selectedExtension,
                extensionDate: null,
              });
            }}
          />
          <Form.Check
            label="No"
            name="approval"
            type={'radio'}
            onChange={() => {
              setExtensionApproval(false);
              setSelectedExtension({
                ...selectedExtension,
              });
            }}
          />
        </div>
      </div>
      {!extensionApproval && (
        <div className={styles.row}>
          <Text weight="semiBold" noMargin>
            Select New Extension Date
          </Text>
          <div className={styles.dateDisplay}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={moment(selectedExtension.requestedDate)}
                minDate={moment(parseInt(actionItem.dueDate))}
                onChange={(newValue) => {
                  const extensionDate = newValue.set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                  });
                  if (newValue && newValue.isValid()) {
                    setSelectedExtension({
                      ...selectedExtension,
                      extensionDate,
                    });
                  }
                }}
              />
            </LocalizationProvider>
            <Text noMargin weight="semiBold">
              {diffInDays(selectedExtension?.extensionDate, actionItem.dueDate)}
            </Text>
          </div>
        </div>
      )}
      <div className={styles.row}>
        <Text weight="semiBold" noMargin>
          Comments
        </Text>
        <Form.Control
          as="textarea"
          rows={4}
          value={selectedExtension?.approverComments}
          onChange={(e) =>
            setSelectedExtension({
              ...selectedExtension,
              approverComments: e.target.value,
            })
          }
        />
      </div>
    </>
  );
}
