import React, { useState, useMemo, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styles from './CreateQuestions.module.scss';
import { loader } from 'graphql.macro';
import AddQuestionMenu from '../../components/question_menu/AddQuestionMenu';
import { Icon, Switch } from '@mui/material';
import { LayersOutlined } from '@mui/icons-material';
import { customColors, inputTypeToIconMap } from '../../utilities/misc';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '../../components/typography';
import emptyStateGraphic from '../../assets/folder_graphic.svg';
import { Col, Form } from 'react-bootstrap';
import { Button } from '../../components';
import questionEmptyState from '../../assets/question_empty_state.svg';
import { openCsvImportModal } from '../../graphql/cache/modal';
import ImageDropzone from '../../components/image_uploads/ImageDropzone';
import ContextMenu from '../../components/ContextMenu';
import { useEffect } from 'react';
import { inputTypes, paths } from '../../constants/strings';
import { cache } from '../../graphql/cache';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { getRoute } from '../../constants/strings';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import {
  keys,
  getTranslationKey,
} from '../../utilities/translator/translation_keys';
import CreateObservationQuestions from './CreateObservationQuestions';
import { Spinner } from 'react-bootstrap';
import { numberCheck } from '../../utilities/inspection';
import { useModal } from '../../providers/ModalProvider';
import TemplateRevisionTable from '../../components/TemplateRevisionTable/TemplateRevisionTable';
import { useWatcher } from '../../providers/WatcherProvider';
import { DropdownText } from '../../components/dropdowns/DropdownText';

const XLSX = require('xlsx');

const templateQuery = loader('./CreateQuestions.inspectionTemplate.graphql');
const templateImageMutation = loader(
  './CreateQuestions.inspectionTemplateImage.graphql',
);
const deleteTemplateMutation = loader(
  './CreateQuestions.deleteTemplate.graphql',
);
const deleteQuestionMutation = loader(
  './CreateQuestions.deleteQuestion.graphql',
);
const deleteCategoryMutation = loader(
  './CreateQuestions.deleteCategory.graphql',
);
const copyMutation = loader('./CreateQuestions.copy.graphql');
const updateMutation = loader('./CreateQuestions.publish.graphql');
const categoryMutation = loader('./CreateQuestions.category.graphql');
const deleteTemplateImageMutation = loader(
  './CreateQuestions.deleteImage.graphql',
);
const updateCategoryMutation = loader(
  './CreateQuestions.updateCategory.graphql',
);

export default function CreateQuestions() {
  const { id } = useParams();
  const { workspace, isWorkspaceAdmin } = useWorkspace();
  const navigate = useNavigate();
  const templateId = parseInt(id, 10);
  const { openConfirmationModal } = useModal();
  const [category, setCategory] = useState('');
  const [createCategory] = useMutation(categoryMutation);
  const [copyTemplate] = useMutation(copyMutation);
  const [deleteCategory] = useMutation(deleteCategoryMutation);
  const [deleteTemplate] = useMutation(deleteTemplateMutation);
  const [deleteQuestion] = useMutation(deleteQuestionMutation);
  const [createTemplateImage] = useMutation(templateImageMutation);
  const [deleteTemplateImage] = useMutation(deleteTemplateImageMutation);
  const [updateCategory] = useMutation(updateCategoryMutation, {
    refetchQueries: ['GetInspectionTemplateForCreateQuestions'],
  });
  const [update] = useMutation(updateMutation);
  const { user: currentUser, isAdmin } = useCurrentUser();
  const [menuOpen, setMenuOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState('');
  const { t } = useTranslation();
  const { FollowButton } = useWatcher();
  const {
    data: { inspectionTemplates: [inspectionTemplate] = [{}] } = {},
    loading,
    refetch,
  } = useQuery(templateQuery, {
    skip: !id,
    variables: {
      id: `${id}`,
    },
  });

  const [newTitle, setNewTitle] = useState(inspectionTemplate?.title);
  const [newDocumentString, setNewDocumentString] = useState(
    inspectionTemplate?.documentString,
  );
  const [newStartingAt, setNewStartingAt] = useState(
    inspectionTemplate?.startingAt || 1,
  );
  const [newDescription, setNewDescription] = useState(
    inspectionTemplate?.description,
  );

  const contextMenuCSV = {
    title: t(keys.action.IMPORT, { variable: null }),
    icon: 'file_upload',
    disabled: !isWorkspaceAdmin,
    onClick: () => {
      openCsvImportModal(inspectionTemplate, refetch);
      setMenuOpen(false);
    },
  };
  const allowCopyAndDelete = useMemo(() => {
    const isCrossWorkspaceTemplate = !inspectionTemplate?.workspaceId;

    const isArchived = inspectionTemplate?.isArchived;

    if (isArchived) {
      return false;
    }

    if (isCrossWorkspaceTemplate) {
      return isAdmin;
    } else {
      return isWorkspaceAdmin;
    }
  }, [inspectionTemplate, isAdmin, isWorkspaceAdmin]);

  const handleExport = async (e) => {
    const { categories } = inspectionTemplate;
    const workbook = XLSX.utils.book_new();
    const headers = [
      'TITLE',
      'DESCRIPTION',
      'CATEGORY',
      'BUNDLE',
      'INPUT TYPE',
      'REQUIRED',
      'ADDITIONAL',
    ];
    const worksheet = XLSX.utils.json_to_sheet([], { header: headers });
    categories?.forEach((category) => {
      const categoryTitle = category.title;
      const categoryIsBundle = category.isBundle ? 'YES' : 'NO';
      category.questions.forEach((question) => {
        const required = question.required ? 'YES' : 'NO';
        const additional = question.additionalData
          ? `${question.additionalData?.split('|')}`
          : '';
        const rowData = [
          question.title,
          question.description,
          categoryTitle,
          categoryIsBundle,
          question.inputType,
          required,
          additional,
        ];
        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
      });
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, t(keys.common.TEMPLATE));
    XLSX.writeFile(workbook, `${inspectionTemplate.title}.xlsx`);
  };
  const contextMenuOptions = [
    {
      title: t(keys.action.EXPORT),
      icon: 'file_download',
      disabled: !isWorkspaceAdmin,
      onClick: () => handleExport(),
    },
  ];

  const reviseOption = {
    title: t(keys.action.REVISE),
    icon: 'file_copy',
    disabled: !allowCopyAndDelete,
    onClick: () => {
      const draftTemplate =
        inspectionTemplate?.originalTemplate?.revisions.find(
          ({ isDraft }) => isDraft,
        );

      if (draftTemplate) {
        navigate(getRoute(workspace?.id, paths.templates, draftTemplate.id));
      } else {
        openConfirmationModal({
          title: t(keys.action.REVISE_VARIABLE, {
            variable: t(keys.templates.TEMPLATE),
          }),
          description: t(keys.templates.CREATE_REVISION_MESSAGE),
          variant: 'success',
          buttonText: t(keys.action.REVISE, { variable: null }),
          onSubmit: () => {
            setMenuOpen(false);
            copyTemplate({ variables: { templateId } }).then(({ data }) => {
              const newTemplateId = data?.copyInspectionTemplate?.id;
              if (!newTemplateId) {
                throw new Error('Template copy failed');
              }
              navigate(getRoute(workspace?.id, paths.templates, newTemplateId));
            });
          },
        });
      }
    },
  };

  const deleteOption = {
    title: t(keys.action.DELETE),
    icon: 'delete',
    disabled: !allowCopyAndDelete,
    variant: 'red',
    onClick: () => {
      openConfirmationModal({
        title: t(keys.action.DELETE_VARIABLE, {
          variable: t(keys.templates.TEMPLATE),
        }),
        description: `${
          inspectionTemplate.isDraft
            ? t(keys.action.DELETE_TEMPLATE_CONFIRMATION_DRAFT, {
                variable: inspectionTemplate.title,
              })
            : t(keys.action.DELETE_TEMPLATE_CONFIRMATION, {
                variable: inspectionTemplate.title,
              })
        }`,
        variant: 'danger',
        buttonText: t(keys.action.DELETE),
        onSubmit: () => {
          deleteTemplate({ variables: { id: templateId } }).then(() => {
            if (inspectionTemplate.templateType === 'AUDIT') {
              navigate(`/${paths.audits}`);
            } else {
              navigate(
                getRoute(
                  workspace.id,
                  inspectionTemplate.templateType === 'INCIDENT'
                    ? paths.incidents
                    : inspectionTemplate.templateType === 'OBSERVATION'
                    ? paths.observations
                    : paths.assessments,
                  '?tab=templates',
                ),
              );
            }
          });
        },
      });
    },
  };

  const revisions = useMemo(() => {
    if (loading) {
      return [];
    }

    if (inspectionTemplate?.originalTemplate) {
      return [
        inspectionTemplate.originalTemplate,
        ...(inspectionTemplate.originalTemplate.revisions || []),
      ];
    }

    return [inspectionTemplate, ...(inspectionTemplate.revisions || [])];
  }, [inspectionTemplate, loading]);

  const latestRevision =
    inspectionTemplate?.originalTemplate?.revisions[1] ||
    inspectionTemplate?.originalTemplate;

  const dateSelectionTypes = ['None', 'YEAR', 'MONTH-YEAR'];
  const [selectedDateOption, setSelectedDateOption] = useState(
    inspectionTemplate.documentStringOption ?? dateSelectionTypes[0],
  );

  const previewDocumentString = useCallback(
    ({ startingAt: number, documentString: string, isDraft }) => {
      if (!string || !isDraft) {
        return null;
      }
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const dateString = () => {
        switch (selectedDateOption) {
          case dateSelectionTypes[0]:
            return '-';
          case dateSelectionTypes[1]:
            return `-${currentYear}-`;
          case dateSelectionTypes[2]:
            return `-${currentMonth + 1}-${currentYear}-`;
          default:
            return '-';
        }
      };

      return (
        <Text noMargin size="sm" color="secondary">{`Preview: ${
          string ?? 'ABC123'
        }${dateString()}${
          number?.toString()?.padStart(5, '0') ?? '00000'
        }`}</Text>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDateOption],
  );
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditingCatTitle, setIsEditingCatTitle] = useState(false);
  const [newCatTitle, setNewCatTitle] = useState('');

  useEffect(() => {
    if (inspectionTemplate?.description) {
      setNewDescription(inspectionTemplate?.description);
    }
    if (inspectionTemplate?.title) {
      setNewTitle(inspectionTemplate?.title);
    }
    if (inspectionTemplate?.startingAt) {
      setNewStartingAt(inspectionTemplate?.startingAt);
    }
  }, [inspectionTemplate, selectedDateOption]);
  return !inspectionTemplate?.id ? (
    <Spinner variant="primary" animation="border" />
  ) : (
    <div className={styles.page}>
      <div className={styles.headerTitle}>
        <Text size="lg" weight="bold" noMargin>
          {inspectionTemplate?.isDraft
            ? inspectionTemplate.templateType === 'AUDIT'
              ? 'Create Audit Checklist'
              : t(keys.action.CREATE_VARIABLE, {
                  variable:
                    t(keys.templates[inspectionTemplate.templateType]) ||
                    inspectionTemplate.templateType,
                })
            : inspectionTemplate.templateType === 'AUDIT'
            ? 'Audit Checklist'
            : t(keys.templates[inspectionTemplate.templateType]) ||
              inspectionTemplate.templateType}
        </Text>
        <div className={styles.optionsButtons}>
          <FollowButton
            type={'inspectionTemplate'}
            id={inspectionTemplate?.id}
            userId={currentUser?.id}
          />
          <div className={styles.moreContainer}>
            <Icon
              sx={{ fontSize: '1.5rem' }}
              baseClassName="material-icons-outlined"
              className={styles.moreIcon}
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              more_horiz
            </Icon>
            <div className={styles.contextMenu}>
              <ContextMenu
                open={menuOpen}
                setOpen={setMenuOpen}
                options={
                  inspectionTemplate.isDraft
                    ? [contextMenuCSV, ...contextMenuOptions, deleteOption]
                    : [...contextMenuOptions, reviseOption, deleteOption]
                }
              />
            </div>
          </div>
        </div>
      </div>
      {!!latestRevision && inspectionTemplate?.isDraft && (
        <>
          <Text noMargin weight="semiBold">
            {`${t(keys.common.REVISION)} ${inspectionTemplate?.revisionNumber}`}
          </Text>
          <div className={styles.revisionContainer}>
            <Text weight="semiBold" color="secondary">{`${t(
              keys.common.PREVIOUS_REVISION,
            )}:`}</Text>
            <Text
              className={styles.draftRevisionLink}
              weight="semiBold"
              color="accentPrimary"
              onClick={() =>
                navigate(
                  getRoute(workspace?.id, paths.templates, latestRevision.id),
                )
              }
            >
              {`${latestRevision.title}, ${t(keys.common.REV)}:
              ${latestRevision.revisionNumber}`}
            </Text>
          </div>
        </>
      )}
      {!inspectionTemplate?.id ? (
        <></>
      ) : (
        <div className={styles.container}>
          <div className={styles.top}>
            <div className={styles.header}>
              {!!inspectionTemplate?.isDraft && isWorkspaceAdmin ? (
                <Form
                  className={styles.formIdContainer}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <Text className={styles.label} noMargin weight="bold">
                    {t(keys.common.TITLE)}
                  </Text>
                  <div className={styles.inputContainer}>
                    <Form.Control
                      type="text"
                      placeholder={t(keys.action.ENTER, {
                        variable: t(keys.common.TITLE),
                      })}
                      value={newTitle || ''}
                      onChange={(e) => setNewTitle(e.target.value)}
                      onBlur={() =>
                        update({
                          variables: { id: templateId, title: newTitle },
                        })
                      }
                    />
                  </div>
                </Form>
              ) : (
                <div className={styles.headerContainer}>
                  <Text size="lg" weight="bold" noMargin>
                    {inspectionTemplate.title}
                  </Text>
                  <Text color="secondary" weight="semiBold" noMargin>
                    {`${t(keys.common.REVISION)} ${
                      inspectionTemplate.revisionNumber
                    }`}
                  </Text>
                </div>
              )}
            </div>

            {!!inspectionTemplate?.isDraft && isWorkspaceAdmin ? (
              <Form
                className={styles.formIdContainer}
                onSubmit={(e) => e.preventDefault()}
              >
                <Text className={styles.label} noMargin weight="bold">
                  {t(keys.common.DESCRIPTION)}
                </Text>

                <div className={styles.inputContainer}>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    placeholder={t(keys.action.ENTER, {
                      variable: t(keys.common.DESCRIPTION),
                    })}
                    value={newDescription || ''}
                    onChange={(e) => setNewDescription(e.target.value)}
                    onBlur={() => {
                      update({
                        variables: {
                          id: templateId,
                          description: newDescription,
                        },
                      });
                    }}
                  />
                </div>
              </Form>
            ) : (
              <div className={styles.headerContainer}>
                <Text size="md" weight="semiBold" noMargin>
                  {inspectionTemplate.description}
                </Text>
              </div>
            )}
          </div>

          {inspectionTemplate.templateType === 'OBSERVATION' ? (
            <CreateObservationQuestions
              observationTemplate={inspectionTemplate}
              refetch={refetch}
            />
          ) : (
            <>
              <div className={styles.top}>
                {!!inspectionTemplate?.isDraft && isWorkspaceAdmin ? (
                  <div className={styles.doc}>
                    <div className={styles.formIdInput}>
                      <Text className={styles.text} noMargin weight="bold">
                        {t(keys.templates.FORM_ID)}
                      </Text>
                      {previewDocumentString(inspectionTemplate)}
                      <Form.Control
                        type="text"
                        placeholder={'ABC2020, XY-A123, etc.'}
                        value={newDocumentString || ''}
                        onChange={(e) => setNewDocumentString(e.target.value)}
                        onBlur={() =>
                          update({
                            variables: {
                              id: templateId,
                              documentString: newDocumentString,
                            },
                          })
                        }
                      />
                    </div>
                    <div className={styles.formIdInput}>
                      <Text className={styles.text} noMargin weight="bold">
                        {t(keys.common.DATE)}
                      </Text>
                      <div className={styles.spacer} />
                      <DropdownText
                        required
                        disabled={!newDocumentString}
                        items={dateSelectionTypes}
                        selected={selectedDateOption}
                        onChange={(option) => {
                          setSelectedDateOption(option);
                          const documentStringOption =
                            option === dateSelectionTypes[0]
                              ? null
                              : option.toUpperCase();
                          update({
                            variables: {
                              id: templateId,
                              documentStringOption,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className={styles.formIdInput}>
                      <Text className={styles.text} noMargin weight="semiBold">
                        {t(keys.templates.STARTING_AT)}
                      </Text>
                      {selectedDateOption !== dateSelectionTypes[0] ? (
                        <Text noMargin color="secondary" size="sm">
                          {inspectionTemplate.originalTemplate?.revisions
                            ?.length
                            ? 'This count can only be updated on Rev 0'
                            : 'This will reset to 1 each year'}
                        </Text>
                      ) : (
                        <div className={styles.spacer} />
                      )}
                      <Form.Control
                        disabled={
                          !newDocumentString ||
                          (selectedDateOption !== 'None' &&
                            inspectionTemplate.originalTemplate?.revisions
                              ?.length)
                        }
                        type="number"
                        placeholder={'12, 415, etc.'}
                        value={newStartingAt || ''}
                        onChange={(e) => {
                          if (e.target.value > 99999) {
                            return;
                          }
                          setNewStartingAt(parseInt(e.target.value));
                        }}
                        onBlur={() =>
                          update({
                            variables: {
                              id: templateId,
                              startingAt: newStartingAt,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                ) : (
                  inspectionTemplate?.documentString && (
                    <div className={styles.headerContainer}>
                      <Text weight="semiBold" noMargin color="secondary">
                        {t(keys.templates.FORM_ID)}
                      </Text>
                      <Text weight="semiBold">
                        {inspectionTemplate.documentString}
                        {` ${
                          inspectionTemplate.documentStringOption &&
                          inspectionTemplate.documentStringOption
                        }`}{' '}
                        {` (starts at ${inspectionTemplate.startingAt})`}
                      </Text>
                    </div>
                  )
                )}
              </div>

              <div>
                {inspectionTemplate?.isDraft && isWorkspaceAdmin ? (
                  <div className={styles.selectedOptions}>
                    <Form.Check
                      inline
                      id="approval"
                      label={t(keys.templates.APPROVAL_REQUIRED)}
                      checked={inspectionTemplate?.reviewRequired}
                      onChange={() => {
                        update({
                          variables: {
                            id: templateId,
                            reviewRequired: !inspectionTemplate?.reviewRequired,
                          },
                        });
                      }}
                    ></Form.Check>
                    <Form.Check
                      inline
                      id="analysis"
                      label="SecondSight"
                      checked={inspectionTemplate?.analysisRequested}
                      onChange={() => {
                        update({
                          variables: {
                            id: templateId,
                            analysisRequested:
                              !inspectionTemplate?.analysisRequested,
                          },
                        });
                      }}
                    ></Form.Check>
                    {currentUser.role !== 'USER' && (
                      <Form.Check
                        inline
                        id="workspace"
                        label={t(keys.templates.ALL_WORKSPACES)}
                        checked={inspectionTemplate.workspaceId ? false : true}
                        onChange={(e) => {
                          update({
                            variables: {
                              id: templateId,
                              workspaceId: e.target.checked
                                ? null
                                : workspace?.id,
                            },
                          });
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <div className={styles.selectedOptions}>
                    {inspectionTemplate?.reviewRequired && (
                      <div className={styles.selectedOption}>
                        <Icon sx={{ color: 'green' }}>check_circle</Icon>
                        <Text noMargin>
                          {t(keys.templates.APPROVAL_REQUIRED)}
                        </Text>
                      </div>
                    )}
                    {inspectionTemplate?.analysisRequested && (
                      <div className={styles.selectedOption}>
                        <Icon sx={{ color: 'green' }}>check_circle</Icon>
                        <Text noMargin>{t(keys.templates.SECOND_SIGHT)}</Text>
                      </div>
                    )}
                    {!inspectionTemplate?.workspaceId && (
                      <div className={styles.selectedOption}>
                        <Icon sx={{ color: 'green' }}>check_circle</Icon>
                        <Text noMargin>{t(keys.templates.ALL_WORKSPACES)}</Text>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <br />
              <div className={styles.bottom}>
                <Text size="lg" weight="bold">
                  Categories
                </Text>
                {!!inspectionTemplate.isDraft && isWorkspaceAdmin && (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      createCategory({
                        variables: {
                          id: templateId,
                          title: category,
                        },
                      }).then(() => {
                        setCategory('');
                        refetch();
                      });

                      return false;
                    }}
                  >
                    <div className={styles.categoryInput}>
                      <Form.Group className="mb-3" as={Col}>
                        <Form.Control
                          type="text"
                          placeholder={t(keys.templates.CATEGORY_PLACEHOLDER)}
                          value={category || ''}
                          onChange={(e) => setCategory(e.target.value)}
                        />
                      </Form.Group>
                      <Button
                        className={styles.button}
                        size="md"
                        type="submit"
                        disabled={loading || !category.length || !templateId}
                        value={t(keys.action.ADD, { variable: null })}
                        icon="add"
                      />
                    </div>
                  </Form>
                )}

                <div>
                  {inspectionTemplate.categories.length ? (
                    <div
                      className={
                        !!showModal ? styles.split : styles.categoryContainer
                      }
                    >
                      {inspectionTemplate.categories.map((cat) => (
                        <div
                          style={{ width: showModal ? '65%' : undefined }}
                          className={
                            selectedCat?.id === cat.id
                              ? styles.highlight
                              : styles.categoryWindow
                          }
                          key={cat.id}
                        >
                          <div className={styles.categoryHeader}>
                            <div className={styles.formInput}>
                              {isEditingCatTitle === cat.id ? (
                                <Form.Control
                                  as="textarea"
                                  onChange={(e) => {
                                    setNewCatTitle(e.target.value);
                                  }}
                                  value={newCatTitle}
                                />
                              ) : (
                                <Text
                                  weight="semiBold"
                                  noMargin
                                  className={styles.text}
                                  size="lg"
                                >
                                  {cat.title}
                                </Text>
                              )}
                            </div>
                            <div>
                              {!!inspectionTemplate?.isDraft &&
                                isWorkspaceAdmin && (
                                  <div className={styles.icons}>
                                    <Icon
                                      baseClassName="material-icons-outlined"
                                      className={styles.editIcon}
                                      sx={{
                                        color:
                                          isEditingCatTitle &&
                                          isEditingCatTitle === cat.id
                                            ? 'green'
                                            : undefined,
                                      }}
                                      onClick={() => {
                                        if (isEditingCatTitle) {
                                          updateCategory({
                                            variables: {
                                              categoryId: cat.id,
                                              title: newCatTitle,
                                              templateId,
                                            },
                                          }).then(() => {
                                            setIsEditingCatTitle(null);
                                            setNewCatTitle('');
                                          });
                                        } else {
                                          setShowModal(false);
                                          setIsEditingCatTitle(cat.id);
                                          setNewCatTitle(cat.title);
                                        }
                                      }}
                                    >
                                      {isEditingCatTitle &&
                                      isEditingCatTitle === cat.id
                                        ? 'check'
                                        : 'edit'}
                                    </Icon>
                                    <Icon
                                      onClick={() => {
                                        openConfirmationModal({
                                          title: t(
                                            keys.templates.DELETE_CATEGORY,
                                          ),
                                          description: t(
                                            keys.templates
                                              .DELETE_CATEGORY_WARNING,
                                            { variable: cat.title },
                                          ),
                                          variant: 'danger',
                                          buttonText: t(keys.action.DELETE),
                                          onSubmit: () => {
                                            deleteCategory({
                                              variables: { id: cat.id },
                                            }).then(() => {
                                              setShowModal(false);
                                              refetch();
                                            });
                                          },
                                        });
                                      }}
                                      className={styles.deleteButton}
                                      baseClassName="material-icons-outlined"
                                    >
                                      delete
                                    </Icon>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div>
                            <div className={styles.categoryHeader}>
                              <div className={styles.bundleSwitch}>
                                {(inspectionTemplate.isDraft ||
                                  cat?.isBundle) && (
                                  <Text noMargin color="secondary">
                                    {t(keys.assessments.REPEATING_BUNDLE)}
                                  </Text>
                                )}
                                {inspectionTemplate?.isDraft && (
                                  <Switch
                                    disabled={!inspectionTemplate.isDraft}
                                    checked={cat.isBundle || false}
                                    sx={{
                                      overflow: 'unset',
                                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                        {
                                          backgroundColor: customColors.BLUE,
                                          opacity: 'unset',
                                        },
                                      '.MuiSwitch-input': {
                                        left: 'unset !important',
                                        width: 'unset !important',
                                      },
                                    }}
                                    icon={
                                      <div
                                        className={styles.switchIconContainer}
                                      >
                                        <LayersOutlined
                                          sx={{
                                            height: '20px',
                                            width: '20px',
                                            color: '#6e6e73',
                                          }}
                                        />
                                      </div>
                                    }
                                    checkedIcon={
                                      <div
                                        className={styles.switchIconContainer}
                                      >
                                        <LayersOutlined
                                          sx={{
                                            height: '20px',
                                            width: '20px',
                                            color: '#0a58ca',
                                          }}
                                        />
                                      </div>
                                    }
                                    onChange={(e) =>
                                      updateCategory({
                                        variables: {
                                          templateId: inspectionTemplate.id,
                                          categoryId: cat.id,
                                          isBundle: e.target.checked,
                                        },
                                      })
                                    }
                                  />
                                )}
                              </div>
                              {inspectionTemplate.isDraft ? (
                                <Button
                                  size="sm"
                                  outlined
                                  align="right"
                                  icon="add"
                                  value="Add Question"
                                  onClick={() => {
                                    setSelectedCat(cat);
                                    setSelectedQuestion(null);
                                    setShowModal(true);
                                  }}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className={styles.sectionLine} />

                          {cat.questions.length ? (
                            cat.questions.map((question) => (
                              <div
                                className={
                                  selectedQuestion?.id === question.id
                                    ? styles.highlightQuestion
                                    : styles.question
                                }
                                key={question.id}
                              >
                                <div
                                  key={question.id}
                                  className={styles.questionContainer}
                                >
                                  <Icon
                                    baseClassName="material-icons-outlined"
                                    className={styles.icon}
                                    sx={{ fontSize: '2rem' }}
                                  >
                                    {inputTypeToIconMap[question.inputType]}
                                  </Icon>
                                  <div className={styles.questionTextContainer}>
                                    <Text size="md" weight="semiBold" noMargin>
                                      {`${question.title}`}
                                      <span className={styles.required}>
                                        {question.required ? ' *' : ''}
                                      </span>
                                    </Text>
                                    <Text size="md" noMargin>
                                      {question.description || '  '}
                                    </Text>
                                    <Text
                                      noMargin
                                      size="sm"
                                      color={'secondary'}
                                    >
                                      {t(
                                        getTranslationKey(
                                          question.inputType,
                                          'templates',
                                        ),
                                      )}
                                    </Text>
                                    <Text noMargin size="sm" color="secondary">
                                      {question.inputType === inputTypes.number
                                        ? numberCheck(question?.additionalData)
                                        : question?.additionalData
                                            ?.split('|')
                                            .join(', ')}
                                    </Text>
                                  </div>
                                </div>
                                {!!inspectionTemplate?.isDraft &&
                                  isWorkspaceAdmin && (
                                    <div className={styles.icons}>
                                      <Icon
                                        baseClassName="material-icons-outlined"
                                        className={styles.editIcon}
                                        onClick={() => {
                                          setSelectedQuestion(question);
                                          setSelectedCat(null);
                                          setShowModal(true);
                                        }}
                                      >
                                        edit
                                      </Icon>
                                      <Icon
                                        baseClassName="material-icons-outlined"
                                        className={styles.deleteIcon}
                                        onClick={() => {
                                          openConfirmationModal({
                                            title: t(
                                              keys.templates.DELETE_QUESTION,
                                            ),
                                            description: t(
                                              keys.action.CONFIRMATION_MESSAGE,
                                              {
                                                variable: t(
                                                  keys.action.DELETE_VARIABLE,
                                                  {
                                                    variable: `${t(
                                                      keys.common.QUESTION,
                                                    )} ${question.title}`,
                                                  },
                                                ),
                                              },
                                            ),
                                            variant: 'danger',
                                            buttonText: t(keys.action.DELETE),
                                            onSubmit: () => {
                                              deleteQuestion({
                                                variables: {
                                                  id: question.id,
                                                },
                                              }).then(() => {
                                                setShowModal(false);
                                                cache.evict(
                                                  cache.identify(question),
                                                );
                                                cache.gc();
                                              });
                                            },
                                          });
                                        }}
                                      >
                                        delete
                                      </Icon>
                                    </div>
                                  )}
                              </div>
                            ))
                          ) : (
                            <>
                              <img
                                src={questionEmptyState}
                                className={styles.questionGraphic}
                                alt="question mark"
                              />
                              <Text
                                size="lg"
                                weight="semiBold"
                                textAlign="center"
                              >
                                {t(keys.templates.QUESTIONS_TITLE)}
                              </Text>
                              <Text
                                size="md"
                                weight="semiBold"
                                noMargin
                                textAlign="center"
                                color="secondaryLight"
                              >
                                {t(keys.templates.QUESTIONS_SUBTEXT)}
                              </Text>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.emptyStateContainer}>
                      <img
                        alt="empty"
                        className={styles.graphic}
                        src={emptyStateGraphic}
                      />
                      <Text
                        noMargin
                        size="lg"
                        weight="semiBold"
                        textAlign="center"
                      >
                        {t(keys.templates.ADD_CATEGORIES_TITLE)}
                      </Text>
                      <Text
                        noMargin
                        size="md"
                        weight="semiBold"
                        color="secondary"
                        textAlign="center"
                      >
                        {t(keys.templates.ADD_CATEGORIES_SUBTEXT)}
                      </Text>
                      <br />
                    </div>
                  )}
                </div>
                <AddQuestionMenu
                  category={selectedCat}
                  showModal={showModal}
                  onHide={() => {
                    setSelectedCat(null);
                    setSelectedQuestion(null);
                    setShowModal(false);
                  }}
                  refetchCategory={() => refetch()}
                  selectedQuestion={selectedQuestion}
                />
              </div>
              <br />
              <br />
              {!!inspectionTemplate.images.length && (
                <div>
                  <Text
                    className={styles.categoryLabel}
                    size="md"
                    weight="semiBold"
                    noMargin
                  >
                    {t(keys.common.REFERENCE_IMAGES)}
                  </Text>
                  {inspectionTemplate.images.map((image) => (
                    <div
                      key={`${image.imageUrl}+${image.id}`}
                      className={styles.imageContainer}
                    >
                      <img
                        className={styles.image}
                        alt="reference"
                        src={image.imageUrl}
                      />
                      {!!inspectionTemplate?.isDraft && (
                        <Icon
                          className={styles.deleteIcon}
                          onClick={() => {
                            openConfirmationModal({
                              title: t(keys.action.DELETE_IMAGE),
                              description: t(keys.action.DELETE_CONFIRMATION, {
                                variable: `${t(keys.action.THIS)} ${t(
                                  keys.common.IMAGE,
                                )}`,
                              }),
                              variant: 'danger',
                              buttonText: t(keys.action.DELETE),
                              onSubmit: () => {
                                deleteTemplateImage({
                                  variables: {
                                    imageId: image.id,
                                    inspectionTemplateId: templateId,
                                  },
                                }).then(() => {
                                  cache.evict(cache.identify(image));
                                  cache.gc();
                                });
                              },
                            });
                          }}
                        >
                          delete_outlined
                        </Icon>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {inspectionTemplate.isDraft && isWorkspaceAdmin && (
                <ImageDropzone
                  title=""
                  imageUrl={image}
                  onSubmit={(url) => {
                    createTemplateImage({
                      variables: {
                        inspectionTemplateId: inspectionTemplate.id,
                        imageUrl: url,
                      },
                    }).then(() => refetch());
                  }}
                />
              )}
            </>
          )}
          {!inspectionTemplate?.isDraft && revisions?.length > 0 && (
            <TemplateRevisionTable revisions={revisions} />
          )}
        </div>
      )}
      <br />
      {!!inspectionTemplate.isDraft && isWorkspaceAdmin && (
        <Button
          disabled={
            !inspectionTemplate?.categories?.length ||
            !inspectionTemplate?.categories.some(
              (c) => !!c.questions?.length,
            ) ||
            !inspectionTemplate?.title
          }
          onClick={() => {
            update({
              variables: {
                id: templateId,
                isDraft: false,
                startingAt: newStartingAt || 1,
              },
            }).then(() => {
              if (inspectionTemplate.templateType === 'AUDIT') {
                navigate(`/${paths.audits}`);
              } else {
                navigate(
                  getRoute(
                    workspace.id,
                    inspectionTemplate.templateType === 'INCIDENT'
                      ? paths.incidents
                      : inspectionTemplate.templateType === 'OBSERVATION'
                      ? paths.observations
                      : paths.assessments,
                    '?tab=templates',
                  ),
                );
              }

              cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'inspectionTemplates',
              });
              window.location.reload();
            });
          }}
          className={styles.button}
          variant="success"
          value={t(keys.action.PUBLISH)}
          icon="done"
          size="md"
          align={'right'}
        />
      )}
    </div>
  );
}
