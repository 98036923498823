import React, { useState, useEffect } from 'react';
import styles from './IncidentReportAviation.module.scss';
import { Text } from '../../components/typography';
import { Form } from 'react-bootstrap';
import {
  ATAchapters,
  incidentAviationQuestions,
  aviationPersonnelQuestions,
  aviationJSONQuestions,
  yesNoQuestions,
} from '../../utilities/incidents';
import UserSelector from '../../components/UserSelector';
import TimezoneDropdown from '../../components/TimezoneDropdown';
import Select from 'react-select';
import { aviationFields } from '../../utilities/incidents';
import { useCurrentUser } from '../../providers/UserProvider';
import Icon from '../../components/Icon';

export const IncidentReportAviation = ({
  incident,
  updateIncident,
  readOnly,
}) => {
  const { isAdmin } = useCurrentUser();

  const aviationResults = JSON.parse(incident.aviationQuestions || '{}');

  const initialState = () => {
    const personnel = aviationPersonnelQuestions.personnel.reduce(
      (acc, key) => {
        acc[key] =
          aviationResults.personnel &&
          aviationResults.personnel[key] !== undefined
            ? aviationResults.personnel[key]
            : 0;
        return acc;
      },
      {},
    );
    const aviationData = Object.keys(aviationFields).reduce((acc, key) => {
      acc[key] =
        key in aviationResults
          ? aviationResults[key]
          : yesNoQuestions.includes(key)
          ? false
          : undefined;
      return acc;
    }, {});

    return {
      personnel,
      totalPersonnel: 0,
      cfn: false,
      tsb: false,
      flightOps: false,
      ...aviationData,
    };
  };
  const [inputs, setInputs] = useState(initialState);

  useEffect(() => {
    const totalPersonnel = aviationPersonnelQuestions.personnel.reduce(
      (acc, role) => {
        const count = parseInt(inputs.personnel[role], 10);
        return acc + (isNaN(count) ? 0 : count);
      },
      0,
    );

    setInputs((prev) => ({
      ...prev,
      totalPersonnel,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.injury, inputs.personnel]);

  const handlePersonnelChange = (role, value) => {
    const updatedValue = value.replace(/\D/, '');
    setInputs((prevInputs) => {
      const updatedInputs = {
        ...prevInputs,
        personnel: {
          ...prevInputs.personnel,
          [role]: updatedValue,
        },
      };
      const aviationQuestions = JSON.stringify(updatedInputs, null, 0);
      updateIncident({
        variables: {
          id: incident.id,
          aviationQuestions,
        },
      });
      return updatedInputs;
    });
  };

  const updateAviationQuestions = (key, newValue) => {
    setInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs, [key]: newValue };
      const aviationQuestions = JSON.stringify(updatedInputs, null, 0);

      updateIncident({
        variables: {
          id: incident.id,
          aviationQuestions,
        },
      });

      return updatedInputs;
    });
  };

  const selector = (title, target) => {
    return (
      <div className={styles.top}>
        <Text noMargin weight="bold">
          {title}
        </Text>
        {readOnly ? (
          <Text noMargin>{!!inputs[target] ? 'Yes' : 'No'}</Text>
        ) : (
          <Form.Group>
            <Form.Check
              inline
              type="radio"
              name={target}
              label="Yes"
              checked={inputs[target]}
              onChange={() => {
                updateAviationQuestions(target, true);
                if (target === 'tsb') {
                  updateIncident({
                    variables: { id: incident.id, reportable: true },
                  });
                }
              }}
            />
            <Form.Check
              inline
              type="radio"
              name={target}
              label="No"
              checked={!inputs[target]}
              onChange={() => {
                updateAviationQuestions(target, false);
                if (target === 'tsb') {
                  updateIncident({
                    variables: { id: incident.id, reportable: false },
                  });
                }
              }}
            />
          </Form.Group>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Text noMargin weight="bold">
          {aviationFields.category}
        </Text>
        {readOnly ? (
          <Text noMargin>{inputs.category?.label || 'Not Selected'}</Text>
        ) : (
          <Select
            isClearable
            value={inputs.category}
            options={ATAchapters}
            onChange={(value) => {
              updateAviationQuestions('category', value);
            }}
          />
        )}
      </div>
      {isAdmin && (
        <div>
          {selector(aviationFields.tsb, aviationJSONQuestions.TSB)}
          {inputs.tsb === true && (
            <div className={styles.contactCard}>
              <div className={styles.flex}>
                <Icon opacity={0.5} style={{ fontSize: '1rem' }}>
                  link
                </Icon>
                <Text
                  noMargin
                  weight="semiBold"
                  color="accentPrimary"
                  hover
                  onClick={() =>
                    window.open(
                      'https://www.tsb.gc.ca/eng/form/air-transportation-occurrence-re',
                    )
                  }
                >
                  Transportation Safety Board
                </Text>
                <Icon>right_arrow</Icon>
              </div>
              <div className={styles.flex}>
                <Icon opacity={0.5} style={{ fontSize: '1rem' }}>
                  phone
                </Icon>
                <Text weight="semiBold" noMargin>
                  819-994-3741 or 1-800-387-3557
                </Text>
              </div>
            </div>
          )}
          {selector(aviationFields.cfn, aviationJSONQuestions.CFN)}
        </div>
      )}

      {selector(aviationFields.flightOps, aviationJSONQuestions.FLIGHT_OPS)}
      {inputs.flightOps === true && (
        <div>
          <div className={styles.top}>
            <Text weight="bold" truncate noMargin>
              {aviationFields.employee}
            </Text>
            <UserSelector
              readOnly={readOnly}
              selected={incident?.employee}
              onSelectionChanged={(user) =>
                updateIncident({
                  variables: { id: incident.id, employeeId: user.id },
                })
              }
              onClose={() =>
                updateIncident({
                  variables: { id: incident.id, employeeId: null },
                })
              }
            />
          </div>
          <div className={styles.top}>
            <Text weight="bold" truncate noMargin>
              Date and Time of Departure
            </Text>
            <div className={styles.flex}>
              {readOnly ? (
                <Text noMargin>
                  {aviationResults[
                    aviationJSONQuestions.DEPARTURE_DATE
                  ]?.replace('T', ' ') ?? 'No Date Selected'}
                </Text>
              ) : (
                <input
                  className={styles.date}
                  value={inputs.departureDate}
                  type="datetime-local"
                  onChange={(e) => {
                    updateAviationQuestions(
                      aviationJSONQuestions.DEPARTURE_DATE,
                      e.target.value,
                    );
                  }}
                />
              )}
              <TimezoneDropdown
                readOnly={readOnly}
                onChange={(value) => {
                  updateAviationQuestions(
                    aviationJSONQuestions.TIMEZONE,
                    value,
                  );
                }}
                value={inputs.timezone}
              />
            </div>
          </div>
          {Object.entries(incidentAviationQuestions).map(([key, questions]) => (
            <div key={key} className={styles.aviation}>
              {questions.map(({ convertToQuery, title }) => {
                return (
                  <div className={styles.form} key={title}>
                    <Text noMargin weight="bold">
                      {title}
                    </Text>
                    {readOnly ? (
                      <Text noMargin>
                        {aviationResults[convertToQuery] || 'None'}
                      </Text>
                    ) : (
                      <Form.Control
                        onChange={(e) =>
                          setInputs({
                            ...inputs,
                            [convertToQuery]: e.target.value,
                          })
                        }
                        value={inputs[convertToQuery] || undefined}
                        onBlur={(e) =>
                          updateAviationQuestions(
                            convertToQuery,
                            e.target.value,
                          )
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          ))}
          <br />
          <div className={styles.personnel}>
            <Text weight="semiBold" size="lg">
              {`Personnel on Board (${inputs.totalPersonnel})`}
            </Text>
            <div className={styles.table}>
              <div className={styles.tableRow}>
                {aviationPersonnelQuestions.personnel.map((role) => (
                  <div key={role}>
                    <Text
                      weight="semiBold"
                      truncate
                      noMargin
                      className={styles.text}
                    >
                      {role}
                    </Text>
                    {readOnly ? (
                      <Text noMargin>
                        {aviationResults.personnel[role] || 0}
                      </Text>
                    ) : (
                      <Form.Control
                        type="text"
                        value={inputs.personnel[role]}
                        onChange={(e) =>
                          handlePersonnelChange(role, e.target.value)
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
