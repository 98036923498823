import styles from './Tasks.module.scss';
import { Text } from '../../components/typography';
import TabSystem from '../../components/TabSystem';
import TaskList from './TaskList';
import TaskData from './TaskData';
import { useQueryParams } from '../../hooks/misc';
import { searchParamKeys } from '../../constants/strings';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { Button } from '../../components';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../providers/UserProvider';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import emptyStateImage from '../../assets/empty_state_images/inspections.svg';
import { useWorkspace } from '../../providers/WorkspaceProvider';

const defaultTab = 'tasklist';

export default function Tasks() {
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { getParam } = useQueryParams();
  const tab = getParam(searchParamKeys.tab, defaultTab);
  const { workspace } = useWorkspace();

  const options = [
    {
      title: t(keys.tasks.MANAGE),
      element: TaskList,
      key: 'tasklist',
    },
    {
      title: t(keys.tasks.ANALYTICS),
      element: TaskData,
      key: 'taskdata',
    },
  ];

  return user.role === 'ADMIN' ? (
    <>
      <div className={styles.header}>
        <Text size="lg" weight="bold">
          Tasks
        </Text>
        {tab !== 'taskdata' && (
          <Button
            align={'right'}
            value={'New'}
            icon={'add'}
            onClick={() =>
              openModal({
                modalName: modals.task,
                variables: {
                  taskWorkspace: workspace,
                },
              })
            }
          />
        )}
      </div>
      <br />
      <TabSystem type={tab} options={options} />
    </>
  ) : (
    <div className={styles.emptyStateContainer}>
      <EmptyStateView
        title={t(keys.tasks.NO_DATA_TITLE)}
        text={t(keys.tasks.ACCESS_DENIED)}
        image={emptyStateImage}
      />
    </div>
  );
}
