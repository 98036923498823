import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useApolloClient } from '@apollo/client';
import icons from '../../assets/icons';
import styles from './EditProfile.module.scss';
import { Button } from '../../components';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import _ from 'lodash';
import { loader } from 'graphql.macro';
import { CF_DOMAIN } from '../../constants/aws';
import { useMutation } from '@apollo/client';
import { Text } from '../../components/typography';
import UserSelector from '../../components/UserSelector';
import ImageCropModal from '../../components/image_uploads/ImageCropModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { onSave } from './settings_strings';
import { keys } from '../../utilities/translator/translation_keys';
import { useIndexedDB } from '../../hooks/offline-hooks/indexedDBhook';
import { useUserIndexedDB } from '../../hooks/offline-hooks/userMutationIndexedDB';
import pjson from '../../../package.json';
import { useOnlineStatus } from '../../hooks/offline-hooks/offline-misc';
import { useCurrentUser } from '../../providers/UserProvider';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { useModal } from '../../providers/ModalProvider';
import Joi from '@hapi/joi';

const updateUserMutation = loader(
  '../../graphql/mutations/user_update.graphql',
);

const uploadUrlQuery = loader('../../graphql/queries/s3.graphql');

const userKeys = [
  'firstName',
  'lastName',
  'address',
  'city',
  'postalCode',
  'phone',
  'supervisor.id',
];

export default function EditProfile() {
  const { user: currentUser } = useCurrentUser();
  const online = useOnlineStatus();
  const client = useApolloClient();
  const { clearFileImageDatabase } = useIndexedDB('offline-file-db');
  const packageVersion = pjson.version.split('.');
  const { clearMutationDatabase } = useUserIndexedDB(
    `userSpecificMutation-${packageVersion[0]}.${packageVersion[1]}`,
  );
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [user, setUser] = useState(currentUser);
  const [updateUser] = useMutation(updateUserMutation);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openConfirmationModal } = useModal();

  const { enableTimecards } = useFlags();

  const userSchema = Joi.object({
    firstName: Joi.string().trim().required().messages({
      'string.empty': 'First name is required',
    }),
    lastName: Joi.string().trim().required().messages({
      'string.empty': 'Last name is required',
    }),
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { error } = userSchema.validate(
      { firstName: user.firstName, lastName: user.lastName },
      { abortEarly: false },
    );

    if (error) {
      const fieldErrors = {};
      error.details.forEach((detail) => {
        const field = detail.context.key;
        fieldErrors[field] = detail.message;
      });
      setErrors(fieldErrors);
    } else {
      setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.firstName, user.lastName]);

  const buttonDisabled = useMemo(() => {
    const isUnchanged = userKeys.every(
      (key) => _.get(user, key) === _.get(currentUser, key),
    );
    return isUnchanged || Object.keys(errors).length > 0;
  }, [user, currentUser, errors]);

  useEffect(() => {
    if (!user && currentUser?.id) {
      setUser(currentUser);
    }
  }, [currentUser, user]);

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <br />
        <Text size="lg" weight="semiBold" noMargin>
          {t(keys.settings.PROFILE_PIC)}
        </Text>
        <br />
        <div className={styles.avatarContainer}>
          <img
            className={styles.avatar}
            src={currentUser?.avatarUrl}
            alt="avatar"
          />
          <div
            className={styles.avatarButtonContainer}
            onClick={() => setAvatarModalOpen(true)}
          >
            <img
              className={styles.editButton}
              src={icons.white.edit}
              alt="edit icon"
            />
          </div>
        </div>
        <br />
        <Text size="lg" weight="semiBold">
          {t(keys.settings.BASIC_INFO)}
        </Text>
        <Text className={styles.label} noMargin weight="bold">
          {t(keys.settings.SUPERVISOR)}
        </Text>
        <UserSelector
          className={styles.supervisorContainer}
          type={t(keys.SUPERVISOR)}
          selected={user.supervisor}
          onClose={() => {
            setUser({
              ...user,
              supervisor: null,
              supervisorId: null,
            });
          }}
          onSelectionChanged={(supervisor) => {
            setUser({
              ...user,
              supervisor: supervisor,
              supervisorId: supervisor ? supervisor.id : null,
            });
          }}
        />
        <br />

        <Form noValidate onSubmit={(e) => e.preventDefault()}>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.common.FIRST_NAME)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.common.FIRST_NAME),
                })}
                value={user.firstName}
                onChange={(e) =>
                  setUser({ ...user, firstName: e.target.value })
                }
                isInvalid={!!errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.common.LAST_NAME)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.common.LAST_NAME),
                })}
                value={user.lastName}
                onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                isInvalid={!!errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.EMAIL)}
              </Text>
              <Form.Control disabled required type="text" value={user.email} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.ADDRESS)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.ADDRESS),
                })}
                value={user.address}
                onChange={(e) => setUser({ ...user, address: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.CITY)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.CITY),
                })}
                value={user.city}
                onChange={(e) => setUser({ ...user, city: e.target.value })}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.POSTAL_CODE)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.POSTAL_CODE),
                })}
                value={user.postalCode}
                onChange={(e) =>
                  setUser({ ...user, postalCode: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Text className={styles.label} noMargin weight="bold">
                {t(keys.settings.PHONE)}
              </Text>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.action.ENTER, {
                  variable: t(keys.settings.PHONE),
                })}
                value={user.phone}
                onChange={(e) => setUser({ ...user, phone: e.target.value })}
              />
            </Form.Group>
          </Row>
          {!!enableTimecards && (
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Text className={styles.label} noMargin weight="bold">
                  {t(keys.settings.PAYROLL_ID)}
                </Text>
                {user.payrollId ? (
                  <Text noMargin color="primary">
                    {user.payrollId}
                  </Text>
                ) : (
                  <Text noMargin color="secondary">
                    {t(keys.common.NONE)}
                  </Text>
                )}
              </Form.Group>
            </Row>
          )}
          <br />
        </Form>

        {online && (
          <div>
            <Text className={styles.label} noMargin weight="bold">
              {t(keys.settings.CLEAR_CACHE_TITLE)}
            </Text>
            <Text noMargin color="secondary">
              {t(keys.settings.CLEAR_CACHE_MESSAGE)}
            </Text>
            <br />
            <Button
              disabled={!online}
              variant="warning"
              outlined
              className={styles.warningOutlinedContainer}
              value={t(keys.settings.CLEAR_CACHE)}
              onClick={() => {
                openConfirmationModal({
                  title: t(keys.settings.CLEAR_CACHE),
                  description: t(keys.settings.CLEAR_CACHE_CONFIRMATION),
                  buttonText: t(keys.action.CLEAR),
                  variant: 'warning',
                  onSubmit: async () => {
                    await clearFileImageDatabase();
                    await clearMutationDatabase(currentUser.id).then(() =>
                      navigate(`/${paths.signout}`),
                    );
                  },
                });
              }}
            />
            <br />
          </div>
        )}

        <div className={styles.buttonDisplay}>
          <Button
            className={styles.saveButton}
            disabled={buttonDisabled}
            value={t(keys.action.SAVE)}
            onClick={() => {
              const { error } = userSchema.validate(
                { firstName: user.firstName, lastName: user.lastName },
                { abortEarly: false },
              );

              if (error) {
                const fieldErrors = {};
                error.details.forEach((detail) => {
                  const field = detail.context.key;
                  fieldErrors[field] = detail.message;
                });
                setErrors(fieldErrors);
                return;
              }

              updateUser({
                variables: {
                  ...user,
                },
              }).then((res) => {
                const errors = res.errors;
                onSave({ errors });
              });
            }}
          />
        </div>
      </div>
      {avatarModalOpen && (
        <ImageCropModal
          title="Avatar"
          ratio={1}
          onClose={() => setAvatarModalOpen(false)}
          onSave={(file) => {
            setAvatarModalOpen(false);

            const fileUUID = uuidv4();
            const fileParts = file.name.split('.');
            const fileName = fileUUID;
            const fileType = fileParts[fileParts.length - 1];
            client
              .query({
                query: uploadUrlQuery,
                variables: {
                  fileName: `assets/${currentUser.company.id}/${fileName}.${fileType}`,
                  fileType,
                },
              })
              .then((data) => {
                const signedUrl = data.data.simpleStorageUploadUrl;
                const options = {
                  headers: {
                    'Content-Type': fileType,
                  },
                };
                axios.put(signedUrl, file, options).then((result) => {
                  if (result.status === 200) {
                    updateUser({
                      variables: {
                        ...user,
                        avatarUrl: `${CF_DOMAIN(
                          currentUser,
                        )}${fileName}.${fileType}`,
                      },
                    }).then(onSave);
                    setUser({
                      ...user,
                      avatarUrl: `${CF_DOMAIN(
                        currentUser,
                      )}${fileName}.${fileType}`,
                    });
                  }
                });
              });
          }}
        />
      )}
    </div>
  );
}
