import { format, addDays } from 'date-fns';
import { customColors } from './misc';
import moment from 'moment';

export const cpaStatusTypes = {
  DENIED: { key: 'DENIED', title: 'Unacceptable', color: 'red' },
  IN_PROGRESS: { key: 'IN_PROGRESS', title: 'In Progress', color: 'yellow' },
  ACCEPTED: { key: 'ACCEPTED', title: 'Acceptance', color: 'blue' },
  COMPLETE: { key: 'COMPLETE', title: 'Complete', color: 'green' },
  REVIEWED: { key: 'REVIEWED', title: 'Review', color: 'blue' },
  INTERIM: { key: 'INTERIM', title: 'Interim', color: 'yellow' },
  FINAL: { key: 'FINAL', title: 'Final', color: 'orange' },
};
export const auditTypes = ['Internal', 'External', 'Vendor'];

export function getDateColor(dateStr) {
  const inputDate = new Date(dateStr + 'T00:00:00Z');
  const today = new Date();
  const utcYear = today.getUTCFullYear();
  const utcMonth = today.getUTCMonth();
  const utcDate = today.getUTCDate();
  const todayUTC = new Date(Date.UTC(utcYear, utcMonth, utcDate));
  if (inputDate < todayUTC) {
    return 'red';
  } else if (inputDate.getTime() === todayUTC.getTime()) {
    return 'yellow';
  } else {
    return 'green';
  }
}
export const riskLevels = {
  LOW: { color: 'green', title: 'LOW', tableText: 'LOW' },
  MEDIUM: { color: 'yellow', title: 'MEDIUM', tableText: 'MED' },
  HIGH: { color: 'red', title: 'HIGH', tableText: 'HIGH' },
  OBSERVATION: { color: 'gray', title: 'OFI', tableText: 'OFI' },
};

export const cpaKeys = {
  OBSERVATION: 'Opportunity for Improvement',
  LOW: 'Low Risk',
  MEDIUM: 'Medium Risk',
  HIGH: 'High Risk',
};

export const correctiveActionTimelines = (type, level) => {
  const timelines = {
    INTERIM: {
      LOW: 28,
      MEDIUM: 14,
      HIGH: 1,
      OBSERVATION: 30,
    },
    FINAL: { LOW: 90, MEDIUM: 60, HIGH: 60, OBSERVATION: 90 },
  };
  switch (type) {
    case 'INTERIM':
      return timelines.INTERIM[level];
    case 'FINAL':
      return timelines.FINAL[level];
    default:
      return null;
  }
};

export const getDueDate = (days) => {
  const today = new Date();
  let daysFromNow = today;
  if (!!days) {
    daysFromNow = addDays(today, days);
  }
  return format(daysFromNow, 'yyyy-MM-dd 23:59:59');
};
export function calculateRiskString(value) {
  switch (true) {
    case value > 0 && value <= 6:
      return 'LOW';
    case value > 6 && value <= 12:
      return 'MEDIUM';
    case value > 12:
      return 'HIGH';
    default:
      return null;
  }
}

export const riskColors = {
  LOW: customColors.GREEN,
  MEDIUM: customColors.YELLOW,
  HIGH: customColors.RED,
  OBSERVATION: customColors.BLUE,
};

export const correctiveActionTypes = {
  interim: 'INTERIM',
  final: 'FINAL',
  review: 'REVIEW',
};

export const pastDue = (dueDate) => {
  const today = new moment();
  const isOverdue = today > moment(dueDate);
  return isOverdue;
};

export const CPAActionTypes = {
  review: 'CPA-REVIEW',
  interim: 'CPA-INTERIM',
  final: 'CPA-FINAL',
  accepter: 'ACCEPTER',
  acceptanceComment: 'ACCEPTANCE_COMMENT',
  closer: 'CLOSER',
  closingComment: 'CLOSING_COMMENT',
  accept: 'CPA-ACCEPT',
  reviewer: 'REVIEWER',
  participant: 'PARTICIPANT',
};

export const cpaStringLookup = {
  [CPAActionTypes.review]: 'Effectiveness Review',
  [CPAActionTypes.interim]: 'Interim Corrective Action',
  [CPAActionTypes.final]: 'Final Preventative Action',
  [CPAActionTypes.accept]: 'Acceptance Results',
};
