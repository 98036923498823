import React, { useMemo, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { getRoute } from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import { useTableComponents } from '../../components/tables/MTableComponents.jsx';
import Label from '../../components/action_items/Label.jsx';
import MTTable from '../../components/tables/MTable.jsx';
import { useQueryParams } from '../../hooks/misc.js';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents.jsx';
import { useURLParams } from '../../providers/URLParamProvider.jsx';

const inspectionsQuery = loader('./AuditsTable.graphql');

export default function AuditsTable() {
  const { workspace } = useWorkspace();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { textCell, dateCell, userCell, userFilterOptions } =
    useTableComponents();
  const { filters, page, pageSize, sort, subfilters } = useURLParams();
  const { getParam } = useQueryParams();
  const [dateSelectors, setDateSelectors] = useState({
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
    dateModifiedMin: getParam('dateModified')?.split('__')[0] || '',
    dateModifiedMax: getParam('dateModified')?.split('__')[1] || '',
    dateCompletedMin: getParam('dateCompleted')?.split('__')[0] || '',
    dateCompletedMax: getParam('dateCompleted')?.split('__')[1] || '',
  });

  const { loading, data: { inspections = [], inspectionsCount = 0 } = {} } =
    useQuery(inspectionsQuery, {
      skip: !page || !sort || !pageSize,
      variables: {
        options: {
          page,
          pageSize,
          sort,
          subfilters,
          filters: [
            { field: 'inspectionType', operator: 'eq', value: 'AUDIT' },
            ...filters,
          ],
        },
      },
    });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        Cell: ({ cell }) => textCell({ cell }),
        size: 80,
      },
      {
        accessorFn: (row) => row?.template?.title,
        accessorKey: 'template.title',
        header: t(keys.common.TITLE),
        grow: true,
        enableSorting: false,
        minSize: 150,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        Header: ({ column }) => (
          <div style={{ textWrap: 'nowrap' }}>{column.columnDef.header}</div>
        ),
        size: 120,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.IN_PROGRESS) },
          { value: 'false', label: t(keys.common.COMPLETE) },
        ],
        Cell: ({ cell }) => (
          <Label
            color={!cell.getValue() ? 'green' : 'yellow'}
            name={
              cell.getValue()
                ? t(keys.common.IN_PROGRESS)
                : t(keys.common.COMPLETE)
            }
          />
        ),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('all'),
        Cell: ({ cell }) => userCell({ cell }),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateModified',
        header: t(keys.common.DATE_MODIFIED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateModifiedMin}
            maxDate={dateSelectors.dateModifiedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCompleted',
        header: t(keys.common.DATE_COMPLETED),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCompletedMin}
            maxDate={dateSelectors.dateCompletedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCompletedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCompletedMax: value })
            }
          />
        ),
      },
    ],
    [t, userFilterOptions, textCell, userCell, dateCell, dateSelectors],
  );

  return (
    <MTTable
      rightToolbar="assessments"
      isLoading={loading}
      data={inspections || []}
      columns={columns}
      rowCount={inspectionsCount}
      onRowClick={(assessment) =>
        navigate(getRoute(workspace?.id, paths.assessment, assessment.id))
      }
      onClearAllFilters={() =>
        setDateSelectors({
          dateCreatedMin: '',
          dateCreatedMax: '',
          dateModifiedMin: '',
          dateModifiedMax: '',
        })
      }
    />
  );
}
