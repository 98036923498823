import i18n from 'i18next';
import { keys } from './translator/translation_keys';

export const incidentTypes = {
  'Health and Safety': [
    'Fatality',
    'Lost Time',
    'Medical Aid',
    'First Aid',
    'Near Miss',
    'Medical - No Treatment',
    'Other',
  ],
  Environment: [
    'Spill to Land',
    'Spill to Water',
    'Waste Segregation',
    'Near Miss',
    'Other',
  ],
  Commercial: ['Change'],
  'Equipment/Property': ['Maintenance', 'Failure', 'Damage', 'Other'],
  Aviation: [
    'Administration or Support',
    'Engineering',
    'Flight Operation',
    'Facilities',
    'Hazard Identification or Event',
    'PMC',
    'Security Event',
    'Regulatory Compliance',
    'Environment',
    'Human Factors',
    'Passenger Related',
    'Other',
  ],
};

export const incidentDetailQuestions = [
  {
    title: keys.incidents.DATE_OF_INCIDENT,
    convertToQuery: 'dateOfIncident',
    inputType: 'DATE_TIME',
    id: 1,
    required: true,
  },
  {
    title: keys.incidents.LOCATION,
    convertToQuery: 'location',
    inputType: 'TEXT',
    id: 3,
    required: true,
  },
  {
    title: keys.incidents.INCIDENT_DESCRIPTION,
    convertToQuery: 'description',
    inputType: 'TEXT_MULTI',
    id: 4,
    required: true,
  },
];

export const aviationPersonnelQuestions = {
  personnel: ['Flight Crew', 'Cabin Crew', 'Passengers', 'Other'],
};

export const incidentAviationQuestions = {
  flight: [
    {
      title: 'Flight Number',
      inputType: 'TEXT',
      convertToQuery: 'flightNumber',
    },
  ],
  destination: [
    {
      title: 'Departure Location',
      inputType: 'TEXT',
      convertToQuery: 'departureLocation',
    },
    {
      title: 'Intended Destination',
      inputType: 'TEXT',
      convertToQuery: 'destination',
    },
  ],
  latLong: [
    {
      title: 'Occurence Latitude',
      inputType: 'TEXT',
      convertToQuery: 'lat',
    },
    {
      title: 'Occurence Longitude',
      inputType: 'TEXT',
      convertToQuery: 'long',
    },
  ],
};

export const ATAchapters = [
  { key: '05', label: 'ATA-05 Time Limits/Maintenance Checks' },
  { key: '11', label: 'ATA-11 Placards and Markings' },
  { key: '20', label: 'ATA-20 Standard Practices' },
  { key: '21', label: 'ATA-21 Air Conditioning' },
  { key: '23', label: 'ATA-23 Communications' },
  { key: '24', label: 'ATA-24 Electrical Power' },
  { key: '25', label: 'ATA-25 Equipment and Furnishings' },
  { key: '27', label: 'ATA-27 Flight Controls' },
  { key: '28', label: 'ATA-28 Fuel' },
  { key: '29', label: 'ATA-29 Hydraulic Power' },
  { key: '30', label: 'ATA-30 Ice and Rain Protection' },
  { key: '31', label: 'ATA-31 Instruments' },
  { key: '32', label: 'ATA-32 Landing Gear' },
  { key: '33', label: 'ATA-33 Lights' },
  { key: '34', label: 'ATA-34 Navigation' },
  { key: '36', label: 'ATA-36 Pneumatic' },
  { key: '49', label: 'ATA-49 Auxiliary Power Unit' },
  { key: '52', label: 'ATA-52 Doors' },
  { key: '71', label: 'ATA-71 Powerplant' },
  { key: '78', label: 'ATA-78 Engine Exhaust' },
  { key: '00', label: 'Other/Misc' },
];

export const incidentStatusTypes = {
  INITIATED: {
    color: 'red',
    icon: 'play_circle_outline_icon',
    title: 'Initiated',
  },
  INVESTIGATION: {
    color: 'yellow',
    icon: 'person_search',
    title: 'In Progress',
  },
  COMPLETE: {
    color: 'green',
    icon: 'check_circle',
  },
  PENDING: {
    color: 'red',
    icon: 'timer',
    title: 'Initiated',
  },
  CLOSED: {
    color: 'gray',
    icon: 'check_circle',
  },
  INVALID: {
    color: 'graylight',
    icon: 'highlight_off',
  },
};

export const getLabel = (status) => {
  switch (status) {
    case 'INITIATED':
      return `${i18n.t(keys.incidents.INITIATED)}`;
    case 'INVESTIGATION':
      return `${i18n.t(keys.incidents.INVESTIGATION)}`;
    case 'COMPLETE':
      return `${i18n.t(keys.common.COMPLETE)}`;
    case 'PENDING':
      return `${i18n.t(keys.incidents.PENDING)}`;
    case 'CLOSED':
      return `${i18n.t(keys.incidents.CLOSED)}`;
    case 'INVALID':
      return `${i18n.t(keys.incidents.INVALID)}`;
    default:
      return '-';
  }
};

export const aviationJSONQuestions = {
  EMPLOYEE: 'employee',
  FLIGHT_NUMBER: 'flightNumber',
  DEPARTURE_LOCATION: 'departureLocation',
  LAT: 'lat',
  LONG: 'long',
  DESTINATION: 'destination',
  DEPARTURE_DATE: 'departureDate',
  TIMEZONE: 'timezone',
  FLIGHT_OPS: 'flightOps',
  CATEGORY: 'category',
  TSB: 'tsb',
  CFN: 'cfn',
  INVESTIGATION: 'investigation',
  IMPACT: 'impact',
  PROBABILITY: 'probability',
};

export const aviationFields = {
  category: 'Incident Category',
  tsb: 'Is this TSB reportable?',
  cfn: 'Customer Flight Notification Required?',
  investigation: 'Investigation Required?',
  flightOps: 'Did the incident involve Flight Operations?',
  employee: 'Pilot in Command',
  departureDate: 'Date and Time of Departure',
  timezone: 'Timezone',
  flightNumber: 'Flight Number',
  departureLocation: 'Departure Location',
  lat: 'Departure Latitude',
  long: 'Departure Longitude',
  destination: 'Intended Destination',
};

export const yesNoQuestions = ['tsb', 'cfn', 'investigation', 'flightOps'];

export const aviationResponseTime = {
  Low: 15,
  Medium: 10,
  High: 4,
};
