import React, { useEffect, useState } from 'react';
import Select from 'react-select';

function WorkspaceLocations({
  workspace,
  updateWorkspace,
  refetchWorkspace,
  companyLocations = [],
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const workspaceLocations = workspace.locations?.split('|') || [];
    const initialLocations =
      workspaceLocations?.map((location) => ({
        value: location,
        label: location,
      })) || [];

    setSelectedOptions(initialLocations);
  }, [workspace.locations]);

  const locationOptions =
    companyLocations?.map((location) => ({
      value: location,
      label: location,
    })) || [];

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    const locations =
      selectedOptions
        ?.map((option) => option.value)
        ?.filter(Boolean)
        .join('|') || null;

    updateWorkspace({
      variables: { id: workspace.id, locations },
    }).then(() => refetchWorkspace());
  };

  return (
    <div>
      <Select
        isMulti
        value={selectedOptions}
        options={locationOptions}
        closeMenuOnSelect={false}
        onChange={handleChange}
      />
    </div>
  );
}

export default WorkspaceLocations;
