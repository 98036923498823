import { CPAActionTypes } from './audits';
import { customColors } from './misc';
import { assessmentTypes } from '../constants/strings';
import { getColor } from '../constants/strings';

export const actionItemTypeIcons = {
  FLEET: {
    icon: 'local_shipping',
    color: 'yellow',
  },
  HR: {
    icon: 'groups',
    color: 'blue',
  },
  INSPECTION: {
    icon: 'search',
    color: 'purple',
  },
  SERVICE: {
    icon: 'build',
    color: 'red',
  },
  HSE: {
    icon: 'health_and_safety',
    color: 'red',
  },
};

export const actionItemIcons = (value) => {
  switch (value) {
    case 'vehicle':
      return { icon: 'local_shipping', color: customColors.YELLOW };
    case CPAActionTypes.interim.toLowerCase():
    case CPAActionTypes.final.toLowerCase():
    case CPAActionTypes.review.toLowerCase():
    case CPAActionTypes.accept.toLowerCase():
      return {
        icon: 'error',
        color: customColors.RED_LIGHT,
        label: 'Corrective',
      };
    default:
      return {
        icon: assessmentTypes[value]?.icon,
        color: getColor(value),
      };
  }
};
