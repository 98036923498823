import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import styles from './Boards.module.scss';
import React, { useState } from 'react';
import { Text } from '../../components/typography';
import { Button } from '../../components';
import BoardCard from './BoardCard';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/modals/Modal';
import { Form, Spinner } from 'react-bootstrap';
import { useModal } from '../../providers/ModalProvider';
import { t } from 'i18next';
import { keys } from '../../utilities/translator/translation_keys';

const boardQuery = loader('./Boards.graphql');
const boardMutation = loader('./Boards.add.graphql');
const deleteBoardMutation = loader('./Boards.delete.graphql');

export default function Boards() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    data: { boards = [] } = {},
    refetch,
    loading,
  } = useQuery(boardQuery, { fetchPolicy: 'no-cache' });
  const [newBoardName, setNewBoardName] = useState('');
  const [newBoardDescription, setNewBoardDescription] = useState('');

  const [addBoard] = useMutation(boardMutation, { fetchPolicy: 'no-cache' });
  const [deleteBoard] = useMutation(deleteBoardMutation);
  const { openConfirmationModal } = useModal();

  const handleAddBoard = () => {
    setModalOpen(true);
  };

  const handleSubmit = () => {
    addBoard({
      variables: {
        title: newBoardName,
        description: newBoardDescription,
      },
    }).then(
      ({
        data: {
          addBoard: { id },
        },
      }) => {
        navigate(`board/${id}`);
      },
    );
  };

  const handleClose = () => {
    setNewBoardName('');
    setNewBoardDescription('');
    setModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <Modal
        open={modalOpen}
        title={t(keys.boards.NEW_BOARD_TITLE)}
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitDisabled={!newBoardName.length}
      >
        <div className={styles.modalContentContainer}>
          <img
            className={styles.modalGraphic}
            src="https://app.opasmobile.com/assets/graphics/board_name_empty.png"
            alt="empty board"
          />
          <Text textAlign="center" size="lg" weight="semiBold">
            {t(keys.boards.NEW_BOARD_MESSAGE_ONE)}
          </Text>
          <Text textAlign="center" weight="semiBold" color="secondary">
            {t(keys.boards.NEW_BOARD_MESSAGE_TWO)}
          </Text>
        </div>
        <Form.Label>{t(keys.boards.BOARD_NAME)}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t(keys.boards.ENTER_BOARD_NAME)}
          value={newBoardName}
          onChange={(e) => setNewBoardName(e.target.value)}
        />
        <br />
        <Form.Label>{t(keys.boards.BOARD_DESCRIPTION)}</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={t(keys.boards.ENTER_BOARD_DESCRIPTION)}
          value={newBoardDescription}
          onChange={(e) => setNewBoardDescription(e.target.value)}
        />
      </Modal>
      <div className={styles.header}>
        <Text size="lg" weight="bold">
          {t(keys.common.BOARDS)}
        </Text>
        <Button
          value={t(keys.action.NEW_VARIABLE, {
            variable: t(keys.common.BOARD),
          })}
          icon="add"
          onClick={handleAddBoard}
        />
      </div>
      <br />
      <div className={styles.cardContainer}>
        {loading ? (
          <div className={styles.loading}>
            <Spinner variant="primary" />
          </div>
        ) : !!boards?.length ? (
          boards.map((board) => (
            <BoardCard
              key={`Board:${board.id}`}
              board={board}
              onDelete={() => {
                openConfirmationModal({
                  title: t(keys.boards.DELETE_BOARD_CONFIRM),
                  description: t(keys.boards.DELETE_BOARD_MESSAGE, {
                    variable: board.title,
                  }),
                  buttonText: t(keys.action.CONFIRM),
                  variant: 'danger',
                  onSubmit: () => {
                    deleteBoard({ variables: { id: board.id } }).then(() => {
                      refetch();
                    });
                  },
                });
              }}
              onClick={() => {
                navigate(`board/${board.id}`);
              }}
            />
          ))
        ) : (
          <div className={styles.emptyStateParent}>
            <img
              className={styles.emptyStateGraphic}
              alt="Graphs"
              src="https://app.opasmobile.com/assets/graphics/insights_es.png"
            />
            <div className={styles.emptyStateContainer}>
              <Text textAlign="center" size="lg" weight="semiBold">
                {t(keys.boards.EMPTY_STATE_BOARDS_HEADER)}
              </Text>
              <Text
                textAlign="center"
                size="md"
                weight="semiBold"
                color="secondary"
              >
                {t(keys.boards.EMPTY_STATE_BOARDS_MESSAGE)}
              </Text>
            </div>
            <br />
            <Button value={t(keys.action.START)} onClick={handleAddBoard} />
          </div>
        )}
      </div>
    </div>
  );
}
