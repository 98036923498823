import Modal from '../Modal';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import styles from './CreateAuditModal.module.scss';
import { Text } from '../../typography';
import { Form } from 'react-bootstrap';
import { auditTypes } from '../../../utilities/audits.js';
import { DropdownText } from '../../dropdowns/DropdownText';
import Icon from '../../Icon';
import DropdownCombo from '../../dropdowns/DropdownCombo';
import { dropdownTypes } from '../../dropdowns/dropdown';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { useTemplates } from '../../../providers/TemplateProvider';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../../constants/strings';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { paths } from '../../../constants/strings';
import UserMultiSelector from '../../UserMultiSelector';

const getAllVehiclesQuery = loader('./CreateAuditModal.allAssets.graphql');
const addInspectionMutation = loader('./CreateAuditModal.create.graphql');

export default function CreateAuditModal() {
  const { modalState, closeModal, updateModal } = useModal();
  const { show, asset, template, auditType, participants, customer, subject } =
    modalState.createAuditModal;
  const navigate = useNavigate();
  const [addInspection] = useMutation(addInspectionMutation);
  const onHide = () => closeModal({ modalName: modals.audit });
  const { t } = useTranslation();
  const { templates } = useTemplates();
  const { workspaceId } = useWorkspace();
  const update = (variables) =>
    updateModal({
      modalName: modals.audit,
      variables: { ...variables },
    });
  const { data: { vehicles = [] } = {} } = useQuery(getAllVehiclesQuery, {});

  return (
    <Modal
      overflow="visible"
      open={show || false}
      onClose={onHide}
      title="Start Audit"
      submitDisabled={!template}
      titleImage={<Icon color="purple">checklist</Icon>}
      onSubmit={() => {
        addInspection({
          variables: {
            templateId: template.id,
            vehicleId: asset?.id || null,
            inspectionType: 'AUDIT',
            participants: participants.map((user) => user.id),
            subject,
            customer,
            auditType,
            workspaceId: null,
          },
        }).then(
          ({
            data: {
              addInspection: { id },
            },
          }) => {
            navigate(getRoute(workspaceId, paths.assessment, id));
          },
        );
        onHide();
      }}
    >
      <div className={styles.container}>
        <DropdownCombo
          type={dropdownTypes.TEMPLATE}
          title="Checklist"
          highlight
          preventStateChange
          items={templates || []}
          onChange={(newTemplate) => {
            update({ template: newTemplate });
          }}
          onRemove={() => update({ template: null })}
          selected={template}
        />
        <DropdownCombo
          type={dropdownTypes.ASSET}
          title={t(keys.common.ASSET)}
          items={vehicles || []}
          onChange={(newAsset) => {
            update({ asset: newAsset });
          }}
          selected={asset}
          optional={true}
          onRemove={() => update({ asset: null })}
        />
        <div className={styles.content}>
          <Text noMargin weight="semiBold">
            Subject
          </Text>
          <Form.Control
            onChange={(e) => {
              update({ subject: e.target.value });
            }}
            placeholder="Enter Subject"
          />
          <Text noMargin weight="semiBold">
            Customer
          </Text>
          <Form.Control
            onChange={(e) => {
              update({ customer: e.target.value });
            }}
            placeholder="Enter Customer"
          />

          <Text noMargin weight="semiBold">
            Type
          </Text>
          <DropdownText
            items={auditTypes}
            onChange={(newValue) => update({ auditType: newValue })}
            selected={auditType}
          />
        </div>
        <Text weight="semiBold" className={styles.title} noMargin>
          {t(keys.common.PARTICIPANTS)}
        </Text>
        <UserMultiSelector
          selected={participants}
          type="all"
          onUserAdded={(user) => {
            update({ participants: [...participants, user] });
          }}
          onUserRemoved={(user) => {
            update({
              participants: participants.filter((u) => u.id !== user.id),
            });
          }}
        />
      </div>
    </Modal>
  );
}
