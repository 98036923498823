import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { formatBundleItems, numberCheck } from '../../inspection';
import { inputTypes } from '../../../constants/strings';
import { Answer } from './assessment_answers';
import { customColors } from '../../misc';

const fontSize = 8;
const blue = customColors.BLUE;
const textPrimary = '#1d1d1f';
const textSecondary = customColors.SECONDARY;

const styles = StyleSheet.create({
  bundleIcon: {
    height: '12px',
    width: '12px',
  },
  categoryHeaderContainer: {
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'flex-start',
  },
  categoryHeader: {
    fontSize: fontSize + 5,
    fontFamily: 'eina03bold',
    color: blue,
    marginBottom: 3,
    marginTop: 3,
  },
  category: {
    flexDirection: 'column',
  },
  bundleAnswer: {
    flexDirection: 'column',
    gap: 5,
  },
  text: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03regular',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
  },
  limitsText: {
    fontSize: fontSize - 1,
    color: textSecondary,
    fontFamily: 'eina03regular',
  },
  semiBoldText: {
    fontSize: fontSize + 2,
    color: textPrimary,
    fontFamily: 'eina03semibold',
  },
  bundle: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 5,
    marginTop: 3,
    marginBottom: 3,
  },
  answer: {
    flexDirection: 'column',
    gap: 7,
    marginTop: 3,
    marginBottom: 3,
  },
});

export const Category = ({
  category,
  categoryId,
  isBundle,
  questions,
  answers,
}) => {
  const { bundleMap } = formatBundleItems(answers);
  const getAnswer = (answers, question) => {
    return answers?.find((a) => a.questionId === question.id)
      ? answers?.find((a) => a.questionId === question.id)?.value
      : null;
  };
  return (
    <View style={styles.category}>
      <View style={styles.categoryHeaderContainer}>
        <Text style={styles.categoryHeader}>{category}</Text>
        {isBundle && (
          <View>
            <Image
              style={styles.bundleIcon}
              alt={'bundle_icon'}
              src={
                'https://app.opasmobile.com/assets/graphics/layers_pdf_icon.png'
              }
            />
          </View>
        )}
      </View>
      {isBundle ? (
        <>
          {!!bundleMap[categoryId] &&
            Object.entries(bundleMap[categoryId]).map(
              ([bundleId, bundleAnswers], idx) => (
                <View key={`bundle-${bundleId}`} style={styles.bundle}>
                  <Text style={styles.semiBoldText}>{`${idx + 1}.`}</Text>
                  <View style={styles.bundleAnswer}>
                    {Object.values(bundleAnswers)?.map((answer, index) => {
                      if (answer.categoryId !== categoryId) return [];
                      const question = questions.find(
                        (question) => question.id === answer.questionId,
                      );
                      return (
                        <View
                          key={`question-${question.id}-${bundleId}-${index}`}
                        >
                          <Text style={styles.semiBoldText}>
                            {question.title}
                          </Text>
                          {question.inputType === inputTypes.number &&
                          !!question.additionalData ? (
                            <Text style={styles.limitsText}>
                              {numberCheck(question.additionalData)}
                            </Text>
                          ) : null}
                          <Answer
                            answer={answer}
                            inputType={question.inputType}
                            additional={question.additionalData}
                            question={question}
                          >
                            {answer?.value}
                          </Answer>
                        </View>
                      );
                    })}
                  </View>
                </View>
              ),
            )}
        </>
      ) : (
        <View style={styles.answer}>
          {questions?.map((question, index) => {
            const answer = answers?.find((a) => a.questionId === question.id);
            return (
              <View key={`question-${question.id}-${index}`}>
                <Text style={styles.semiBoldText}>{question.title}</Text>
                {question.inputType === inputTypes.number &&
                !!question.additionalData ? (
                  <Text style={styles.limitsText}>
                    {numberCheck(question.additionalData)}
                  </Text>
                ) : null}

                <Answer
                  answer={answer}
                  inputType={question.inputType}
                  additional={question.additionalData}
                  question={question}
                >
                  {getAnswer(answers, question)}
                </Answer>
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
};
