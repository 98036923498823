import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { keys, languages } from './utilities/translator/translation_keys';
import moment from 'moment';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    detection: {
      order: ['navigator'],
    },
    ns: Object.keys(keys),
    defaultNS: 'common',
    fallbackLng: 'en',
    debug: false,
    whiteList: Object.keys(languages),
    load: 'languageOnly',
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
