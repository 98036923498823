import classNames from 'classnames';
import React from 'react';
import styles from './SimpleUserCard.module.scss';
import { Text } from './typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { useNavigate } from 'react-router-dom';
import { paths } from '../constants/strings';
import { useOnlineStatus } from '../hooks/offline-hooks/offline-misc';

export default function SimpleUserCard({
  user,
  weight = 'semiBold',
  size = 'md',
  largeAvatar = false,
  mediumAvatar = false,
  className,
  hideAvatar = false,
  hideNameOnMobile = false,
  smallText = '',
}) {
  const { t } = useTranslation();
  const isOnline = useOnlineStatus();
  const navigate = useNavigate();
  return user?.id && !hideAvatar ? (
    <div
      className={classNames(styles.container, className)}
      onClick={() => {
        if (isOnline) {
          navigate(`/${paths.user}/${user?.id}`);
        }
      }}
    >
      {!hideAvatar && (
        <img
          alt="user avatar"
          src={user.avatarUrl}
          className={
            mediumAvatar
              ? styles.mediumAvatar
              : largeAvatar
              ? styles.largeAvatar
              : styles.avatar
          }
        />
      )}
      <div className={styles.column}>
        <Text
          className={classNames(
            styles.text,
            hideNameOnMobile && styles.hideTextOnMobile,
          )}
          size={size}
          noMargin
          weight={weight}
          noSelect
        >{`${user.firstName} ${user.lastName}`}</Text>
        <Text size="sm" noMargin>
          {smallText}
        </Text>
      </div>
    </div>
  ) : user?.id ? (
    <div
      className={classNames(
        styles.container,
        hideNameOnMobile && styles.hideTextOnMobile,
        className,
      )}
    >
      <Text
        size={size}
        noMargin
        weight={weight}
        noSelect
      >{`${user.firstName} ${user.lastName}`}</Text>
    </div>
  ) : (
    <div className={styles.container}>
      <Text size={size} noMargin noSelect>
        {t(keys.common.NONE)}
      </Text>
    </div>
  );
}
