import { StyleSheet } from '@react-pdf/renderer';
import { customColors } from '../../misc';
const fontSize = 8;
const textPrimary = '#1d1d1f';
const green = customColors.GREEN;
const red = customColors.RED;
const blue = customColors.BLUE;

export const styles = StyleSheet.create({
  text: {
    fontSize,
    color: textPrimary,
    fontFamily: 'eina03regular',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
  },
  textPass: {
    fontSize,
    color: green,
    fontFamily: 'eina03bold',
  },
  textFail: {
    fontSize,
    color: red,
    fontFamily: 'eina03bold',
  },
  signature: {
    maxWidth: 250,
    maxHeight: 100,
  },
  image: {
    maxWidth: 250,
    maxHeight: 250,
    padding: 2,
  },
  link: {
    fontSize,
    fontFamily: 'eina03semibold',
    color: blue,
    wordBreak: 'break-all',
    flexWrap: 'wrap',
  },
});
