import React, { useState, useMemo, useEffect } from 'react';
import styles from './AddTimecard.module.scss';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Text } from '../../components/typography';
import { Form, Spinner } from 'react-bootstrap';
import { Button } from '../../components';
import { loader } from 'graphql.macro';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { paths } from '../../constants/strings';
import { useQueryParams, useMobileListener } from '../../hooks/misc';
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import DropdownCombo from '../../components/dropdowns/DropdownCombo';
import { timecardStatus } from '../../constants/misc';
import moment from 'moment';
import SimpleUserCard from '../../components/SimpleUserCard';
import { getLocalTime } from '../../utilities/time';
import {
  deleteButton,
  requiredKeys,
  reviewerButtons,
  submitButton,
  timecardStatusTypes,
  paidButton,
} from './Timecards';
import VehicleCard from '../../components/vehicles/VehicleCard';
import ActivitySelector from '../../components/activities/ActivitySelector';
import { Icon } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { assessmentTypes } from '../../constants/strings';
import Label from '../../components/action_items/Label';
import { getRoute } from '../../constants/strings';
import { activityStatusOptions } from '../../components/activities/activites';
import { useCurrentUser } from '../../providers/UserProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { dropdownTypes } from '../../components/dropdowns/dropdown';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import FlagDisabled from '../flagDisabled/FlagDisabled';
import { useFlags } from 'launchdarkly-react-client-sdk';

const timecardQuery = loader('./AddTimecard.read.graphql');
const addTimecardMutation = loader('./AddTimecard.create.graphql');
const updateTimecardMutation = loader('./AddTimecard.update.graphql');
const vehiclesQuery = loader('./AddTimecard.vehicles.graphql');
const deleteTimecardMutation = loader('./AddTimecard.delete.graphql');
const updateActivityMutation = loader('./AddTimecard.updateActivity.graphql');

export default function AddTimecard() {
  const { t } = useTranslation();
  const { getParam } = useQueryParams();
  const id = getParam('id');
  const timecardId = useMemo(() => parseInt(id), [id]);
  const { user, isAdmin, team } = useCurrentUser();
  const [addTimecard] = useMutation(addTimecardMutation);
  const navigate = useNavigate();
  const [updateTimecard] = useMutation(updateTimecardMutation);
  const { openModal, openConfirmationModal, closeModal } = useModal();

  const [nonBillableHours, setNonBillableHours] = useState(0);
  const [isEditingComments, setIsEditingComments] = useState(false);
  const [deleteTimecard] = useMutation(deleteTimecardMutation);
  const [updateActivity] = useMutation(updateActivityMutation);
  const isMobile = useMobileListener();

  const { data: { timecards: [timecard] = [{}] } = {} } = useQuery(
    timecardQuery,
    {
      skip: !id,
      fetchPolicy: 'cache-and-network',
      variables: {
        options: {
          filters: [{ field: 'id', operator: 'eq', value: [`${id}`] }],
        },
      },
    },
  );
  const [description, setDescription] = useState(timecard?.description || '');

  useEffect(() => {
    if (timecard?.id) {
      setDescription(timecard?.description);
      setNonBillableHours(timecard?.nonBillableHours);
    }
  }, [timecard?.description, timecard?.id, timecard?.nonBillableHours]);

  const [comments, setComments] = useState(timecard?.notes || '');
  useEffect(() => setComments(timecard?.notes), [timecard?.notes]);

  const { creator, vehicle, reviewer, activity, workspace, inspections } =
    timecard || {};

  const readOnly = timecard?.status !== timecardStatus.draft;

  const { data: { workspaceVehicles = [] } = {} } = useQuery(vehiclesQuery, {
    skip: !workspace?.id || readOnly,
    variables: {
      options: {
        filters: [
          {
            field: 'workspaceId',
            operator: 'eq',
            value: [`${workspace?.id}`],
          },
        ],
      },
    },
  });

  const isCreator = timecard?.creator?.id === user?.id;
  const isReviewer = timecard?.reviewer?.id === user?.id;
  const isUserSupervisor = !!team?.some((u) => u.id === creator?.id);

  const allowedApprovers = isAdmin || isUserSupervisor;

  const canApprove =
    !isCreator &&
    allowedApprovers &&
    timecard?.status === timecardStatus.submitted;

  const isComplete =
    timecard?.status === timecardStatus.approved ||
    timecard?.status === timecardStatus.denied;

  const allowRevert =
    (isCreator && timecard?.status === timecardStatus.submitted) ||
    (isReviewer && isComplete) ||
    (isAdmin && timecard?.status !== timecardStatus.draft);

  const allowChanges = isCreator && timecard?.status === timecardStatus.denied;

  const canMarkAsPaid =
    isAdmin && !isCreator && timecard?.status === timecardStatus.approved;
  const isPaid = timecard?.status === timecardStatus.complete;

  const hasBeenReviewed = !!reviewer;

  const disableSubmit = useMemo(() => {
    return requiredKeys.some(
      (key) => timecard[key] === null || timecard[key] === '',
    );
  }, [timecard]);

  const billableHours = useMemo(() => {
    const { startTime, endTime, nonBillableHours } = timecard;

    if (startTime && endTime) {
      const startMoment = moment(parseInt(startTime));
      const endMoment = moment(parseInt(endTime));

      if (startMoment.isValid() && endMoment.isValid()) {
        let totalHours = endMoment.diff(startMoment, 'hours', true);

        if (nonBillableHours) {
          totalHours -= nonBillableHours;
        }
        return totalHours >= 0 ? totalHours.toFixed(2) : 0;
      }
    }
    return 0;
  }, [timecard]);

  const onSubmit = ({ newValue, target }) => {
    updateTimecard({
      variables: { id: timecardId, [target]: newValue },
    });
  };
  const DateTimePickerComponent = isMobile
    ? MobileDateTimePicker
    : DateTimePicker;

  const mobileStyle = isMobile
    ? {
        textField: {
          InputProps: {
            endAdornment: (
              <InputAdornment
                sx={{
                  color: '#979797',
                }}
                position="end"
              >
                <CalendarIcon />
              </InputAdornment>
            ),
          },
        },
      }
    : null;

  const revertTimecard = () => {
    const initialStatus = timecard.status;
    const status = isComplete ? timecardStatus.submitted : timecardStatus.draft;
    updateTimecard({
      variables: {
        id: timecardId,
        status,
        reviewerId: null,
      },
    });
    if (initialStatus === timecardStatus.approved && !activity.isArchived) {
      updateActivity({
        variables: {
          id: parseInt(activity?.id),
          actualHours: parseFloat(activity?.actualHours - billableHours),
          actualStartDate: null,
          actualEndDate: null,
          status: activityStatusOptions.notStarted,
        },
      });
    }
  };
  const activityToUpdate = useMemo(() => {
    const id = parseInt(activity?.id);
    const progress = 50;
    const actualStartDate = activity?.actualStartDate
      ? activity?.actualStartDate
      : moment(parseInt(timecard?.startTime)).format('MM/DD/YYYY HH:mm:ss');
    const actualEndDate = null;
    const status = activityStatusOptions.inProgress;
    const actualHours =
      parseFloat(activity?.actualHours) + parseFloat(billableHours);
    return {
      id,
      actualStartDate,
      actualEndDate,
      status,
      actualHours,
      progress,
    };
  }, [
    activity?.actualHours,
    activity?.actualStartDate,
    activity?.id,
    billableHours,
    timecard?.startTime,
  ]);

  const openDailyReport = () =>
    openModal({
      modalName: modals.createAssessment,
      variables: {
        types: [assessmentTypes.daily_report],
        timecardId: timecardId,
        dataWorkspaceId: workspace?.id,
        type: assessmentTypes.daily_report,
        participants: [user],
      },
    });

  const { enableTimecards } = useFlags();

  return enableTimecards ? (
    id ? (
      !timecard.id ? (
        <Spinner className={styles.spinner} animation="border" />
      ) : (
        <div className={styles.container}>
          <div className={styles.left}>
            <Text noMargin size="lg" weight="bold">
              {isCreator
                ? t(keys.timecards.MY_TIMECARD)
                : t(keys.timecards.TEAM_TIMECARD, {
                    firstName: creator?.firstName,
                  })}
            </Text>
            <Text size="md" weight="semiBold" color="secondary">
              {t(keys.timecards.SUB_HEADER)}
            </Text>
            <div className={styles.sectionLine} />
            <div className={styles.dates}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div>
                  <DateTimePickerComponent
                    label={`${t(keys.timecards.START_TIME)}*`}
                    closeOnSelect={false}
                    disabled={readOnly}
                    className={
                      readOnly ? styles.datePickerDisabled : styles.datePicker
                    }
                    onChange={(newMoment) => {
                      if (!newMoment || !newMoment.isValid()) {
                        return;
                      }
                      onSubmit({
                        newValue: `${newMoment.valueOf()}`,
                        target: 'startTime',
                      });
                      onSubmit({
                        newValue: newMoment.format('Z'),
                        target: 'startTimeZone',
                      });
                    }}
                    value={moment(parseInt(timecard?.startTime))}
                    slotProps={mobileStyle}
                  />
                </div>
                <div>
                  <DateTimePickerComponent
                    label={`${t(keys.timecards.END_TIME)}*`}
                    closeOnSelect={false}
                    minDateTime={moment(parseInt(timecard?.startTime))}
                    disabled={readOnly}
                    className={
                      readOnly ? styles.datePickerDisabled : styles.datePicker
                    }
                    onChange={(newMoment) => {
                      if (!newMoment || !newMoment.isValid()) {
                        return;
                      }

                      onSubmit({
                        newValue: `${newMoment.valueOf()}`,
                        target: 'endTime',
                      });
                      onSubmit({
                        newValue: newMoment.format('Z'),
                        target: 'endTimeZone',
                      });
                    }}
                    value={moment(parseInt(timecard?.endTime))}
                    slotProps={mobileStyle}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <div className={styles.spacer} />
            <Text size="md" weight="bold" color="secondary">
              {t(keys.timecards.DESCRIPTION)}
            </Text>
            {readOnly ? (
              <Text weight="bold" noMargin>
                {timecard?.description || ''}
              </Text>
            ) : (
              <Form.Control
                className={styles.input}
                disabled={readOnly}
                value={description || ''}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                onBlur={() => {
                  const target = 'description';
                  const newValue = description;
                  onSubmit({ newValue, target });
                }}
              />
            )}

            <div className={styles.spacer} />
            <Text size="md" weight="bold" color="secondary">
              {t(keys.timecards.NON_WORKING_HOURS)}
            </Text>
            {readOnly ? (
              <Text noMargin weight="bold">
                {timecard?.nonBillableHours || 0}
              </Text>
            ) : (
              <Form.Control
                className={styles.input}
                type="number"
                min={0}
                disabled={readOnly}
                value={nonBillableHours || 0}
                onChange={(e) => {
                  setNonBillableHours(e.target.value);
                }}
                onBlur={() => {
                  const target = 'nonBillableHours';
                  const newValue = parseFloat(nonBillableHours);
                  onSubmit({ newValue, target });
                }}
              />
            )}

            <div className={styles.spacer} />
            <Text size="md" weight="bold" color="secondary">
              {t(keys.timecards.TOTAL_WORKING_HOURS)}
            </Text>
            <Text size="lg" noMargin weight="bold">
              {billableHours}
            </Text>
            <div className={styles.spacer} />

            <Text size="md" weight="bold" color="secondary">
              {t(keys.timecards.ASSET)}
            </Text>
            {readOnly ? (
              vehicle ? (
                <>
                  <VehicleCard
                    vehicle={vehicle}
                    className={styles.small}
                    onClick={() =>
                      navigate(getRoute(workspace?.id, paths.asset, vehicle.id))
                    }
                  />
                  <br />
                </>
              ) : (
                <Text weight="bold">{t(keys.common.NONE)}</Text>
              )
            ) : (
              <DropdownCombo
                type={dropdownTypes.ASSET}
                items={workspaceVehicles || []}
                preventStateChange
                onChange={(vehicle) => {
                  onSubmit({
                    newValue: vehicle?.id,
                    target: 'vehicleId',
                  });
                }}
                onRemove={() =>
                  onSubmit({ newValue: null, target: 'vehicleId' })
                }
                selected={vehicle}
              />
            )}
            <ActivitySelector
              workspace={workspace}
              update={updateTimecard}
              activity={activity}
              readOnly={readOnly}
              id={timecardId}
              creator={creator}
            />
          </div>
          <div className={styles.right}>
            <div className={styles.hideOnMobile}>
              <Text noMargin size="lg" weight="bold">
                {t(keys.common.DETAILS)}
              </Text>
            </div>
            <div className={styles.sectionLine} />
            <Text size="md" weight="bold" color="secondary">
              {t(keys.common.CREATOR)}
            </Text>
            <SimpleUserCard user={creator} />
            <div className={styles.sectionLine} />
            <Text size="md" weight="bold" color="secondary" noMargin>
              {t(keys.common.STATUS)}
            </Text>
            <div className={styles.spaceBetween}>
              <Label
                className={styles.label}
                color={timecardStatusTypes[timecard?.status]?.variant}
                name={t(timecardStatusTypes[timecard?.status]?.title)}
                rowSized={false}
              />
              {allowRevert ? (
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles.icon}
                  onClick={() => {
                    const isApproved =
                      timecard?.status === timecardStatus.approved;
                    if (isApproved) {
                      openConfirmationModal({
                        title: t(keys.timecards.REVERT_STATUS),
                        description: t(keys.timecards.REVERT_MESSAGE, {
                          variable:
                            activity?.description ||
                            activity?.externalActivityId ||
                            null,
                        }),
                        variant: 'danger',
                        buttonText: t(keys.timecards.REVERT),
                        onSubmit: () => {
                          revertTimecard();
                        },
                      });
                    } else {
                      revertTimecard();
                    }
                  }}
                >
                  undo
                </Icon>
              ) : null}
            </div>
            <div className={styles.sectionLine} />
            {!readOnly ? (
              <>
                <Text size="md" weight="bold" color="secondary">
                  {t(keys.common.DATE_CREATED)}
                </Text>
                <Text noMargin weight="semiBold">
                  {getLocalTime(parseInt(timecard?.dateCreated, 10)).format(
                    'dddd, MMMM Do YYYY, h:mm a',
                  )}
                </Text>
                <div className={styles.sectionLine} />
              </>
            ) : (
              <>
                <Text size="md" weight="bold" color="secondary">
                  {t(keys.common.DATE_SUBMITTED)}
                </Text>
                <Text noMargin weight="semiBold">
                  {getLocalTime(parseInt(timecard?.dateSubmitted, 10)).format(
                    'dddd, MMMM Do YYYY, h:mm a',
                  )}
                </Text>
                <div className={styles.sectionLine} />
              </>
            )}

            {isComplete && (
              <>
                <Text size="md" weight="bold" color="secondary">
                  {t(keys.common.DATE_REVIEWED)}
                </Text>
                <Text noMargin weight="semiBold">
                  {getLocalTime(parseInt(timecard?.dateReviewed, 10)).format(
                    'dddd, MMMM Do YYYY, h:mm a',
                  )}
                </Text>
                <div className={styles.sectionLine} />
              </>
            )}
            {isPaid && (
              <>
                <Text size="md" weight="bold" color="secondary">
                  {t(keys.common.DATE_COMPLETED)}
                </Text>
                <Text noMargin weight="semiBold">
                  {getLocalTime(parseInt(timecard?.datePaid, 10)).format(
                    'dddd, MMMM Do YYYY, h:mm a',
                  )}
                </Text>
                <div className={styles.sectionLine} />
              </>
            )}

            <div className={styles.report}>
              <div className={styles.spaceBetween}>
                <Text size="md" weight="bold" color="secondary">
                  {t(keys.common.DAILY_REPORT)}
                </Text>
                {!readOnly && (
                  <Icon
                    className={styles.addIcon}
                    onClick={() => openDailyReport()}
                  >
                    add
                  </Icon>
                )}
              </div>
              {!inspections?.length ? (
                <Text noMargin size="md" weight="semiBold">
                  {t(keys.common.NONE)}
                </Text>
              ) : (
                <div className={styles.reportContainer}>
                  {inspections?.map((inspection, idx) => (
                    <div
                      key={`${inspection.id}-inspection-${idx}`}
                      className={styles.reportCard}
                      onClick={() =>
                        navigate(
                          getRoute(
                            workspace?.id,
                            paths.assessment,
                            inspection?.id,
                          ),
                        )
                      }
                    >
                      <div className={styles.spaceBetween}>
                        <Text noSelect size="md" weight="bold" noMargin>
                          {inspection?.template?.title}
                        </Text>
                        <Label
                          name={
                            inspection.isDraft
                              ? t(keys.common.IN_PROGRESS)
                              : t(keys.common.COMPLETE)
                          }
                          color={inspection?.isDraft ? 'yellow' : 'green'}
                          rowSized={true}
                        />
                      </div>
                      <Text size="sm" noMargin>
                        {getLocalTime(
                          parseInt(inspection?.dateCreated, 10),
                        ).format('dddd, MMMM Do YYYY, h:mm a')}
                      </Text>
                    </div>
                  ))}
                </div>
              )}
              <div className={styles.sectionLine} />
            </div>

            {hasBeenReviewed && (
              <div>
                <Text size="md" weight="bold" color="secondary">
                  {t(keys.common.REVIEWER)}
                </Text>
                <SimpleUserCard user={reviewer} />
                <div className={styles.sectionLine} />
                <div className={styles.spaceBetween}>
                  <Text size="md" weight="bold" color="secondary">
                    {t(keys.common.NOTES)}
                  </Text>
                  <div className={isReviewer ? styles.icon : styles.hide}>
                    <Icon
                      className={isReviewer ? styles.icon : styles.hide}
                      baseClassName="material-icons-outlined"
                      onClick={() => {
                        setIsEditingComments(!isEditingComments);
                        if (isEditingComments) {
                          updateTimecard({
                            variables: {
                              id: timecardId,
                              notes: comments,
                            },
                          });
                        }
                      }}
                    >
                      {isEditingComments ? 'save' : 'edit'}
                    </Icon>
                  </div>
                </div>
                {isReviewer && isEditingComments ? (
                  <Form.Control
                    className={styles.input}
                    as="textarea"
                    rows={2}
                    value={comments || ''}
                    onChange={(e) => {
                      setComments(e.target.value);
                    }}
                  />
                ) : (
                  <Text noMargin weight="semiBold">
                    {timecard?.notes || t(keys.common.NONE)}
                  </Text>
                )}
                <div className={styles.sectionLine} />
              </div>
            )}
            {canMarkAsPaid && (
              <Button
                className={styles.largeButton}
                variant={paidButton.variant}
                outlined
                icon={paidButton.icon}
                value={t(paidButton.value)}
                onClick={() => {
                  openConfirmationModal({
                    title: t(paidButton.title),
                    description: t(paidButton.description),
                    variant: paidButton.variant,
                    onSubmit: () => {
                      updateTimecard({
                        variables: {
                          id: timecardId,
                          status: timecardStatus.complete,
                        },
                      }).then(() =>
                        navigate(`/${paths.timecards}/${paidButton.navigate}`),
                      );
                    },
                  });
                }}
              />
            )}
            {!readOnly && (
              <div className={styles.buttons}>
                <Button
                  disabled={disableSubmit}
                  className={styles.largeButton}
                  value={t(submitButton.value)}
                  icon={submitButton.icon}
                  onClick={() => {
                    if (isCreator) {
                      openConfirmationModal({
                        title: t(submitButton.title),
                        description: t(submitButton.description),
                        variant: submitButton.variant,
                        onSubmit: () => {
                          updateTimecard({
                            variables: {
                              id: parseInt(id),
                              status: timecardStatus.submitted,
                              billableHours: parseFloat(billableHours),
                            },
                          }).then(() => {
                            if (!inspections.length && isCreator) {
                              openConfirmationModal({
                                title: t(keys.timecards.DAILY_REPORT_TITLE),
                                description: t(
                                  keys.timecards.DAILY_REPORT_MESSAGE,
                                ),
                                onSubmit: () => openDailyReport(),
                                cancelText: t(keys.timecards.NO_REPORT),
                                buttonText: t(keys.timecards.START_REPORT),
                                variant: 'success',
                                onCancel: () => {
                                  navigate(
                                    `/${paths.timecards}/${submitButton.navigate}`,
                                  );
                                  closeModal({
                                    modalName: modals.confirmation,
                                  });
                                },
                              });
                            } else {
                              navigate(
                                `/${paths.timecards}/${submitButton.navigate}`,
                              );
                            }
                          });
                        },
                      });
                    } else {
                      if (isAdmin) {
                        updateTimecard({
                          variables: {
                            id: parseInt(id),
                            status: timecardStatus.submitted,
                            billableHours: parseFloat(billableHours),
                            reviewerId: user.id,
                            notes: `${timecard.notes || ''} ${t(
                              keys.timecards.NOTES,
                              {
                                variable: `${user.firstName} ${
                                  user.lastName
                                } ${new moment().format(
                                  'YYYY-MM-DD HH:mm:ss',
                                )}`,
                              },
                            )}`,
                          },
                        });
                      }
                    }
                  }}
                />
                <Button
                  className={styles.largeButton}
                  variant={deleteButton.variant}
                  outlined
                  icon={deleteButton.icon}
                  value={t(deleteButton.value)}
                  onClick={() => {
                    openConfirmationModal({
                      title: t(deleteButton.title, {
                        variable: t(keys.common.TIMECARD),
                      }),
                      description: t(deleteButton.description, {
                        variable: `${t(keys.action.THIS)} ${t(
                          keys.common.TIMECARD,
                        )}`,
                      }),
                      variant: deleteButton.variant,
                      onSubmit: () => {
                        deleteTimecard({ variables: { id: timecardId } }).then(
                          () =>
                            navigate(
                              `/${paths.timecards}/${deleteButton.navigate}`,
                            ),
                        );
                      },
                    });
                  }}
                />
              </div>
            )}
            <div className={styles.buttons}>
              {canApprove &&
                reviewerButtons.map((button, index) => (
                  <Button
                    className={styles.largeButton}
                    key={`button-${button.title}-${index}`}
                    value={t(button.value)}
                    variant={button.variant}
                    onClick={() => {
                      openConfirmationModal({
                        title: t(button.title, {
                          variable: t(keys.common.TIMECARD),
                        }),
                        description: t(button.description),
                        variant: button.variant,
                        textInput: button.textInput,
                        textInputTitle: t(button.textInputTitle),
                        onSubmit: (notes) => {
                          updateTimecard({
                            variables: {
                              id: timecardId,
                              status: button.status,
                              activityId: activity?.id,
                              notes: `${timecard.notes || ''} ${notes}`,
                            },
                          }).then(
                            ({
                              data: {
                                updateTimecard: { status },
                              },
                            }) => {
                              if (status === timecardStatus.approved) {
                                updateActivity({
                                  variables: { ...activityToUpdate },
                                });
                              }
                              navigate(
                                `/${paths.timecards}/${button.navigate}`,
                              );
                            },
                          );
                        },
                      });
                    }}
                  />
                ))}
            </div>

            {allowChanges && (
              <Button
                variant="primary"
                outlined
                value={t(keys.action.EDIT)}
                className={styles.largeButton}
                onClick={() => {
                  updateTimecard({
                    variables: {
                      id: timecardId,
                      status: timecardStatus.draft,
                      reviewer: null,
                    },
                  });
                }}
              />
            )}
          </div>
        </div>
      )
    ) : (
      <div className={styles.newTimecardContainer}>
        <div className={styles.headerContainer}>
          <Text noMargin size="lg" weight="bold">
            {t(keys.action.NEW_VARIABLE, { variable: t(keys.common.TIMECARD) })}
          </Text>
          <Text noMargin size="md" weight="semiBold" color="secondary">
            {t(keys.timecards.NEW_HEADER_1)}
          </Text>
        </div>
        <div className={styles.newTimecardPage}>
          <div className={styles.spacer} />
          <div className={styles.formContainer}>
            <Form
              className={styles.form}
              onSubmit={(e) => {
                e.preventDefault();
                addTimecard({
                  variables: {
                    description,
                    progress: 50,
                  },
                }).then(
                  ({
                    data: {
                      addTimecard: { id },
                    },
                  }) => {
                    navigate(`/${paths.timecard}/?id=${id}`);
                  },
                );
              }}
            >
              <img
                src="https://app.opasmobile.com/assets/graphics/timecard_sm.png"
                className={styles.graphic}
                alt="timecard"
              />
              <Text noMargin size="lg" weight="semiBold" textAlign="center">
                {t(keys.timecards.NEW_HEADER_2)}
              </Text>
              <Text
                noMargin
                size="sm"
                weight="semiBold"
                textAlign="center"
                color="secondary"
              >
                {t(keys.timecards.NEW_HEADER_3)}
              </Text>
              <Text className={styles.label} weight="semiBold">
                {t(keys.common.DESCRIPTION)}
              </Text>
              <Form.Control
                type="text"
                value={description || ''}
                placeholder={t(keys.timecards.NEW_DESCRIPTION)}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Button
                className={styles.largeButton}
                value={t(keys.action.NEXT)}
              />
            </Form>
          </div>
        </div>
      </div>
    )
  ) : (
    <FlagDisabled />
  );
}
