import { Icon } from '@mui/material';
import React from 'react';
import { actionMap } from '../../utilities/notifications';
import styles from './NotificationCard.module.scss';
import { Text } from '../typography';
import { useNavigate } from 'react-router-dom';
import { getLocalTime } from '../../utilities/time';
import { showToast } from '../../graphql/cache/modal';
import { toastVariant } from '../../constants/misc';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

export default function NotificationCard({
  notifier,
  onClick,
  showFullText,
  selectActivated,
  selected,
  onSelected,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { avatar, icon, iconColor, header, title, description, url, object } =
    actionMap[notifier.notification.action](notifier.notification);

  return (
    <div className={styles.container}>
      {selectActivated && (
        <Form.Check
          className={styles.checkbox}
          checked={selected}
          value={selected}
          onChange={(e) => {
            e.stopPropagation();
            onSelected();
          }}
          onClick={(e) => e.stopPropagation()}
        />
      )}
      <div
        className={classNames(
          styles.primaryContainer,
          notifier?.seen && styles.read,
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (object?.isArchived) {
            showToast({
              title: t(keys.notifications.DELETED_TITLE),
              message: t(keys.notifications.DELETED_DESCRIPTION),
              variant: toastVariant.warning,
            });
          } else {
            navigate(url);
          }
          onClick();
        }}
      >
        <div className={styles.leftContainer}>
          <div className={styles.header}>
            <Icon
              baseClassName="material-icons-outlined"
              className={styles[iconColor]}
              sx={{ fontSize: '1rem' }}
            >
              {icon}
            </Icon>
            <Text noMargin weight="semiBold" size="sm">
              {`${header}  -  ${getLocalTime(
                notifier.notification.dateCreated,
              ).fromNow()}`}
            </Text>
          </div>
          <div>
            <Text noMargin size="sm" weight="semiBold">
              {title}
            </Text>
            <div>
              <Text noMargin size="sm">
                {description}
              </Text>
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <Icon
            className={notifier?.seen ? styles.read : styles.unread}
            baseClassName={
              notifier?.seen ? 'material-icons-outlined' : 'material-icons'
            }
            sx={{ fontSize: '1rem' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            circle
          </Icon>
          <img
            src={
              avatar
                ? avatar
                : 'https://app.opasmobile.com/assets/graphics/default-user-avatar.webp'
            }
            className={styles.avatar}
            alt="avatar"
          />
        </div>
      </div>
    </div>
  );
}
