import { useState, useMemo, useEffect } from 'react';
import styles from './Tasks.module.scss';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import UserMultiSelector from '../../components/UserMultiSelector';
import { Text } from '../../components/typography';
import SimpleUserCard from '../../components/SimpleUserCard';
import DropdownCombo from '../../components/dropdowns/DropdownCombo';
import { dropdownTypes } from '../../components/dropdowns/dropdown';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Button from '../../components/Button';
import DateRangeSelector from '../../components/DateRangeSelector';
import TablePagination from '../../components/pagination/TablePagination';
import { keys } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { generateTaskTargetString } from '../../utilities';

const getTasksQuery = loader('./Tasks.tasks.graphql');
const getTaskMetricsQuery = loader('./TaskData.graphql');

const getLastMonthRange = () => {
  const now = new Date();
  const oneMonthAgo = new Date(now);
  oneMonthAgo.setMonth(now.getMonth() - 1);

  return {
    min: oneMonthAgo.toISOString(),
    max: now.toISOString(),
  };
};

export default function TaskData() {
  const { t } = useTranslation();
  const { inWorkspaces } = useWorkspace();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [dateFilter, setDateFilter] = useState(getLastMonthRange());
  const [page, setPage] = useState(1);
  const pageSize = 1_000;

  const {
    refetch: refetchTasks,
    data: { getTasks: tasks = [], taskCount: count = 0 } = {},
  } = useQuery(getTasksQuery, {
    skip: !selectedWorkspace?.id,
    variables: {
      options: {
        page,
        pageSize,
        filters: selectedWorkspace?.id
          ? {
              operator: 'eq',
              field: 'workspaceId',
              value: [`${selectedWorkspace?.id}`],
            }
          : null,
      },
    },
  });

  const { refetch: refetchMetrics, data: { taskMetrics = [] } = {} } = useQuery(
    getTaskMetricsQuery,
    {
      skip:
        selectedUsers.length === 0 ||
        tasks.length === 0 ||
        !dateFilter.min ||
        !dateFilter.max,
      variables: {
        taskIds: tasks.map((task) => task.id),
        userIds: selectedUsers.map((user) => user.id),
        startDate: dateFilter.min,
        endDate: dateFilter.max,
      },
    },
  );

  const metricMap = useMemo(() => {
    const map = {};
    taskMetrics.forEach(({ taskId, userId, total }) => {
      map[`${taskId}_${userId}`] = total;
    });
    return map;
  }, [taskMetrics]);

  const calculateExpectedTarget = (task) => {
    if (!task.target || !task.targetInterval || !task.targetPeriod) {
      return null;
    }

    const { min: startDate, max: endDate } = dateFilter;
    if (!startDate || !endDate) return null;

    const start = new Date(startDate);
    const end = new Date(endDate);

    const msInDay = 24 * 60 * 60 * 1000;
    const msInWeek = msInDay * 7;
    const msInMonth = msInDay * 30.44;
    const msInYear = msInDay * 365.25;

    let intervalMs;
    switch (task.targetPeriod) {
      case 'DAY':
        intervalMs = msInDay * task.targetInterval;
        break;
      case 'WEEK':
        intervalMs = msInWeek * task.targetInterval;
        break;
      case 'MONTH':
        intervalMs = msInMonth * task.targetInterval;
        break;
      case 'YEAR':
        intervalMs = msInYear * task.targetInterval;
        break;
      default:
        intervalMs = msInDay * task.targetInterval;
    }

    const totalIntervals = Math.floor((end - start) / intervalMs) + 1;
    return totalIntervals * task.target;
  };

  const handleExportExcel = () => {
    const workbook = XLSX.utils.book_new();

    const mainSheetData = [];
    const mainHeaders = ['Task Title'];
    selectedUsers.forEach((user) => {
      mainHeaders.push(`${user.firstName} ${user.lastName}`);
    });
    mainSheetData.push(mainHeaders);

    tasks.forEach((task) => {
      const row = [task.title];
      selectedUsers.forEach((user) => {
        const total = metricMap[`${task.id}_${user.id}`] || 0;
        const expectedTarget = calculateExpectedTarget(task);
        const displayValue = expectedTarget
          ? `${total}/${expectedTarget}`
          : total;
        row.push(displayValue);
      });
      mainSheetData.push(row);
    });

    const mainWorksheet = XLSX.utils.aoa_to_sheet(mainSheetData);
    XLSX.utils.book_append_sheet(workbook, mainWorksheet, 'Task Metrics');

    const tasksSheetData = [['Task ID', 'Task Title', 'Target']];
    tasks.forEach((task) => {
      tasksSheetData.push([
        task.id,
        task.title,
        generateTaskTargetString(task),
      ]);
    });
    const tasksWorksheet = XLSX.utils.aoa_to_sheet(tasksSheetData);
    XLSX.utils.book_append_sheet(workbook, tasksWorksheet, 'Tasks Overview');

    const detailedMetricsData = [['User', 'Task', 'Total', 'Expected Target']];
    tasks.forEach((task) => {
      selectedUsers.forEach((user) => {
        const total = metricMap[`${task.id}_${user.id}`] || 0;
        const expectedTarget = calculateExpectedTarget(task);
        detailedMetricsData.push([
          `${user.firstName} ${user.lastName}`,
          task.title,
          total,
          expectedTarget,
        ]);
      });
    });
    const detailedMetricsWorksheet =
      XLSX.utils.aoa_to_sheet(detailedMetricsData);
    XLSX.utils.book_append_sheet(
      workbook,
      detailedMetricsWorksheet,
      'Detailed Metrics',
    );
    const { min: startDate, max: endDate } = dateFilter;

    const sanitizedWorkspace = selectedWorkspace?.title
      ?.replace(/[^a-zA-Z0-9]/g, '')
      .substring(0, 25)
      .toLowerCase();

    XLSX.writeFile(
      workbook,
      `${sanitizedWorkspace}_tasks_${moment(startDate).format('L')}_${moment(
        endDate,
      ).format('L')}.xlsx`,
    );
  };

  const findCellValue = (taskId, userId) => {
    const total = metricMap[`${taskId}_${userId}`];
    if (total === undefined) {
      return '-';
    }

    const task = tasks.find((t) => t.id === taskId);
    const expectedTarget = calculateExpectedTarget(task);
    if (expectedTarget) {
      return `${total} / ${expectedTarget}`;
    }
    return total;
  };

  const determineColor = (total, expectedTarget) => {
    if (expectedTarget) {
      const percentage = (total / expectedTarget) * 100;
      if (percentage < 35) {
        return 'red';
      } else if (percentage < 70) {
        return 'yellow';
      } else {
        return 'green';
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (selectedWorkspace?.id) {
      refetchTasks();
    }
  }, [selectedWorkspace, page, refetchTasks]);

  useEffect(() => {
    if (
      selectedUsers.length > 0 &&
      tasks.length > 0 &&
      dateFilter.min &&
      dateFilter.max
    ) {
      refetchMetrics();
    }
  }, [selectedUsers, tasks, dateFilter, refetchMetrics]);

  const handleDateRangeChange = ({ min, max }) => {
    if (new Date(min) > new Date(max)) {
      const newMax = new Date(min);
      newMax.setDate(newMax.getDate() + 1);
      setDateFilter({ min, max: newMax.toISOString() });
    } else {
      setDateFilter({ min, max });
    }
  };

  return (
    <div className={styles.dataContainer}>
      <Text weight="semiBold">{t(keys.common.WORKSPACE)}</Text>
      <div className={styles.workspaceSelectorContainer}>
        <div className={styles.workspaceSelector}>
          <DropdownCombo
            type={dropdownTypes.WORKSPACE}
            preventStateChange
            highlight={true}
            items={inWorkspaces || []}
            selected={selectedWorkspace}
            onChange={(workspace) => {
              setSelectedWorkspace(workspace);
              setSelectedUsers([]);
              setDateFilter(getLastMonthRange());
              setPage(1);
            }}
            required={true}
          />
        </div>
        <Button
          onClick={handleExportExcel}
          variant="primary"
          value={'Export'}
          icon="download"
          disabled={!selectedWorkspace?.id || !selectedUsers?.length}
        />
      </div>
      <div className={styles.spacer} />
      <Text weight="semiBold">{t(keys.common.USERS)}</Text>
      <div className={styles.userContainer}>
        <UserMultiSelector
          type={'byWorkspace'}
          workspaceId={selectedWorkspace?.id}
          selected={selectedUsers}
          onUserAdded={(user) => {
            setSelectedUsers([...selectedUsers, user]);
          }}
          onUserRemoved={(user) => {
            setSelectedUsers(selectedUsers.filter(({ id }) => id !== user.id));
          }}
        />
      </div>
      <div className={styles.buttonContainer}>
        <DateRangeSelector
          onChange={handleDateRangeChange}
          values={dateFilter}
        />
        <div className={styles.sortContainer}>
          <Button
            onClick={() => {
              setPage(1);
              setSelectedUsers([]);
              setSelectedWorkspace(null);
              setDateFilter(getLastMonthRange());
            }}
            outlined
            variant="secondary"
            value={t(keys.tasks.CLEAR_SELECTION)}
          />
        </div>
      </div>
      <div className={styles.spacer} />
      {tasks.length > 0 && selectedUsers.length > 0 ? (
        <div className={styles.table}>
          <TableContainer
            component={Paper}
            sx={{ maxWidth: '100%', overflowX: 'auto' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 200 }}>
                    <Text weight="semiBold">{t(keys.tasks.TASK_TITLE)}</Text>
                  </TableCell>
                  {tasks.map((task) => (
                    <TableCell component="th" scope="row">
                      <Text noMargin weight="semiBold" truncate>
                        {task.title}
                      </Text>
                      <Text
                        noMargin
                        size="sm"
                        weight="semiBold"
                        color="secondary"
                      >
                        {generateTaskTargetString(task)}
                      </Text>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedUsers.map((user, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'grey.100' : 'white',
                    }}
                  >
                    <TableCell key={index} align="center" sx={{ width: 200 }}>
                      <SimpleUserCard user={user} />
                    </TableCell>
                    {tasks.map((task, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{
                          width: 200,
                        }}
                      >
                        <Text
                          noMargin
                          color={
                            metricMap[`${task.id}_${user.id}`] !== undefined
                              ? determineColor(
                                  metricMap[`${task.id}_${user.id}`] || 0,
                                  calculateExpectedTarget(task),
                                )
                              : 'secondary'
                          }
                          weight="semiBold"
                        >
                          {findCellValue(task.id, user.id)}
                        </Text>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className={styles.emptyStateContainer}>
          <EmptyStateView
            title={t(keys.tasks.NO_DATA_TITLE)}
            text={t(keys.tasks.NO_DATA_TEXT)}
            image={'https://app.opasmobile.com/assets/graphics/no_data_md.png'}
            fullOpacity
          />
        </div>
      )}
      <TablePagination
        pageSize={pageSize}
        count={count}
        setPage={setPage}
        page={page}
      />
    </div>
  );
}
