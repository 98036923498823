import React, { useMemo } from 'react';
import styles from './TabSystem.module.scss';
import classNames from 'classnames';
import { Text } from './typography';
import { searchParamKeys } from '../constants/strings';
import { useQueryParams } from '../hooks/misc';
import { useLocation } from 'react-router-dom';
import { tableSortingParams } from './tables/table';

export default function TabSystem({
  options,
  type,
  parent,
  justifyContent = 'flexStart',
}) {
  const { upsertParam, deleteParam } = useQueryParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const SubPage = useMemo(() => {
    const option = options.find((o) => o.key === type);
    return option.element;
  }, [type, options]);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.tabsContainer, styles[justifyContent])}>
        {options.map((option) => (
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              for (const key of Array.from(searchParams.keys())) {
                if (!tableSortingParams.includes(key)) {
                  deleteParam(key);
                }
              }
              upsertParam(searchParamKeys.tab, option.key);
            }}
            className={styles.optionContainer}
            key={option.title}
          >
            <Text
              textAlign="center"
              noMargin
              color={type === option.key ? 'primary' : 'secondary'}
            >
              {option.title}
            </Text>
            <div
              className={classNames(
                styles.selectedFooter,
                type === option.key ? styles.show : styles.hide,
              )}
            />
          </div>
        ))}
      </div>
      <div className={styles.subPage}>
        <SubPage parent={parent} />
      </div>
    </div>
  );
}
