import React from 'react';
import styles from './EmptyViewer.module.scss';
import { Text } from '../../typography';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';

export default function EmptyViewer({ file, onDetailsClick, onClose }) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        alt="Not Supported"
        src="https://opas-public.s3.us-east-2.amazonaws.com/webapp-assets/empty_viewer.png"
      />
      <br />
      <Text size="lg" weight="bold" textAlign="center">
        {t(keys.files.EMPTY_VIEWER_TITLE)}
      </Text>
      <Text size="md" weight="semiBold" color="secondary" textAlign="center">
        {file.downloadAllowed
          ? t(keys.files.FILE_TYPE_MESSAGE)
          : t(keys.files.DOWNLOAD_DISABLED_MESSAGE)}
      </Text>
      {!file.downloadAllowed ? (
        <>
          <br />
          <Button
            className={styles.button}
            icon="info"
            value={t(keys.common.DETAILS)}
            variant="secondary"
            outlined
            onClick={() => {
              onDetailsClick();
              onClose();
            }}
          />
        </>
      ) : (
        <>
          <br />
          <Button
            className={styles.button}
            value={t(keys.action.DOWNLOAD)}
            icon="download"
            onClick={() => {
              onClose();
              const downloadFile = (url, name) => {
                fetch(url)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const objectUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    const awsExtension = new URL(file.url).pathname.split(
                      '.',
                    )[1];
                    if (name.split('.').length > 1) {
                      link.download = name;
                    } else {
                      link.download = name + '.' + awsExtension;
                    }
                    link.href = objectUrl;
                    link.click();
                    URL.revokeObjectURL(objectUrl);
                  })
                  .catch((error) => console.error(error));
              };
              downloadFile(file.url, file.name);
            }}
          />
        </>
      )}
    </div>
  );
}
