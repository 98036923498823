import React from 'react';
import styles from './SafetyShareCard.module.scss';
import { Text } from '../../components/typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { Icon } from '@mui/material';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { useCurrentUser } from '../../providers/UserProvider';
import { openToolboxTalkModal } from '../../graphql/cache/modal';
import { useOnlineStatus } from '../../hooks/offline-hooks/offline-misc';
import { Card, CardContent } from '@mui/material';
import { loader } from 'graphql.macro';
import format from 'date-fns/format';
import { useQuery } from '@apollo/client';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import useSafetyShare from './SafetyShare';
import { multiLineTruncate } from '../../utilities';

const todaysTBTQuery = loader('./SafetyShareCard.fetch.graphql');

export default function SafetyShareCard({ className }) {
  const today = format(new Date(), 'yyyy-MM-dd');
  const { workspaceId } = useWorkspace();

  const { data: { tbtSchedule = [] } = {} } = useQuery(todaysTBTQuery, {
    variables: {
      options: {
        filters: [
          {
            field: 'scheduleDate',
            operator: 'eq',
            value: today,
          },
          {
            field: 'workspaceId',
            operator: 'eq',
            value: `${workspaceId}`,
          },
        ],
      },
    },
  });

  const { tbt } = tbtSchedule[0] ?? {};

  const {
    readTime,
    content,
    content: { title, description } = {},
  } = useSafetyShare(tbt) || {};

  const { t } = useTranslation();
  const { openModal } = useModal();
  const { user } = useCurrentUser();
  const isOnline = useOnlineStatus();

  return (
    <div
      className={styles[className]}
      onClick={() => openToolboxTalkModal({ info: content })}
    >
      <Card
        className={styles.card}
        sx={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '12px',
          backgroundImage:
            'url(https://app.opasmobile.com/assets/graphics/safety_share_background.jpg)',
        }}
      >
        <CardContent>
          <div className={styles.cardContent}>
            <div className={styles.header}>
              <Text color="white" weight="bold" size="lg" noSelect>
                {title
                  ? multiLineTruncate(title, 70)
                  : t(keys.dashboard.SAFETY_SHARE)}
              </Text>
              {isOnline && (
                <Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    openModal({
                      modalName: modals.recordMeeting,
                      variables: {
                        onView: () => openToolboxTalkModal({ info: content }),
                        participants: [user],
                        safetyTopic: `${title}: ${
                          description || t(keys.toolbox.DEFAULT_DESCRIPTION)
                        }`,
                      },
                    });
                  }}
                  sx={{ color: 'white' }}
                >
                  mic
                </Icon>
              )}
            </div>
            <Text color="white" weight="semiBold" noSelect>
              {multiLineTruncate(description, 200) ||
                t(keys.toolbox.DEFAULT_DESCRIPTION)}
            </Text>
            <Text
              textAlign="right"
              size="sm"
              noSelect
              weight="semiBold"
              color="white"
              noMargin
            >
              {`${readTime} ${t(keys.dashboard.MIN_READ)}`}
            </Text>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
