import { useContext, createContext, useMemo, useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import { useCurrentUser } from './UserProvider';
import Button from '../components/Button';
import styles from './WatcherProvider.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';

const watchedItemsQuery = loader('./WatcherProvider.fetch.graphql');
const addWatcherMutation = loader('./WatcherProvider.add.graphql');
const removeWatcherMutation = loader('./WatcherProvider.remove.graphql');

const WatcherProviderContext = createContext();

export const WatcherProvider = ({ children }) => {
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const [addWatcher] = useMutation(addWatcherMutation, {
    refetchQueries: ['GetWatchedItems'],
  });
  const [removeWatcher] = useMutation(removeWatcherMutation, {
    refetchQueries: ['GetWatchedItems'],
  });
  const [prevId, setPrevId] = useState(null);

  const { refetch, data: { watchedItems = [] } = {} } = useQuery(
    watchedItemsQuery,
    {
      skip: !user?.id,
    },
  );

  const FollowButton = ({
    type,
    id,
    userId,
    align = 'right',
    size = 'sm',
    disabled = false,
  }) => {
    const isWatching = useMemo(
      () =>
        watchedItems?.some(
          ({ watchableType, watchableId }) =>
            watchableType === type && watchableId === id,
        ),
      [id, type],
    );

    useEffect(() => {
      if (id !== prevId) {
        refetch();
        setPrevId(id);
      }
    }, [id]);

    return (
      <Button
        disabled={disabled}
        align={align}
        className={styles.followButton}
        size={size}
        icon={isWatching ? 'notifications_off' : 'notifications'}
        value={isWatching ? t(keys.common.UNFOLLOW) : t(keys.common.FOLLOW)}
        onClick={() =>
          isWatching
            ? removeWatcher({
                variables: {
                  watchableId: id,
                  userId,
                  watchableType: type,
                },
              })
            : addWatcher({
                variables: {
                  watchableId: id,
                  userId,
                  watchableType: type,
                },
              })
        }
        variant={isWatching ? 'danger' : 'success'}
      />
    );
  };

  return (
    <WatcherProviderContext.Provider value={{ FollowButton }}>
      {children}
    </WatcherProviderContext.Provider>
  );
};

export const useWatcher = () => useContext(WatcherProviderContext);
