import { getLocalTime } from '../time';
export const formatActionItem = ({
  creator,
  title,
  description,
  status,
  dateCreated,
  dueDate,
  assignee,
  workspace,
  __typename,
  id,
  priority,
}) => ({
  id: `${id}`,
  creator: `${creator?.firstName} ${creator?.lastName}`,
  assignee: assignee ? `${assignee?.firstName} ${assignee?.lastName}` : '',
  title: title,
  description: description,
  status: status,
  priority,
  dateCreated: getLocalTime(dateCreated).format('YYYY-MM-DD hh:mm'),
  dueDate: dueDate ? getLocalTime(dueDate).format('YYYY-MM-DD hh:mm') : '',
  workspace: workspace?.title,
  __typename,
});
export const formatAssessment = ({
  creator,
  template,
  dateCreated,
  dateModified,
  notes,
  __typename,
  vehicle,
  id,
  isDraft,
  passed,
  participant,
  workspace,
  allWorkspaces,
}) => ({
  id: `${id}`,
  title: template?.title,
  status: isDraft ? 'IN_PROGRESS' : 'COMPLETE',
  type: template?.templateType === 'VEHICLE' ? 'FLEET' : template.templateType,
  passed: passed ? 'TRUE' : 'FALSE',
  vehicle: `${vehicle?.unitNumber || ''}`,
  creator: `${creator?.firstName} ${creator?.lastName}`,
  creatorId: `${creator.id}`,
  creatorEmail: creator.email,
  workspace:
    workspace?.title ??
    allWorkspaces?.find((w) => w.id === creator.lastWorkspaceId)?.title,
  dateCreated: getLocalTime(dateCreated).format('YYYY-MM-DD hh:mm'),
  dateModified: getLocalTime(dateModified).format('YYYY-MM-DD hh:mm'),
  notes,
  participant,
  allWorkspaces: !!workspace ? 'FALSE' : 'TRUE',
  __typename,
});

export const formatIncident = ({
  creator,
  dateCreated,
  status,
  dateOfIncident,
  timeOfDay,
  location,
  notes,
  __typename,
  vehicle,
  id,
  closedAt,
  title,
  description,
  type,
  subtype,
  lostTimeHrs,
  lostTime,
  nearMiss,
  potentialFatality,
  externalEmployee,
  employee,
  closer,
  workspace,
}) => ({
  id: `${id}`,
  status,
  dateOfIncident,
  location,
  timeOfDay,
  type,
  subtype,
  title,
  description,
  potentialFatality,
  nearMiss,
  lostTime,
  lostTimeHrs,
  employee: !!employee ? `${employee?.firstName} ${employee?.lastName}` : null,
  externalEmployee,
  vehicle: `${vehicle?.unitNumber || ''}`,
  workspace: workspace?.title,
  creator: `${creator?.firstName} ${creator?.lastName}`,
  dateCreated: getLocalTime(dateCreated).format('YYYY-MM-DD hh:mm'),
  closer: !!closer ? `${closer?.firstName} ${closer?.lastName}` : null,
  closedAt: !!closer ? getLocalTime(closedAt).format('YYYY-MM-DD hh:mm') : null,
  notes,
  __typename,
});

export const formatObservation = ({
  creator,
  dateCreated,
  dateTime,
  negative,
  location,
  status,
  notes,
  __typename,
  id,
  dateClosed,
  description,
  reviewer,
  dateReviewed,
  closer,
  workspace,
}) => ({
  id: `${id}`,
  negative: !!negative ? 'Action Required' : 'Positive',
  status,
  dateTime: getLocalTime(dateTime).format('YYYY-MM-DD'),
  location,
  description,
  workspace: workspace?.title,
  creator: !!creator
    ? `${creator?.firstName} ${creator?.lastName}`
    : 'Anonymous',
  dateCreated: getLocalTime(dateCreated).format('YYYY-MM-DD hh:mm'),
  reviewer: !!reviewer ? `${reviewer?.firstName} ${reviewer?.lastName}` : null,
  dateReviewed: !!reviewer
    ? getLocalTime(dateReviewed).format('YYYY-MM-DD hh:mm')
    : null,
  closer: !!closer ? `${closer?.firstName} ${closer?.lastName}` : null,
  dateClosed: !!closer
    ? getLocalTime(dateClosed).format('YYYY-MM-DD hh:mm')
    : null,
  notes,
  __typename,
});

export const formatUser = ({
  id,
  firstName,
  lastName,
  email,
  lastActivity,
  address,
  role,
  city,
  phone,
  postalCode,
  emergencyContact,
  externalActivityId,
  dateOfBirth,
  payrollId,
  workRole,
}) => ({
  id: `${id}`,
  name: `${firstName} ${lastName}`,
  email: email || 'N/A',
  lastActivity: lastActivity
    ? getLocalTime(lastActivity).format('YYYY-MM-DD hh:mm')
    : 'Never',
  address: address || 'N/A',
  role: role || 'Unassigned',
  city: city || 'N/A',
  phone: phone || 'N/A',
  postalCode: postalCode || 'N/A',
  emergencyContact: emergencyContact || 'N/A',
  externalActivityId: externalActivityId || 'N/A',
  dateOfBirth: dateOfBirth
    ? getLocalTime(dateOfBirth).format('YYYY-MM-DD')
    : 'N/A',
  payrollId: payrollId || 'N/A',
  workRole: workRole?.title || 'Unassigned',
});

export const excelHeaderLookup = {
  __typename: ' ',
  dateCreated: 'Date Created',
  description: 'Description',
  workspace: 'Workspace',
  reviewer: 'Reviewer',
  creator: 'Creator',
  id: 'ID',
  status: 'Status',
  notes: 'Notes',
  priority: 'Priority',
  assignee: 'Assignee',
  type: 'Type',
  title: 'Title',
  dueDate: 'Due Date',
  labels: 'Labels',
  inspection: 'Inspection',
  vehicle: 'Asset',
  dateModified: 'Date Published',
  template: 'Title',
  passed: 'Passed',
  isDraft: 'Status',
  employee: 'Employee',
  externalEmployee: 'External Employee',
  lostTime: 'Lost Time',
  lostTimeHrs: 'Lost Time Hrs',
  nearMiss: 'Near Miss',
  subtype: 'Subtype',
  closedAt: 'Closed on',
  potentialFatality: 'Potential Fatality',
  timeOfDay: 'Time of Incident',
  dateOfIncident: 'Date of Incident',
  closer: 'Closer',
  dateTime: 'Date and Time',
  location: 'Location',
  dateReviewed: 'Date Reviewed',
  dateClosed: 'Date Closed',
  negative: 'Sentiment',
  creatorId: 'User ID',
  creatorEmail: 'User Email',
  participant: 'Participant',
  allWorkspaces: 'All Workspaces',
};
