import { StyleSheet } from '@react-pdf/renderer';

const fontSize = 8;
const red = '#e9222c';
const textPrimary = '#1d1d1f';
const blue = '#0d6efd';

export const commentStyles = StyleSheet.create({
  comment: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  commentContainer: { marginTop: 3, marginBottom: 3 },
  semiBold: {
    width: 120,
    fontSize,
    fontFamily: 'eina03semibold',
  },
  text: {
    fontSize,
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    color: textPrimary,
    fontFamily: 'eina03regular',
  },
  red: {
    fontSize: fontSize + 1,
    color: red,
    marginBottom: 2,
  },
  commentLink: {
    color: blue,
    fontFamily: 'eina03semibold',
    fontSize,
    wordBreak: 'break-all',
    flexWrap: 'wrap',
  },
});
