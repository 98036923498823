import styles from './UsersTable.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useMemo, useState } from 'react';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { paths } from '../../constants/strings';
import { useNavigate } from 'react-router-dom';
import MTTable from '../../components/tables/MTable';
import { useURLParams } from '../../providers/URLParamProvider';
import { useTableComponents } from '../../components/tables/MTableComponents';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { userRoles } from '../../constants/strings';
import { useCurrentUser } from '../../providers/UserProvider';
import { getLocalTime } from '../../utilities/time';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Icon } from '@mui/material';
import { Form } from 'react-bootstrap';
import { useModal } from '../../providers/ModalProvider';
import { defaultUserAvatar } from '../../utilities';

const usersQuery = loader('./UsersTable.graphql');
const editUserMutation = loader(
  '../../pages/settings_pages/EditUsers.update.graphql',
);
const deleteActivityParticipantMutation = loader(
  '../../pages/settings_pages/EditUsers.deleteActivity.graphql',
);
const addParticipantsToActivityMutation = loader(
  '../../pages/settings_pages/EditUsers.addUserActivities.graphql',
);

const deleteUserMutation = loader('./UsersTable.delete.graphql');

export default function UsersTable() {
  const { availableWorkspaces } = useWorkspace();
  const { page, pageSize, filters, sort, subfilters } = useURLParams();
  const { currentWorkspaceCell, dropdownCell, textCell } = useTableComponents();
  const [deleteUser] = useMutation(deleteUserMutation);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user: currentUser } = useCurrentUser();
  const [updateUser] = useMutation(editUserMutation);
  const [deleteActivityParticipant] = useMutation(
    deleteActivityParticipantMutation,
  );
  const [addActivityParticipant] = useMutation(
    addParticipantsToActivityMutation,
  );
  const { openConfirmationModal } = useModal();
  const { enableActivities, enableTimecards } = useFlags();
  const [editResourceId, setEditResourceId] = useState({});
  const [editPayrollId, setEditPayrollId] = useState({});

  const workspaces = availableWorkspaces?.map((w) => ({
    label: `${w.title}`,
    value: `${w.id}`,
  }));

  const {
    refetch,
    networkStatus,
    previousData: { usersCount: oldCount } = {},
    data: { users = [], usersCount = 0 } = {},
  } = useQuery(usersQuery, {
    skip: !page || !pageSize || !sort,
    variables: {
      options: {
        page,
        pageSize,
        filters,
        subfilters,
        sort,
      },
    },
  });

  const onAccessChange = (userId, newRole) => {
    updateUser({
      variables: {
        id: userId,
        role: newRole,
      },
    });
  };

  const updateResourceId = (userId, newResourceId) => {
    updateUser({
      variables: {
        id: userId,
        externalActivityId: newResourceId,
      },
    }).then(() => {
      deleteActivityParticipant({
        variables: { userId: userId },
      }).then(() => {
        addActivityParticipant({
          variables: { userId: userId, resourceId: newResourceId },
        }).then(({ data: { addActivityParticipant } }) => {
          openConfirmationModal({
            title: 'New User Activities',
            description: addActivityParticipant
              ? `Success! This user has been linked to all new Activities related to ${newResourceId}`
              : `No Activities found related to ${newResourceId}. You may need to update your P6 import, or check the resource ID.`,
            buttonText: 'OK',
            variant: addActivityParticipant ? 'success' : 'warning',
            onSubmit: () => {},
          });
        });
      });
    });
  };

  const tableColumns = useMemo(() => {
    const columns = [
      {
        accessorKey: 'avatarUrl',
        header: '',
        size: 75,
        Cell: ({ cell }) => (
          <img
            className={styles.avatar}
            src={cell.getValue() || defaultUserAvatar}
            alt="avatar"
          />
        ),
      },
      {
        accessorFn: (row) => row?.firstName,
        accessorKey: 'firstName',
        header: t(keys.common.FIRST_NAME),
        minSize: 150,
        grow: true,
        enableSorting: false,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorFn: (row) => row?.lastName,
        accessorKey: 'lastName',
        header: t(keys.common.LAST_NAME),
        minSize: 150,
        grow: true,
        enableSorting: false,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorFn: (row) => row?.email,
        accessorKey: 'email',
        header: t(keys.settings.EMAIL),
        minSize: 150,
        grow: true,
        enableSorting: false,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorFn: (row) => row?.workRole?.title,
        accessorKey: 'workRole.title',
        header: t(keys.common.ROLE),
        minSize: 150,
        grow: true,
        enableSorting: false,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorFn: (row) => row?.workspaces,
        accessorKey: 'workspaces',
        header: t(keys.common.WORKSPACE),
        Cell: ({ cell }) => currentWorkspaceCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: workspaces,
        enableSorting: false,
      },
      {
        accessorFn: (row) => row?.lastActivity,
        accessorKey: 'lastActivity',
        enableColumnFilter: false,
        header: t(keys.common.ACTIVE),
        enableSorting: false,
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.getValue()
                ? getLocalTime(cell.getValue()).fromNow()
                : t(keys.common.NA)}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row?.role,
        accessorKey: 'role',
        header: t(keys.common.ACCESS),
        enableSorting: false,
        Cell: ({ cell, row }) => {
          const selected = cell.getValue();
          return dropdownCell({
            valueStrings: ['Admin', 'Standard'],
            values: ['ADMIN', 'USER'],
            userRoles,
            selected,
            rowId: row.original.id,
            onChange: onAccessChange,
            disabled: currentUser.id === row.original.id,
          });
        },
        enableColumnFilter: false,
      },
    ];

    // Conditionally add columns based on flags
    if (!!enableActivities) {
      columns.push({
        accessorFn: (row) => row?.externalActivityId,
        accessorKey: 'externalActivityId',
        header: t(keys.common.RESOURCE_ID),
        Cell: ({ cell, row }) => {
          const userId = row.original.id;
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {!editResourceId[userId]?.editableResource ? (
                <div
                  className={styles.resourceIdPayrollIdcontainer}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Icon
                    className={styles.editIcon}
                    sx={{ fontSize: 'small' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setEditResourceId((prevEditResourceId) => ({
                        ...prevEditResourceId,
                        [row.original.id]: {
                          editableResource: true,
                          userResourceIdinput:
                            row.original.externalActivityId ?? '',
                        },
                      }));
                    }}
                  >
                    edit
                  </Icon>
                  <div>{cell.getValue()}</div>
                </div>
              ) : (
                <div className={styles.p6Container}>
                  <Icon
                    sx={{ fontSize: 'small' }}
                    baseClassName="material-icons-outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setEditResourceId((prevEditResourceId) => ({
                        ...prevEditResourceId,
                        [row.original.id]: {
                          editableResource: false,
                          userResourceIdinput:
                            row.original.externalActivityId ?? '',
                        },
                      }));
                    }}
                  >
                    cancel
                  </Icon>
                  <Icon
                    className={styles.editIcon}
                    sx={{ fontSize: 'small' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      openConfirmationModal({
                        title: 'Edit User P6 Identifier',
                        description:
                          'Are you sure you want to change this users P6 Identifier? This will change all associated activities to this user. This action cannot be undone',
                        variant: 'danger',
                        buttonText: 'Confirm',
                        onSubmit: () => {
                          updateResourceId(
                            userId,
                            editResourceId[userId]?.userResourceIdinput,
                          );
                          setEditResourceId((prevEditResourceId) => ({
                            ...prevEditResourceId,
                            [row.original.id]: {
                              editableResource: false,
                            },
                          }));
                        },
                      });
                    }}
                  >
                    save
                  </Icon>
                  <Form.Control
                    value={editResourceId[userId]?.userResourceIdinput}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setEditResourceId((prevEditResourceId) => ({
                        ...prevEditResourceId,
                        [row.original.id]: {
                          editableResource: true,
                          userResourceIdinput: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          );
        },
        minSize: 150,
        grow: true,
      });
    }

    if (!!enableTimecards) {
      columns.push({
        accessorFn: (row) => row?.payrollId,
        accessorKey: 'payrollId',
        Filter: ({ column }) => (
          <input
            className={styles.noBorders}
            type="number"
            value={column.getFilterValue() || ''}
            onChange={(e) =>
              column.setFilterValue(
                e.target.value ? Number(e.target.value) : undefined,
              )
            }
            placeholder="Filter by PayrollId"
          />
        ),
        header: t(keys.settings.PAYROLL_ID),
        Cell: ({ cell, row }) => {
          const userId = row.original.id;
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {!editPayrollId[userId]?.editablePayroll ? (
                <div className={styles.resourceIdPayrollIdcontainer}>
                  <Icon
                    className={styles.editIcon}
                    sx={{ fontSize: 'small' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setEditPayrollId((prevEditPayrollId) => ({
                        ...prevEditPayrollId,
                        [row.original.id]: {
                          editablePayroll: true,
                          userPayrollIdinput: row.original.payrollId ?? '',
                        },
                      }));
                    }}
                  >
                    edit
                  </Icon>
                  <div>{cell.getValue()}</div>
                </div>
              ) : (
                <div className={styles.p6Container}>
                  <Icon
                    sx={{ fontSize: 'small' }}
                    baseClassName="material-icons-outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setEditPayrollId((prevEditPayrollId) => ({
                        ...prevEditPayrollId,
                        [row.original.id]: {
                          editablePayroll: false,
                          userPayrollIdinput: row.original.PayrollId ?? '',
                        },
                      }));
                    }}
                  >
                    cancel
                  </Icon>
                  <Icon
                    className={styles.editIcon}
                    sx={{ fontSize: 'small' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      openConfirmationModal({
                        title: 'Edit User Payroll ID',
                        description: `Are you sure you want to change this users Payroll ID?`,
                        variant: 'danger',
                        buttonText: 'Confirm',
                        onSubmit: () => {
                          updateUser({
                            variables: {
                              id: userId,
                              payrollId: parseInt(
                                editPayrollId[userId]?.userPayrollIdinput,
                              ),
                            },
                          });
                          setEditPayrollId((prevEditPayrollId) => ({
                            ...prevEditPayrollId,
                            [row.original.id]: {
                              editablePayroll: false,
                            },
                          }));
                        },
                      });
                    }}
                  >
                    save
                  </Icon>
                  <Form.Control
                    value={editPayrollId[userId]?.userPayrollIdinput}
                    type="number"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setEditPayrollId((prevEditPayrollId) => ({
                        ...prevEditPayrollId,
                        [row.original.id]: {
                          editablePayroll: true,
                          userPayrollIdinput: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          );
        },
        minSize: 150,
        grow: true,
      });
    }
    columns.push({
      accessorKey: 'id',
      header: '',
      size: 50,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        const user = users.find((u) => u.id === cell.getValue()) || undefined;
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Icon
              baseClassName="material-icons-outlined"
              className={styles.deleteIcon}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openConfirmationModal({
                  title: t(keys.action.DELETE_VARIABLE, {
                    variable: t(keys.common.USER),
                  }),
                  description: t(keys.action.DELETE_CONFIRMATION, {
                    variable: `${user.firstName} ${user.lastName} - ${user.email}`,
                  }),

                  buttonText: t(keys.action.DELETE),
                  variant: 'danger',
                  onSubmit: () => {
                    deleteUser({
                      variables: { id: user.id },
                    }).then(() => refetch());
                  },
                });
              }}
            >
              delete
            </Icon>
          </div>
        );
      },
    });

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    users,
    enableActivities,
    enableTimecards,
    currentUser.id,
    editResourceId,
    editPayrollId,
  ]);

  return (
    <div className={styles.table}>
      <MTTable
        isLoading={networkStatus !== NetworkStatus.ready}
        data={users || []}
        columns={tableColumns || []}
        onRowClick={(user) => {
          navigate(`/${paths.user}/${user?.id}`);
        }}
        rowCount={usersCount || oldCount}
        className={styles.noMargin}
      />
    </div>
  );
}
