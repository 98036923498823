import { useMemo } from 'react';
import styles from './TaskModal.module.scss';
import { Text } from '../../typography';
import Modal from '../Modal';
import { useModal } from '../../../providers/ModalProvider';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DropdownCombo from '../../dropdowns/DropdownCombo';
import { dropdownTypes } from '../../dropdowns/dropdown';
import { modals } from '../../../providers/modals';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import TypeSelectorDropdown from '../../dropdowns/TypeSelectorDropdown';
import { showToast } from '../../../graphql/cache/modal';
import { toastVariant } from '../../../constants/misc';
import UserMultiSelector from '../../UserMultiSelector';
import {
  Icon,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { keys } from '../../../utilities/translator/translation_keys';
import taskImage from '../../../assets/clipboard_task.png';
import defaultLogo from '../../../assets/workspace_image.png';
import {
  assessmentTypes,
  taskIntervalStringLookup,
} from '../../../constants/strings';
import { useTemplates } from '../../../providers/TemplateProvider';
import { useQueryParams } from '../../../hooks/misc';
import { generateTaskTargetString } from '../../../utilities';
import { useCurrentUser } from '../../../providers/UserProvider';

const createTaskMuation = loader('./TaskModal.createTask.graphql');
const assignTaskMutation = loader('./TaskModal.assign.graphql');
const removeUserFromTaskMutation = loader('./TaskModal.removeUser.graphql');
const deleteTaskMutation = loader('./TaskModal.deleteTask.graphql');
const refetchTaskQuery = loader('./TaskModal.tasks.graphql');

export default function TaskModal() {
  const { modalState, closeModal, updateModal, openConfirmationModal } =
    useModal();
  const { workspace, inWorkspaces } = useWorkspace();
  const { t } = useTranslation();
  const [createTask] = useMutation(createTaskMuation, {
    refetchQueries: [refetchTaskQuery],
  });
  const { user } = useCurrentUser();
  const [assignTask] = useMutation(assignTaskMutation);
  const { templates } = useTemplates();
  const { upsertParam } = useQueryParams();
  const [removeUserFromTask] = useMutation(removeUserFromTaskMutation, {
    refetchQueries: [refetchTaskQuery],
    update(cache, _, { variables: { userId, id: taskId } }) {
      const normalizedUserId = cache.identify({
        id: userId,
        __typename: 'User',
      });
      cache.modify({
        id: normalizedUserId,
        fields: {
          tasks(existingTasksRefs = [], { readField }) {
            return existingTasksRefs.filter(
              (taskRef) => readField('id', taskRef) !== taskId,
            );
          },
        },
      });
    },
  });

  const [deleteTask] = useMutation(deleteTaskMutation, {
    refetchQueries: [refetchTaskQuery],
  });

  const update = (variables) =>
    updateModal({
      modalName: modals.task,
      variables: { ...variables },
    });

  const onHide = () => {
    closeModal({ modalName: modals.task });
  };

  const intervals = useMemo(
    () => ({
      oneTime: { key: 'ONE_TIME', name: t(keys.tasks.ONE_TIME) },
      daily: { key: 'DAILY', name: t(keys.tasks.DAILY) },
      monthly: { key: 'MONTHLY', name: t(keys.tasks.MONTHLY) },
      weekly: { key: 'WEEKLY', name: t(keys.tasks.WEEKLY) },
    }),
    [t],
  );

  // TODO: Translate this
  const targetPeriodsSingular = useMemo(
    () => ({
      daily: { key: 'DAY', name: 'Day' },
      weekly: { key: 'WEEK', name: 'Week' },
      monthly: { key: 'MONTH', name: 'Month' },
      annually: { key: 'YEAR', name: 'Year' },
    }),
    [],
  );

  // TODO: Translate this
  const targetPeriodsPlural = useMemo(
    () => ({
      daily: { key: 'DAY', name: 'Days' },
      weekly: { key: 'WEEK', name: 'Weeks' },
      monthly: { key: 'MONTH', name: 'Months' },
      annually: { key: 'YEAR', name: 'Years' },
    }),
    [],
  );

  const taskTypes = useMemo(
    () => ({
      daily: {
        key: 'INSPECTION',
        name: user.company?.assessmentName || t(keys.assessments.ASSESSMENT),
      },
      weekly: {
        key: 'OBSERVATION',
        name: user.company?.observationName || t(keys.observations.OBSERVATION),
      },
    }),
    [t, user.company?.assessmentName, user.company?.observationName],
  );

  const {
    taskId,
    show,
    title,
    template,
    interval,
    type,
    types,
    assignees,
    taskWorkspace,
    readOnly,
    taskType,
    target,
    targetPeriod,
    targetInterval,
  } = modalState.taskModal;

  const targetPeriods = useMemo(
    () => (targetInterval > 1 ? targetPeriodsPlural : targetPeriodsSingular),
    [targetInterval, targetPeriodsPlural, targetPeriodsSingular],
  );

  const fetchSubmitDisabled = () => {
    switch (taskType) {
      case 'INSPECTION':
        return !title || !template || !workspace || !interval;
      case 'OBSERVATION':
        return !title || !workspace || !interval;
      default:
        return true;
    }
  };

  return (
    <Modal
      open={show || false}
      onClose={onHide}
      variant={readOnly ? 'danger' : 'primary'}
      title={
        readOnly
          ? t(keys.tasks.TASK)
          : t(keys.action.CREATE_VARIABLE, { variable: t(keys.tasks.TASK) })
      }
      subtitle={t(keys.tasks.CREATE_TASK_SECONDARY)}
      hideCancel
      submitDisabled={fetchSubmitDisabled()}
      disableCloseOnSubmit={readOnly}
      titleImage={
        <img className={styles.taskImage} src={taskImage} alt="task_png" />
      }
      submitText={readOnly ? t(keys.action.DELETE) : t(keys.action.SUBMIT)}
      onSubmit={() => {
        readOnly
          ? openConfirmationModal({
              title: t(keys.action.DELETE_VARIABLE, {
                variable: title,
              }),
              description: t(keys.action.DELETE_CONFIRMATION, {
                variable: `${t(keys.tasks.TASK)} ${title}`,
              }),
              variant: 'danger',
              buttonText: t(keys.action.DELETE),
              onSubmit: () => {
                deleteTask({ variables: { id: taskId } });
                onHide();
              },
            })
          : createTask({
              variables: {
                title,
                taskType,
                templateId: template?.id ?? null,
                interval: interval,
                workspaceId: taskWorkspace.id,
                assigneeIds: assignees.map(({ id }) => id),
                target: parseInt(target, 10),
                targetInterval: parseInt(targetInterval, 10),
                targetPeriod,
              },
            }).then(({ errors }) => {
              errors &&
                showToast({
                  title: t(keys.common.ERROR),
                  message: t(keys.tasks.ERROR_EXISTS),
                  variant: toastVariant.warning,
                });
              onHide();
            });
      }}
    >
      <div className={styles.content}>
        <Text noMargin weight="semiBold">
          {t(keys.common.WORKSPACE)}
        </Text>
        {readOnly ? (
          <>
            <div className={styles.spacerSm} />
            <div className={styles.readOnlyText}>
              <img
                className={styles.logo}
                src={taskWorkspace.logoUrl || defaultLogo}
                alt="workspace-logo"
              />
              <Text noMargin>{taskWorkspace?.title}</Text>
            </div>
          </>
        ) : (
          <DropdownCombo
            type={dropdownTypes.WORKSPACE}
            preventStateChange
            highlight={true}
            items={inWorkspaces || []}
            selected={taskWorkspace || workspace}
            onChange={(ws) => {
              upsertParam('workspace', ws?.id);
              update({
                taskWorkspace: ws,
                type: assessmentTypes.fleet,
                template: null,
                assignees: [],
              });
            }}
            placeholder="Workspace"
            required={true}
          />
        )}
        <Text noMargin weight="semibold">
          {t(keys.tasks.TYPE)}
        </Text>
        <div className={styles.spacerSm} />
        {readOnly ? (
          <Text>
            {
              taskTypes[
                Object.keys(taskTypes).find(
                  (k) => taskTypes[k].key === taskType,
                )
              ].name
            }
          </Text>
        ) : (
          <Form.Select
            value={taskType}
            onChange={(e) => update({ taskType: e.target.value })}
            aria-label="Task type"
          >
            {Object.keys(taskTypes).map((k) => (
              <option key={taskTypes[k].key} value={taskTypes[k].key}>
                {taskTypes[k].name}
              </option>
            ))}
          </Form.Select>
        )}
        <div className={styles.spacerSm} />
        {taskType === 'INSPECTION' && (
          <>
            <Text noMargin weight="semibold">
              {t(keys.tasks.TEMPLATE_TYPE)}
            </Text>
            <div className={styles.spacerSm} />
            {readOnly ? (
              <>
                <div className={styles.spacerSm} />
                <div className={styles.readOnlyText}>
                  <Icon
                    baseClassName="material-icons-outlined"
                    className={styles[type.color]}
                  >
                    {type.icon}
                  </Icon>
                  <Text noMargin>{t(type?.title)}</Text>
                </div>
              </>
            ) : (
              <TypeSelectorDropdown
                onChange={(newType) => {
                  update({ type: newType, template: null });
                }}
                selected={type}
                items={types.map((t, index) => ({
                  title: t.title,
                  icon: t.icon,
                  color: t.color,
                  key: t.key,
                  id: index,
                }))}
              />
            )}
            <Text noMargin weight="semiBold">
              {t(keys.common.TEMPLATE)}
            </Text>
            {readOnly ? (
              <>
                <div className={styles.spacerSm} />
                <div className={styles.readOnlyText}>
                  <Text noMargin>{template?.title}</Text>
                </div>
              </>
            ) : (
              <DropdownCombo
                type={dropdownTypes.TEMPLATE}
                highlight
                items={templates || []}
                onChange={(newTemplate) => {
                  update({ template: newTemplate });
                }}
                onRemove={() => update({ template: null })}
                selected={template}
              />
            )}
            <div className={styles.spacerSm} />
          </>
        )}
        <Text className={styles.text} noMargin weight="semiBold">
          {t(keys.tasks.NAME)}
        </Text>
        <div className={styles.spacerSm} />
        {readOnly ? (
          <Text>{title}</Text>
        ) : (
          <Form.Control
            type="text"
            placeholder={t(keys.tasks.ENTER_NAME)}
            value={title}
            onChange={({ target: { value } }) => update({ title: value })}
          />
        )}
        <div className={styles.spacerSm} />
        <Text className={styles.text} noMargin weight="semiBold">
          Target
        </Text>
        <div className={styles.spacerSm} />
        {readOnly ? (
          <Text>
            {generateTaskTargetString({ target, targetInterval, targetPeriod })}
          </Text>
        ) : (
          <div className={styles.targetInputContainer}>
            <Form.Control
              type="number"
              value={target}
              onChange={({ target: { value } }) => update({ target: value })}
            />
            <Text noMargin>every</Text>
            <Form.Control
              type="number"
              min={1}
              value={targetInterval}
              onChange={({ target: { value } }) =>
                update({ targetInterval: Math.max(value, 1) })
              }
            />
            <Form.Select
              value={targetPeriod}
              onChange={(e) => update({ targetPeriod: e.target.value })}
              aria-label="Target period"
            >
              {Object.keys(targetPeriods).map((k) => (
                <option key={targetPeriods[k].key} value={targetPeriods[k].key}>
                  {targetPeriods[k].name}
                </option>
              ))}
            </Form.Select>
          </div>
        )}
        <div className={styles.spacerSm} />
        <Text noMargin weight="semiBold">
          {t(keys.tasks.INTERVAL)}
        </Text>
        <div className={styles.spacerSm} />
        {readOnly ? (
          <div className={styles.readOnlyText}>
            <Text noMargin>{t(taskIntervalStringLookup[interval])}</Text>
          </div>
        ) : (
          <FormControl>
            <RadioGroup
              defaultValue={intervals.oneTime.key}
              row={true}
              onChange={({ target: { value } }) => update({ interval: value })}
            >
              <FormControlLabel
                value={intervals.oneTime.key}
                control={<Radio />}
                label={<Text noMargin>{intervals.oneTime.name}</Text>}
              />
              <FormControlLabel
                value={intervals.daily.key}
                control={<Radio />}
                label={<Text noMargin>{intervals.daily.name}</Text>}
              />
              <FormControlLabel
                value={intervals.weekly.key}
                control={<Radio />}
                label={<Text noMargin>{intervals.weekly.name}</Text>}
              />
              <FormControlLabel
                value={intervals.monthly.key}
                control={<Radio />}
                label={<Text noMargin>{intervals.monthly.name}</Text>}
              />
            </RadioGroup>
          </FormControl>
        )}
        <div className={styles.spacerSm} />
        <Text noMargin weight="semiBold">
          {t(keys.common.ASSIGNEES)}
        </Text>
        <div className={styles.spacerSm} />
        <UserMultiSelector
          type={'byWorkspace'}
          workspaceId={taskWorkspace?.id}
          selected={assignees}
          onUserAdded={(user) => {
            if (taskId) {
              const userHasSimilarTask = user.tasks.some(
                ({ templateId, workspaceId }) =>
                  templateId === template.id &&
                  workspaceId === taskWorkspace.id,
              );

              if (userHasSimilarTask) {
                const similarTask = user.tasks.find(
                  ({ templateId, workspaceId }) =>
                    templateId === template.id &&
                    workspaceId === taskWorkspace.id,
                );
                const name = user.firstName;

                openConfirmationModal({
                  title: t(keys.common.HOLD_ON),
                  highlight: true,
                  wordsToHighlight: [name, similarTask.title, title].map(
                    (item) => item.toLowerCase(),
                  ),
                  description: t(keys.tasks.REASSIGN, {
                    name,
                    similarTask: similarTask.title,
                    title,
                  }),
                  variant: 'warning',
                  buttonText: t(keys.common.YES),
                  onSubmit: () => {
                    removeUserFromTask({
                      variables: { id: similarTask.id, userId: user.id },
                    })
                      .then(() =>
                        assignTask({
                          variables: { id: taskId, assigneeIds: [user.id] },
                        }),
                      )
                      .then(() => update({ assignees: [...assignees, user] }));
                  },
                });
              } else {
                assignTask({
                  variables: { id: taskId, assigneeIds: [user.id] },
                }).then(() => update({ assignees: [...assignees, user] }));
              }
            } else {
              update({ assignees: [...assignees, user] });
            }
          }}
          onUserRemoved={(user) =>
            taskId
              ? removeUserFromTask({
                  variables: { id: taskId, userId: user.id },
                }).then(() =>
                  update({
                    assignees: assignees.filter(({ id }) => id !== user.id),
                  }),
                )
              : update({
                  assignees: assignees.filter(({ id }) => id !== user.id),
                })
          }
        />
      </div>
      <br />
    </Modal>
  );
}
