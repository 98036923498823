import styles from './InspectionSignatures.module.scss';
import { useMemo } from 'react';
import { Text } from '../../components/typography';
import { getLocalTime } from '../../utilities/time';

const defaultAvatar =
  'https://opas-assets-dev.s3.amazonaws.com/df4384d6-c97e-478b-b41d-3cad86ae93f9.webp';

export default function InspectionSignatures({ attachments }) {
  const signatures = useMemo(() => {
    return attachments
      ?.map((a) => {
        if (a.extension) {
          return a.extension === 'SIGNATURE' ? a : null;
        }
        return a;
      })
      .filter(Boolean);
  }, [attachments]);

  return (
    <div className={styles.signatures}>
      {signatures?.map((s, idx) => (
        <div key={`${s.name}-${idx}`} className={styles.signature}>
          <img
            src={s.creator?.avatarUrl || defaultAvatar}
            className={styles.avatar}
            alt="avatar"
          />
          <div>
            <Text noMargin weight="semiBold" size="sm" className={styles.text}>
              {s.name}
            </Text>
            <Text size="sm" color="secondary" noMargin>
              {getLocalTime(parseInt(s.dateCreated)).format(
                'ddd MMMM Do, YYYY HH:mm',
              )}
            </Text>
          </div>
          <img className={styles.image} src={s.url} alt="signature" />
        </div>
      ))}
    </div>
  );
}
