import styles from './SimpleFileCard.module.scss';
import { downloadFile } from '../../utilities/inspection';
import findFileIcon from '../../utilities/files';
import { Text } from '../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import Icon from '../Icon';
import { openFileViewer } from '../../graphql/cache/modal';
import { imageExtensions } from '../../utilities/files';
import { useModal } from '../../providers/ModalProvider';
import { getLocalTime } from '../../utilities/time';
import SimpleUserCard from '../SimpleUserCard';

export default function SimpleFileCard({
  extension,
  file,
  fileName,
  readOnly = true,
  onDelete,
}) {
  const { t } = useTranslation();
  const url = file?.url ? file.url : file.imageUrl;
  const src = imageExtensions.includes(extension)
    ? url
    : findFileIcon(extension);
  const { openConfirmationModal } = useModal();

  return (
    <div className={styles.container}>
      <div className={styles.fileDisplay}>
        <div
          onClick={() => {
            openFileViewer(file);
          }}
          className={styles.file}
        >
          <img className={styles.fileImage} src={src} alt="file" />
          <div className={styles.cardText}>
            <Text weight="semiBold" noMargin size="sm" truncate>
              {fileName ??
                `${extension.toUpperCase()} ${t(keys.assessments.ATTACHMENT)}`}
            </Text>
            {file.dateCreated && (
              <Text size="xs" color="secondary" noMargin truncate>
                {getLocalTime(file?.dateCreated).format('dddd MMM DD, YYYY')}
              </Text>
            )}
            {file.creator ? (
              <SimpleUserCard size="sm" user={file.creator} />
            ) : null}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Icon
            hover
            style={{ fontSize: '1rem' }}
            color={'primary'}
            className={styles.icon}
            onClick={() => {
              openConfirmationModal({
                title: t(keys.action.DOWNLOAD_VARIABLE, {
                  variable: fileName,
                }),
                description: t(keys.action.DOWNLOAD_CONFIRMATION, {
                  variable: fileName,
                }),
                variant: 'warning',
                onSubmit: () => {
                  downloadFile(url, fileName);
                },
              });
            }}
          >
            download
          </Icon>
          {!readOnly && (
            <Icon
              onClick={() => {
                openConfirmationModal({
                  title: 'Delete File',
                  description:
                    'Are you sure you want to delete this file? This action cannot be undone.',
                  variant: 'danger',
                  onSubmit: () => onDelete(file.id),
                });
              }}
              style={{ fontSize: '1rem' }}
              hover
              color="red"
            >
              delete
            </Icon>
          )}
        </div>
      </div>
    </div>
  );
}
