import Modal from '../Modal';
import { useState } from 'react';
import { useModal } from '../../../providers/ModalProvider';
import { modals } from '../../../providers/modals';
import SpeechToText from '../../SpeechToText';
import UserMultiSelector from '../../UserMultiSelector';
import { Text } from '../../typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { Form } from 'react-bootstrap';
import styles from './RecordMeetingModal.module.scss';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { Icon } from '@mui/material';
import {
  openAnalysisModal,
  openFileViewer,
  showToast,
} from '../../../graphql/cache/modal';
import Button from '../../Button';
import { useApolloClient } from '@apollo/client';
import { Spinner } from 'react-bootstrap';
import findFileIcon from '../../../utilities/files';
import {
  getExtension,
  internalTemplateMEETING,
} from '../../../utilities/inspection';
import { fileType, toastLength, toastVariant } from '../../../constants/misc';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../../constants/strings';
import { useCurrentUser } from '../../../providers/UserProvider';
import { customColors } from '../../../utilities';
import InspectionSignatures from '../../../pages/inspection_page/InspectionSignatures';
import SignatureCanvas from '../../SignatureCanvas';
import { useOnlineStatus } from '../../../hooks/offline-hooks/offline-misc';

const createMeetingInspectionMutation = loader(
  './RecordMeetingModal.create.graphql',
);
const inspectionQuery = loader('./RecordMeetingModal.fetch.graphql');
const addInspectionAttachment = loader(
  '../../../pages/inspection_page/Inspection.addAttachment.graphql',
);

export default function RecordMeetingModal() {
  const {
    closeModal,
    modalState,
    updateModal,
    openModal,
    openConfirmationModal,
  } = useModal();
  const { user: currentUser } = useCurrentUser();
  const { workspaceId } = useWorkspace();
  const { t } = useTranslation();
  const [addAttachment] = useMutation(addInspectionAttachment);
  const {
    show,
    participants,
    internalType,
    location,
    description,
    safetyTopic,
    externalParticipants,
    attachment,
    refetch,
    onView,
    regrets,
    signatures = [],
  } = modalState.recordMeetingModal;

  const [file, setFile] = useState(null);
  const [createMeetingInspection] = useMutation(
    createMeetingInspectionMutation,
  );
  const online = useOnlineStatus();
  const { user } = useCurrentUser();
  const [showSignatures, setShowSignatures] = useState(false);

  const onClose = (id) => {
    closeModal({ modalName: modals.recordMeeting });
    setTranscript('');
    setIsRecording(false);
    setSecondSight(true);
    setFile(null);
    refetch();
    if (!!id) {
      navigate(getRoute(workspaceId, paths.assessment, id));
    }
  };
  const onHide = () => {
    openConfirmationModal({
      title: t(keys.assessments.CLOSE_CONFIRMATION_TITLE),
      description: t(keys.assessments.CLOSE_CONFIRMATION_MESSAGE),
      variant: 'danger',
      buttonText: t(keys.action_items.CLOSE),
      onSubmit: () => {
        onClose();
      },
    });
  };
  const update = (variables) =>
    updateModal({
      modalName: modals.recordMeeting,
      variables: { ...variables },
    });
  const hideSignatures = () => setShowSignatures(false);
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [loading, setLoading] = useState(false);
  const [secondSight, setSecondSight] = useState(true);
  const [allWorkspaces, setAllWorkspaces] = useState(false);
  const client = useApolloClient();
  const navigate = useNavigate();

  const footer = (
    <div className={styles.footer}>
      <div className={styles.check}>
        <div className={styles.checkContainer}>
          <Text noMargin weight="semiBold">
            {t(keys.templates.ALL_WORKSPACES)}
          </Text>
          <Form.Check
            type="switch"
            onChange={(e) => {
              setAllWorkspaces(e.target.checked);
              update({ participants: [currentUser] });
            }}
            checked={allWorkspaces}
          />
        </div>
        <div className={styles.checkContainer}>
          <Text noMargin weight="semiBold">
            {t(keys.assessments.SECOND_SIGHT_ANALYSIS)}
          </Text>
          <Form.Check
            type="switch"
            onChange={(e) => setSecondSight(e.target.checked)}
            checked={secondSight}
          />
        </div>
      </div>
      <Button
        disabled={isRecording || loading}
        value={t(keys.action.SUBMIT)}
        onClick={() => {
          const items = internalTemplateMEETING(
            description,
            file,
            transcript,
            safetyTopic,
            location,
            externalParticipants,
            attachment,
          );
          openConfirmationModal({
            title: t(keys.assessments.SUBMIT_CONFIRMATION_TITLE),
            description: t(keys.assessments.SUBMIT_CONFIRMATION_MESSAGE),
            variant: 'warning',
            onSubmit: async () => {
              try {
                const {
                  data: {
                    useInternalTemplate: { id },
                  },
                } = await createMeetingInspection({
                  variables: {
                    workspaceId: allWorkspaces ? null : workspaceId,
                    internalType,
                    items,
                    participants: participants?.map(({ id }) => id) || [],
                    regrets: regrets?.map(({ id }) => id) || [],
                  },
                });
                if (signatures?.length) {
                  for (let signature of signatures) {
                    addAttachment({
                      variables: {
                        url: signature.url,
                        inspectionId: id,
                        name: signature.name,
                        extension: 'SIGNATURE',
                      },
                    });
                  }
                }

                if (!!secondSight) {
                  const {
                    data: { inspections: [inspection] = [{ setFile }] },
                  } = await client.query({
                    query: inspectionQuery,
                    variables: { id: `${id}` },
                  });
                  onClose(id);
                  openAnalysisModal({
                    inspection,
                  });
                } else {
                  onClose(id);
                }
                showToast({
                  title: t(keys.assessments.SUBMIT_TITLE),
                  message: t(keys.assessments.SUBMIT_TITLE),
                  variant: toastVariant.info,
                  time: toastLength.md,
                });
              } catch (error) {
                console.error(error);
              }
            },
          });
        }}
      />
    </div>
  );

  return (
    <Modal
      open={show || false}
      onClose={onHide}
      title={t(keys.assessments.RECORD_MEETING)}
      allowClose={!isRecording}
      hideCancel
      hideSubmit
      beta
      className={styles.fullScreen}
      footer={footer}
      titleImage={
        <Text size="lg" noMargin>
          📆
        </Text>
      }
    >
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.recorder}>
            <div className={styles.voice}>
              <Text weight="semiBold" size="lg" textAlign="center" noMargin>
                {t(keys.assessments.VOICE_RECORDER)}
              </Text>
              {isRecording && (
                <Icon sx={{ color: 'green' }}>radio_button_checked</Icon>
              )}
            </div>
            <SpeechToText
              isRecording={isRecording}
              setIsRecording={setIsRecording}
              transcript={transcript}
              setTranscript={setTranscript}
              setFile={setFile}
              file={file}
            />
          </div>
          <div className={styles.spacer} />
          <div className={styles.header}>
            <Text noMargin weight="semiBold">
              {t(keys.assessments.SAFETY_TOPIC)}
            </Text>
            {!!onView && (
              <Text
                hover
                onClick={() => onView()}
                noMargin
                size="sm"
                color="accentPrimary"
              >
                {t(keys.assessments.VIEW)}
              </Text>
            )}
          </div>
          <Form.Control
            value={safetyTopic}
            as="textarea"
            rows={3}
            size="sm"
            onChange={(e) => update({ safetyTopic: e.target.value })}
            className={styles.input}
          />
          <Text noMargin weight="semiBold">
            {t(keys.assessments.MEETING_DESCRIPTION)}
          </Text>
          <Form.Control
            as="textarea"
            value={description}
            rows={3}
            size="sm"
            onChange={(e) => update({ description: e.target.value })}
            className={styles.input}
          />
          <div className={styles.header}>
            {isRecording && (
              <Icon sx={{ color: 'green' }}>radio_button_checked</Icon>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <Text noMargin weight="semiBold">
            {t(keys.assessments.LOCATION)}
          </Text>
          <Form.Control
            size="sm"
            as="textarea"
            rows={1}
            value={location}
            onChange={(e) => update({ location: e.target.value })}
            className={styles.input}
          />
          <Text noMargin weight="semiBold">
            {t(keys.common.PARTICIPANTS)}
          </Text>
          <UserMultiSelector
            type={allWorkspaces ? 'all' : 'users'}
            selected={participants}
            onUserAdded={(user) =>
              update({ participants: [...participants, user] })
            }
            onUserRemoved={(user) => {
              update({
                participants: participants.filter((u) => u.id !== user.id),
              });
            }}
          />
          <Text noMargin weight="semiBold">
            {t(keys.common.REGRETS)}
          </Text>
          <UserMultiSelector
            type={allWorkspaces ? 'all' : undefined}
            selected={regrets}
            onUserAdded={(user) => update({ regrets: [...regrets, user] })}
            onUserRemoved={(user) => {
              update({
                regrets: regrets.filter((u) => u.id !== user.id),
              });
            }}
          />
          <div className={styles.header}>
            <Text noMargin weight="semiBold">
              {t(keys.assessments.ATTACHMENT)}
            </Text>
            <Icon
              disabled={loading}
              baseClassName="material-icons-outlined"
              className={styles.icon}
              sx={{ color: attachment ? 'grey' : customColors.BLUE }}
              onClick={() => {
                attachment
                  ? update({ attachment: null })
                  : openModal({
                      modalName: modals.fileUpload,
                      variables: {
                        assessment: true,
                        simple: true,
                        onSubmit: ({ url, name }) => {
                          const extension = url.split('.').pop();
                          update({
                            attachment: {
                              url,
                              name,
                              downloadAllowed: true,
                              fileType: fileType[extension],
                            },
                          });
                          setLoading(false);
                        },
                        onLoading: () => {
                          setLoading(true);
                        },
                      },
                    });
              }}
            >
              {attachment ? 'delete' : 'add'}
            </Icon>
          </div>
          {loading ? (
            <Spinner animation="border" variant="primary" />
          ) : attachment ? (
            <div className={styles.header}>
              <div
                className={styles.fileCard}
                onClick={() => openFileViewer(attachment)}
              >
                <img
                  src={findFileIcon(getExtension(attachment?.url))}
                  alt="meeting_attachment"
                  className={styles.image}
                ></img>
                <Text weight="semiBold" noMargin>
                  {attachment?.name}
                </Text>
              </div>
            </div>
          ) : (
            <Text noMargin>{t(keys.common.NONE)}</Text>
          )}
          <div className={styles.header}>
            <Text noMargin weight="semiBold">
              Signatures
            </Text>
            <Icon
              onClick={() => {
                setShowSignatures(!showSignatures);
              }}
              sx={{ color: customColors.BLUE, cursor: 'pointer' }}
            >
              {showSignatures ? 'cancel' : 'add'}
            </Icon>
          </div>
          {online ? (
            <>
              {!showSignatures ? (
                <div className={styles.signatures}>
                  <InspectionSignatures
                    size="sm"
                    attachments={signatures || {}}
                  />
                </div>
              ) : (
                <div className={styles.signatureContainer}>
                  <SignatureCanvas
                    onSubmit={(url, failed, pass, name) => {
                      const dateCreated = new Date().getTime();
                      const signature = {
                        url,
                        name,
                        dateCreated,
                        creator: user,
                      };
                      update({ signatures: [...signatures, signature] });
                      hideSignatures();
                    }}
                  />
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
