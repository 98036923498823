export const mockQuestionTypes = [
  {
    icon: 'done',
    name: 'Test',
  },
  {
    icon: 'text_fields',
    name: 'Text',
    observationType: 'true',
  },
  {
    icon: '123',
    name: 'Number',
  },
  {
    icon: 'photo_camera',
    name: 'Image',
  },
  { icon: 'account_box', name: 'User' },
  { icon: 'library_add_check', name: 'Select', observationType: 'true' },
  { icon: 'table_view', name: 'Table' },
  { icon: 'border_color', name: 'Signature' },
  { icon: 'calendar_month', name: 'Date', observationType: 'true' },
  { icon: 'apps', name: 'Matrix' },
  { icon: 'upload_file', name: 'File' },
  { icon: 'mic', name: 'Audio' },
  { icon: 'local_shipping', name: 'Asset' },
];

export const testQuestionOptions = [
  'Pass, Fail, N/A',
  'Pass, Fail',
  'Yes, No, N/A',
  'Yes, No',
  'OK, Issue',
  'OK, Issue, N/A',
  'Acceptable, Uncceptable, N/A',
];

export const statusType = {
  none: 'NONE',
  pass: 'PASS',
  fail: 'FAIL',
  notApplicable: 'NOT_APPLICABLE',
};

export const inputTypes = {
  image: 'IMAGE',
  number: 'NUMBER',
  test: 'TEST',
  text: 'TEXT',
  table: 'TABLE',
  user: 'USER',
  select: 'SELECT_SINGLE',
  multiSelect: 'SELECT_MULTI',
  signature: 'SIGNATURE_SINGLE',
  multiSignature: 'SIGNATURE_MULTI',
  date: 'DATE',
  matrix: 'MATRIX',
  multiText: 'TEXT_MULTI',
  multiFile: 'FILE_MULTI',
  file: 'FILE_SINGLE',
  multiImage: 'IMAGE_MULTI',
  multiUser: 'USER_MULTI',
  dateTime: 'DATE_TIME',
  time: 'TIME',
  audio: 'AUDIO',
  asset: 'ASSET',
};

export const getExtension = (url) => {
  const urlParts = url?.split('.');
  return url ? urlParts[urlParts?.length - 1] : null;
};

export const internalTemplateMEETING = (
  description,
  file,
  transcript,
  safetyTopic,
  location,
  externalParticipants,
  attachment,
) => ({
  'Meeting Description': { value: description },
  'Meeting Recording': {
    value: file?.url || null,
    additional: file?.name || null,
  },
  'Meeting Transcription': { value: transcript },
  'Safety Topic': { value: safetyTopic },
  Location: { value: location },
  'External Participants': { value: externalParticipants },
  Attachment: {
    value: attachment?.url || '',
    additional: attachment?.name || '',
  },
});

export const dateOptions = {
  DATE: {
    title: 'Date',
    format: 'YYYY-MM-DD',
    views: ['year', 'month', 'day'],
  },
  DATE_TIME: {
    title: 'Date and Time',
    format: 'YYYY-MM-DD h:mm A',
    views: ['day', 'hours', 'minutes', 'month', 'year'],
    timeSteps: { hours: 1, minutes: 1 },
  },
  TIME: {
    title: 'Time',
    format: 'h:mm a',
    timeSteps: { hours: 1, minutes: 1 },
  },
};

export const downloadFile = (url, name) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      const awsExtension = new URL(url).pathname?.split('.')[1];
      if (name?.split('.').length > 1) {
        link.download = name;
      } else {
        link.download = name + '.' + awsExtension;
      }
      link.href = objectUrl;
      link.click();
      URL.revokeObjectURL(objectUrl);
    })
    .catch((error) => console.error(error));
};

export const formatBundleItems = (items) => {
  const bundleMap = {};
  const itemMap = {};

  items.forEach((item) => {
    if (item?.bundleId) {
      bundleMap[item.categoryId] = bundleMap[item.categoryId]
        ? bundleMap[item.categoryId]
        : {};

      bundleMap[item.categoryId][item.bundleId] = bundleMap[item.categoryId][
        item?.bundleId
      ]
        ? bundleMap[item.categoryId][item?.bundleId]
        : {};

      bundleMap[item.categoryId][item.bundleId][item.questionId] = item;
      if (!itemMap[item.questionId]) {
        itemMap[item?.questionId] = [item];
      } else {
        itemMap[item?.questionId]?.push(item);
      }
    } else {
      itemMap[item?.questionId] = item;
    }
  });

  return { bundleMap, itemMap };
};
export function numberCheck(additionalData) {
  const [min, max] = additionalData?.split('|') || [];
  const minValue =
    min !== undefined && min !== null && min !== '' && !isNaN(min)
      ? parseFloat(min)
      : null;
  const maxValue =
    max !== undefined && max !== null && max !== '' && !isNaN(max)
      ? parseFloat(max)
      : null;
  let string;

  if (minValue !== null && maxValue !== null) {
    string = `Tolerance: ${minValue}-${maxValue}`;
  } else if (minValue !== null) {
    string = `Min: ${minValue}`;
  } else if (maxValue !== null) {
    string = `Max: ${maxValue}`;
  } else {
    string = '';
  }

  return string;
}
