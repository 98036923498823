import Icon from '../Icon';
import { useState } from 'react';
import styles from './ActionItemCreateComment.module.scss';
import { Form } from 'react-bootstrap';
import { useCurrentUser } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useIsMobile } from '../../hooks/misc';
import Button from '../Button';

export default function ActionItemCreateComment({ onSubmit }) {
  const [comment, setComment] = useState(undefined);
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <div className={styles.container}>
      <img className={styles.avatarUrl} src={user.avatarUrl} alt="avatar" />
      <Form.Control
        value={comment}
        placeholder={t(keys.action_items.COMMENT)}
        onChange={(e) => setComment(e.target.value)}
        as="textarea"
      />
      {mobile ? (
        <Icon
          className={!!comment ? styles.icon : styles.disabled}
          onClick={() => {
            onSubmit(comment);
            setComment('');
          }}
        >
          send
        </Icon>
      ) : (
        <Button
          value="Send"
          disabled={!comment}
          onClick={() => {
            onSubmit(comment);
            setComment('');
          }}
        />
      )}
    </div>
  );
}
