import { makeVar } from '@apollo/client';
import { toastLength, toastVariant } from '../../constants/misc';

export const labelCreationModalVar = makeVar({
  labels: [],
  show: false,
  onCompleted: () => {},
});

export const multiUserModalVar = makeVar({
  users: [],
  show: false,
  onCompleted: () => {},
});

export const fileViewerVar = makeVar({
  show: false,
  file: null,
});

export const dailyReportVar = makeVar({
  show: false,
});

export const defaultFailureModalState = {
  title: '',
  description: '',
  notes: '',
  imageUrl: '',
  show: false,
  onSubmit: () => {},
};

export const openFailureModal = ({ title, description, onSubmit }) =>
  failureModalVar({
    ...defaultFailureModalState,
    title,
    description,
    show: true,
    onSubmit,
  });

// Might need to delay this
export const closeFailureModal = () =>
  failureModalVar(defaultFailureModalState);

export const failureModalVar = makeVar(defaultFailureModalState);

export const defaultSignatureModalState = {
  title: '',
  message: '',
  variant: 'warning',
  buttonText: 'submit',
  onSubmit: () => {},
  onCancel: () => {},
  show: false,
  additionalInput: null,
};

export const signatureModalVar = makeVar(defaultSignatureModalState);

export const defaultAlertState = {
  title: '',
  buttonText: 'Submit',
  description: '',
  variant: 'danger',
  onSubmit: () => {},
  onHide: () => {
    alertVar({
      ...alertVar(),
      show: false,
    });
  },
  show: false,
  doubleConfirm: false,
  confirmText: '',
  onCancel: () => {},
  cancelText: null,
};

const defaultNetworkModalState = {
  isOnline: true,
  show: false,
  title: '',
  message: '',
  buttonText: 'OK',
  onCancel: () => {},
};

export const defaultCsvImportModalState = {
  templateId: 0,
  show: false,
  refetch: () => {},
};

export const defaultToastState = {
  title: '',
  message: '',
  variant: 'info',
  show: false,
  time: toastLength.md,
};

export const defaultImageUploadModal = {
  title: 'Image',
  onSubmit: () => {},
  show: false,
  onLoading: () => {},
};

export const defaultTextInputModal = {
  title: '',
  inputs: [],
  onSubmit: () => {},
  show: false,
  submitText: 'Submit',
  namespace: 'common',
};

export const toastVar = makeVar({ defaultToastState });
export const imageUploadModalVar = makeVar({
  defaultImageUploadModal,
});

export const textInputModalVar = makeVar(defaultTextInputModal);

export const openTextInputModal = ({
  inputs,
  title,
  submitText,
  onSubmit,
  namespace,
}) =>
  textInputModalVar({
    ...textInputModalVar,
    show: true,
    title,
    submitText,
    onSubmit,
    inputs,
    namespace,
  });

export const alertVar = makeVar(defaultAlertState);
export const networkModalVar = makeVar(defaultNetworkModalState);

export const actionItemModalVar = makeVar({
  show: false,
  id: undefined,
  type: 'VEHICLE',
});

export const showToast = ({
  title,
  message,
  variant = toastVariant.info,
  time = toastLength.md,
}) => {
  toastVar({
    title,
    message,
    variant,
    show: true,
    time,
  });
};

export const hideToast = () => {
  toastVar({ ...toastVar(), show: false });
  setTimeout(() => {
    toastVar(defaultToastState);
  }, 1000);
};

export const openDailyReportModal = () => {
  dailyReportVar({ ...dailyReportVar, show: true });
};

export const closeDailyReportModal = () => {
  dailyReportVar({ ...dailyReportVar, show: false });
};

export const openImageUploadModal = (
  title,
  callback,
  onLoading = () => {},
  showDescription = false,
) =>
  imageUploadModalVar({
    ...imageUploadModalVar(),
    title,
    onSubmit: callback,
    show: true,
    onLoading,
    showDescription,
  });

export const openNetworkModal = ({
  isOnline,
  title,
  message,
  onSubmit,
  buttonText,
}) => {
  networkModalVar({
    isOnline,
    show: true,
    title,
    message,
    onSubmit,
    buttonText,
  });
};

export const closeNetworkModal = () => {
  networkModalVar(defaultNetworkModalState);
};

export const hideActionItemModal = () => {
  actionItemModalVar({ show: false, id: undefined });
};

export const csvImportModalVar = makeVar(defaultCsvImportModalState);

export const openCsvImportModal = (template, refetch) =>
  csvImportModalVar({ show: true, template, refetch });

export const openLabelCreationModal = (labels, onCompleted) => {
  labelCreationModalVar({ show: true, labels, onCompleted });
};

export const hideLabelCreationModal = () => {
  labelCreationModalVar({
    show: false,
    onCompleted: () => {},
    labels: [],
  });
};

export const openMultiUserModal = (users, onCompleted) => {
  multiUserModalVar({ show: true, users, onCompleted });
};

export const hideMultiUserModal = () => {
  multiUserModalVar({
    show: false,
    onCompleted: () => {},
    users: [],
  });
};
export const openEditVehicleModal = (vehicle) =>
  editVehicleModalVar({ show: true, vehicle });

export const editVehicleModalVar = makeVar({
  show: false,
  vehicle: {},
});

export const hideEditVehicleModal = () =>
  editVehicleModalVar({ show: false, vehicle: {} });

export const openEditVehicleFeaturesModal = (features, refetch) =>
  editVehicleFeaturesModalVar({ show: true, features, refetch });

export const editVehicleFeaturesModalVar = makeVar({
  show: false,
  features: {},
  refetch: () => {},
});

export const hideEditVehicleFeaturesModal = () =>
  editVehicleFeaturesModalVar({ show: false, features: {} });

export const filterTableModalVar = makeVar({
  show: false,
  filters: {},
  filterOptions: [],
  statusOptions: [],
  onSave: () => {},
});
export const openTableFilterModal = (
  filters,
  filterOptions,
  statusOptions,
  priorityOptions,
  onSave,
  userQuery,
) =>
  filterTableModalVar({
    show: true,
    filters,
    filterOptions,
    statusOptions,
    priorityOptions,
    onSave,
    userQuery,
  });
export const hideTableFilterModal = () =>
  filterTableModalVar({
    show: false,
    filters: {},
  });

export const filterAssetModalVar = makeVar({
  show: false,
  filters: {},
  filterOptions: [],
  onSave: () => {},
});
export const openAssetFilterModal = (
  filters,
  filterOptions,
  onSave,
  assetType,
) =>
  filterAssetModalVar({
    show: true,
    filters,
    filterOptions,
    onSave,
    assetType,
  });
export const hideAssetFilterModal = () =>
  filterAssetModalVar({
    show: false,
    filters: {},
  });
export const fullPageImageModalVar = makeVar({
  show: false,
  image: {},
});
export const openFullPageImageModal = ({ image }) => {
  fullPageImageModalVar({ show: true, image });
};
export const hideFullPageImageModal = () => {
  fullPageImageModalVar({ show: false });
};
export const toolboxTalkModalVar = makeVar({
  show: false,
  info: {},
});
export const openToolboxTalkModal = ({ info }) => {
  toolboxTalkModalVar({ show: true, info });
};
export const hideToolboxTalkModal = () => {
  toolboxTalkModalVar({ show: false });
};
export const analysisModalVar = makeVar({
  show: false,
  inspection: {},
  onSubmit: () => {},
});
export const openAnalysisModal = ({ onSubmit, inspection }) =>
  analysisModalVar({ show: true, onSubmit, inspection });
export const hideAnalysisModal = () => analysisModalVar({ show: false });

export const openFileViewer = (file, onDetailsClick) =>
  fileViewerVar({ show: true, file, onDetailsClick });
export const closeFileViewer = () =>
  fileViewerVar({ show: false, file: null, onDetailsClick: () => {} });
