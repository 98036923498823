import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import { Text } from '../../components/typography';
import VehicleCard from '../../components/vehicles/VehicleCard';
import { paths } from '../../constants/strings';
import styles from './VehiclesPage.module.scss';
import noVehicles from '../../assets/empty_state_images/vehicle.svg';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import { useState } from 'react';
import TableSorting from '../../components/sorting/TableSorting';
import TablePagination from '../../components/pagination/TablePagination';
import { sortVehicleStrings } from '../../constants/strings';
import { convertVehicleFiltersToQueryParams } from '../../utilities/filtering';
import { vehicleFilterOptions } from '../../utilities/filtering';
import AssetFiltering from '../../components/sorting/AssetFiltering';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { getRoute } from '../../constants/strings';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  getTranslationKey,
  keys,
} from '../../utilities/translator/translation_keys';
import { Icon } from '@mui/material';
import TablePrototype from '../../components/TablePrototype.jsx';
import SimpleUserCard from '../../components/SimpleUserCard.jsx';
import Searchbar from '../../components/Searchbar.jsx';
import TypeSelectorDropdown from '../../components/dropdowns/TypeSelectorDropdown.jsx';
import {
  assetTypeIcons,
  assetTypeStringLookup,
  showAssetAlarmClassname,
  vehicles,
} from '../../utilities/vehicles.js';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider.jsx';
import { useTableComponents } from '../../components/tables/MTableComponents.jsx';
import { asseetColorStringLookup } from '../../utilities/vehicles.js';

const vehicleQuery = loader('./VehiclesPage.graphql');

export default function VehiclesPage({ disabled = false }) {
  const navigate = useNavigate();
  const { workspace, isWorkspaceAdmin } = useWorkspace();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('dateCreated');
  const [isAscending, setIsAscending] = useState(false);
  const { t } = useTranslation();
  const sortingOptions = ['dateCreated', 'make', 'model', 'unitNumber', 'year'];
  const [rawFilters, setRawFilters] = useState({});
  const [tableView, setTableView] = useState(false);
  const [searchOn, setSearchOn] = useState(false);
  const { allWorkspaceUsers } = useWorkspacePermissions();
  const { labelCell, textCell, dateCell } = useTableComponents();

  const [searchText, setSearchText] = useState('');

  const searchSelectorItems = useMemo(() => {
    return [
      {
        id: 1,
        title: t(keys.assets.UNIT_NUMBER),
        key: 'unitNumber',
      },
      {
        id: 2,
        title: t(keys.assets.MAKE),
        key: 'make',
      },
      {
        id: 3,
        title: t(keys.assets.MODEL),
        key: 'model',
      },
    ];
  }, [t]);

  const [searchField, setSearchField] = useState(searchSelectorItems[0]);

  const filters = useMemo(() => {
    return Object.keys(rawFilters).length || searchText.length
      ? convertVehicleFiltersToQueryParams(rawFilters, {
          searchField: searchField.key,
          searchText,
        })
      : [];
  }, [rawFilters, searchText, searchField.key]);

  const {
    refetch,
    loading,
    data: { workspaceVehicles = [], vehiclesCount = 0 } = {},
  } = useQuery(vehicleQuery, {
    variables: {
      options: {
        page,
        pageSize,
        sort: [
          {
            field: sortBy,
            order: isAscending ? 'asc' : 'desc NULLS LAST',
          },
        ],
        filters: [
          ...filters,
          { field: 'workspaceId', operator: 'eq', value: [`${workspace?.id}`] },
        ],
      },
    },
  });

  const userOptions = useMemo(() => {
    const userOptions = allWorkspaceUsers.map((p) => ({
      label: `${p.firstName} ${p.lastName}`,
      value: p.id,
    }));
    return userOptions;
  }, [allWorkspaceUsers]);

  const typeOptions = useMemo(() => {
    const vehicleTypes = Object.values(vehicles).map((type) => ({
      label: t(keys.assets[assetTypeStringLookup[type.string]]),
      value: type.string,
    }));

    return vehicleTypes;
  }, [t]);

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: '',
        enableSorting: false,
        enableColumnFilter: false,
        size: 50,
        grow: false,
        Cell: ({ row }) => {
          const vehicle = row.original;
          const alert = showAssetAlarmClassname({ vehicle });
          const noAlert = alert === 'hide';
          return (
            <Icon
              className={noAlert ? undefined : styles[alert]}
              style={{
                opacity: noAlert ? 0.3 : 1,
                fontSize: '1.25rem',
              }}
            >
              {noAlert ? assetTypeIcons[row.original.type] : 'error'}
            </Icon>
          );
        },
      },
      {
        accessorKey: 'type',
        header: t(keys.common.TYPE),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: typeOptions,
        Cell: ({ cell }) => (
          <Text noMargin size="sm" weight="semiBold">
            {t(getTranslationKey(cell.getValue(), 'assets')) || cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'subtype',
        header: t(keys.common.SUBTYPE),
        minSize: 150,
        grow: true,
        Cell: ({ cell }) => (
          <Text noMargin size="sm" weight="semiBold">
            {t(getTranslationKey(cell.getValue(), 'assets')) || cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'make',
        header: t(keys.assets.MAKE),
        minSize: 150,
        grow: true,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'model',
        header: t(keys.assets.MODEL),
        minSize: 150,
        grow: true,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'year',
        header: t(keys.assets.YEAR),
        minSize: 150,
        grow: true,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'unitNumber',
        header: t(keys.assets.UNIT_NUMBER),
        minSize: 150,
        grow: true,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'location',
        header: t(keys.assets.LOCATION),
        minSize: 150,
        grow: true,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        minSize: 150,
        grow: true,
        Cell: ({ cell }) =>
          !!cell.getValue() ? (
            labelCell({
              name:
                t(getTranslationKey(cell.getValue(), 'assets')) ||
                cell.getValue(),
              color: asseetColorStringLookup[cell.getValue()] || 'grey',
            })
          ) : (
            <Text noMargin size="sm" color="secondary">
              {t(keys.common.NONE)}
            </Text>
          ),
      },
      {
        accessorKey: 'assignee',
        minSize: 150,
        grow: true,
        header: t(keys.common.ASSIGNEE),
        enableSorting: false,
        Cell: ({ cell }) => {
          return <SimpleUserCard size="sm" user={cell.getValue()} />;
        },
        filterVariant: 'select',
        filterSelectOptions: userOptions,
      },
      {
        accessorKey: 'lastInspectionDate',
        header: t(keys.assets.LAST_INSPECTION),
        minSize: 350,
        grow: true,
        filterVariant: 'date-range',
        Cell: ({ cell }) => dateCell({ cell }),
      },
      {
        accessorKey: 'endOfLifeHours',
        header: t(keys.assets.END_OF_LIFE_HOURS),
        grow: true,
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'endOfLife',
        header: t(keys.assets.END_OF_LIFE_DATE),
        minSize: 350,
        grow: true,
        filterVariant: 'date-range',
        Cell: ({ cell }) => dateCell({ cell }),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        minSize: 350,
        grow: true,
        filterVariant: 'date-range',
        Cell: ({ cell }) => dateCell({ cell }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        minSize: 150,
        grow: true,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userOptions,
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
    ],
    [t, typeOptions, userOptions, textCell, labelCell, dateCell],
  );

  useEffect(() => {
    if (!tableView) {
      setPageSize(10);
      setPage(1);
    }
  }, [tableView]);

  return (
    <div
      className={classNames(
        styles.rootContainer,
        disabled ? styles.disabled : null,
      )}
    >
      <div className={styles.headerContainer}>
        <Text size="lg" weight="bold" noMargin>
          {t(keys.common.ASSETS)}
        </Text>
        {isWorkspaceAdmin && (
          <Button
            variant="primary"
            testId="asset"
            icon="add"
            size="md"
            value={t(keys.action.CREATE)}
            onClick={() =>
              navigate(getRoute(workspace?.id, paths.asset, 'create'))
            }
          />
        )}
      </div>
      <div className={styles.sort}>
        <div>
          {!tableView && (
            <AssetFiltering
              filters={filters}
              setFilters={setRawFilters}
              rawFilters={rawFilters}
              filterOptions={vehicleFilterOptions}
              setPage={setPage}
              assetType="Asset"
            />
          )}
        </div>
        <div className={styles.toggleSortContainer}>
          <div className={styles.toggleContainer}>
            <Icon
              className={styles.toggleList}
              onClick={() => {
                setRawFilters({});
                setTableView(!tableView);
              }}
            >
              {tableView ? 'apps' : 'list'}
            </Icon>
          </div>
          {!tableView && (
            <TableSorting
              setSortBy={setSortBy}
              sortBy={sortBy}
              setIsAscending={setIsAscending}
              refetch={refetch}
              isAscending={isAscending}
              sortingOptions={sortingOptions}
              sortStrings={sortVehicleStrings}
            />
          )}
        </div>
      </div>
      {!tableView && (
        <div className={styles.searchContainer}>
          <div
            className={styles.searchIcon}
            onClick={() => {
              if (!searchOn) {
                setSearchOn(true);
              } else {
                setSearchField(searchSelectorItems[0]);
                setSearchText('');
                setSearchOn(false);
              }
            }}
          >
            <Icon>{searchOn ? 'search_off' : 'search'}</Icon>
          </div>
          {searchOn && (
            <div className={styles.searchbarContainer}>
              <Searchbar
                className={styles.searchbar}
                onChange={setSearchText}
                value={searchText}
                debounce={true}
              />
              <TypeSelectorDropdown
                items={searchSelectorItems}
                onChange={setSearchField}
                selected={searchField}
                noMargin
              />
            </div>
          )}
        </div>
      )}
      <div className={styles.container}>
        {tableView ? (
          <div className={styles.table}>
            <TablePrototype
              isLoading={loading}
              data={workspaceVehicles || []}
              columns={tableColumns}
              count={vehiclesCount}
              page={page}
              rightClickNavigation={paths.asset}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setRawFilters={setRawFilters}
              setSortBy={setSortBy}
              setIsAscending={setIsAscending}
              onRowClick={(vehicle) =>
                navigate(getRoute(workspace?.id, paths.asset, vehicle.id), {
                  state: { isEditing: false },
                })
              }
              emptyState={{
                title: t(keys.action.NOT_FOUND, {
                  variable: t(keys.common.ASSETS),
                }),
                text:
                  filters.length > 2
                    ? t(keys.action.EMPTY_STATE_CHECK_FILTERS, {
                        variable: t(keys.common.ASSETS),
                      })
                    : t(keys.action.EMPTY_STATE_MESSAGE, {
                        variable: t(keys.common.ASSETS),
                      }),
                image: noVehicles,
              }}
            />
          </div>
        ) : workspaceVehicles?.length ? (
          workspaceVehicles.map((vehicle) => (
            <VehicleCard
              key={vehicle.id}
              className={styles.vehicleCard}
              vehicle={vehicle}
              onClick={() =>
                navigate(getRoute(workspace?.id, paths.asset, vehicle.id), {
                  state: {
                    isEditing: false,
                  },
                })
              }
            />
          ))
        ) : (
          <div className={styles.emptyStateContainer}>
            <EmptyStateView
              title={t(keys.action.NOT_FOUND, {
                variable: t(keys.common.ASSETS),
              })}
              text={
                rawFilters?.filterData?.length
                  ? t(keys.assets.EMPTY_STATE_FILTERS)
                  : t(keys.assets.EMPTY_STATE_WORKSPACE)
              }
              image={noVehicles}
            />
          </div>
        )}
      </div>
      {!tableView && (
        <TablePagination
          pageSize={pageSize}
          count={vehiclesCount}
          setPage={setPage}
          page={page}
        />
      )}
    </div>
  );
}
