import Modal from '../Modal';
import { useModal } from '../../../providers/ModalProvider';
import TypeSelectorDropdown from '../../dropdowns/TypeSelectorDropdown';
import DropdownCombo from '../../dropdowns/DropdownCombo';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import { Text } from '../../typography';
import UserMultiSelector from '../../UserMultiSelector';
import styles from './CreateAssessmentModal.module.scss';
import { useInspection } from '../../../hooks/offline-hooks/createInspectionhook';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../../constants/strings';
import { paths } from '../../../constants/strings';
import { useEffect } from 'react';
import { modals } from '../../../providers/modals';
import { dropdownTypes } from '../../dropdowns/dropdown';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import { Checkbox } from '@mui/material';
import { useChangeWorkspace } from '../../../hooks/misc';
import { useTemplates } from '../../../providers/TemplateProvider';
import { useCurrentUser } from '../../../providers/UserProvider';

export default function CreateAssessmentModal() {
  const { modalState, closeModal, updateModal } = useModal();
  const { t } = useTranslation();
  const { workspaceId, workspaceVehicles } = useWorkspace();
  const { changeWorkspace } = useChangeWorkspace();
  const { templates } = useTemplates();
  const { user: currentUser } = useCurrentUser();

  const {
    show,
    modalTitle,
    types,
    timecardId,
    incidentId,
    asset,
    participants,
    template,
    type,
    dataWorkspaceId,
    private: privateAssessment,
    allWorkspaces,
  } = modalState.createAssessmentModal;

  const { addInspection } = useInspection();
  const navigate = useNavigate();

  const update = (variables) =>
    updateModal({
      modalName: modals.createAssessment,
      variables: { ...variables },
    });

  const onHide = () => {
    closeModal({ modalName: modals.createAssessment });
  };

  const submitDisabled =
    type.key === 'VEHICLE' ? !template?.id || !asset?.id : !template?.id;

  const optional = type.key !== 'VEHICLE';

  useEffect(() => {
    if (workspaceId !== dataWorkspaceId && show && !isNaN(dataWorkspaceId)) {
      changeWorkspace(dataWorkspaceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataWorkspaceId, show, workspaceId]);

  return (
    <Modal
      open={show || false}
      onClose={onHide}
      title={modalTitle || t(keys.assessments.START_ASSESSMENT)}
      submitText={t(keys.action.START)}
      hideCancel
      submitDisabled={submitDisabled}
      onSubmit={() => {
        addInspection(
          template?.id,
          asset?.id,
          type.key,
          participants?.map((user) => user.id) || [],
          allWorkspaces ? null : dataWorkspaceId || workspaceId,
          incidentId,
          timecardId,
          privateAssessment,
        ).then(
          ({
            data: {
              addInspection: { id },
            },
          }) => {
            navigate(
              getRoute(
                dataWorkspaceId ? dataWorkspaceId : workspaceId,
                paths.assessment,
                id,
              ),
            );
          },
        );
        onHide();
      }}
    >
      <div className={styles.modalContent}>
        <TypeSelectorDropdown
          onChange={(newType) => {
            update({ type: newType, asset: null, template: null });
          }}
          selected={type}
          items={types.map((t, index) => ({
            title: t.title,
            icon: t.icon,
            color: t.color,
            key: t.key,
            id: index,
          }))}
          type="Type"
        />
        <DropdownCombo
          type={dropdownTypes.ASSET}
          title={t(keys.common.ASSET)}
          items={workspaceVehicles}
          onChange={(newAsset) => {
            update({ asset: newAsset });
          }}
          selected={asset}
          optional={optional}
          onRemove={() => update({ asset: null })}
          preventStateChange
          required={type.key === 'VEHICLE'}
          highlight={type.key === 'VEHICLE'}
        />
        <DropdownCombo
          type={dropdownTypes.TEMPLATE}
          title={t(keys.common.TEMPLATE)}
          highlight
          preventStateChange
          items={templates || []}
          onChange={(newTemplate) => {
            update({ template: newTemplate });
          }}
          onRemove={() => update({ template: null })}
          selected={template}
        />
        <Text weight="semiBold" className={styles.title}>
          {t(keys.common.PARTICIPANTS)}
        </Text>
        <UserMultiSelector
          selected={participants}
          type={allWorkspaces ? 'all' : 'users'}
          onUserAdded={(user) => {
            update({ participants: [...participants, user] });
          }}
          onUserRemoved={(user) => {
            update({
              participants: participants.filter((u) => u.id !== user.id),
            });
          }}
        />
        <br />
        <div className={styles.checkboxes}>
          <div className={styles.privateContainer}>
            <Text size="sm" weight="semiBold" color="secondary" noMargin>
              {t(keys.templates.ALL_WORKSPACES)}
            </Text>
            <Checkbox
              onChange={(e) => {
                update({ allWorkspaces: e.target.checked });
                update({ participants: [currentUser] });
              }}
            />
          </div>
          <div className={styles.privateContainer}>
            <Text size="sm" weight="semiBold" color="secondary" noMargin>
              {t(keys.utilities.PRIVATE)}
            </Text>
            <Checkbox onChange={(e) => update({ private: e.target.checked })} />
          </div>
        </div>
        {privateAssessment && (
          <Text textAlign="right" size="sm" noMargin>
            {t(keys.utilities.PRIVATE_DISCLAIMER)}
          </Text>
        )}
        {allWorkspaces && (
          <Text textAlign="right" size="sm" noMargin>
            {t(keys.utilities.ALL_WORKSPACE_DISCLAIMER)}
          </Text>
        )}
      </div>
      <br />
    </Modal>
  );
}
