import React, { useRef, useState, useCallback, useEffect, memo } from 'react';
import SignaturePad from 'react-signature-canvas';
import styles from './SignatureCanvas.module.scss';
import Button from './Button';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useInspection } from '../hooks/offline-hooks/createInspectionhook';
import { keys } from '../utilities/translator/translation_keys.js';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import Text from './typography/Text.jsx';

const SignatureField = memo(
  ({ onSubmit, disabled, signatureText, setSignatureText, onSigned }) => {
    const signatureRef = useRef(null);
    const { t } = useTranslation();
    const [signatureData, setSignatureData] = useState(null); // Store the drawn signature data

    const resizeCanvas = useCallback(() => {
      const canvas = signatureRef.current.getCanvas();
      // Ensure the ratio is updated for high DPI screens
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d').scale(ratio, ratio);

      // Resize can happen on init or window resize, only restore if signatureData is set
      if (signatureData) {
        signatureRef.current.fromData(signatureData);
      }
    }, [signatureData]);

    useEffect(() => {
      window.addEventListener('resize', resizeCanvas);
      resizeCanvas(); // Adjust canvas size initially
      return () => {
        window.removeEventListener('resize', resizeCanvas);
      };
    }, [resizeCanvas]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => resizeCanvas(), []);

    const clearSignature = () => {
      signatureRef.current.clear();
      setSignatureData(null); // Reset the stored signature data
      setSignatureText('');
      onSigned(true);
    };

    const saveSignature = () => {
      if (!signatureRef.current.isEmpty() && signatureText?.length) {
        signatureRef.current.getCanvas().toBlob(
          (blob) => {
            const fileUUID = uuidv4();
            const file = new File([blob], `${fileUUID}.png`);
            onSubmit(signatureText, file);
            clearSignature();
          },
          'image/png',
          0.4,
        );
      }
    };

    const handleSignature = () => {
      const data = signatureRef.current.toData();
      setSignatureData(data);
      onSigned(false);
    };

    return (
      <div className={styles.signatureField}>
        <div className={styles.signatureCanvas}>
          <SignaturePad
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              className: styles.signaturePadCanvas,
            }}
            onEnd={handleSignature}
            disabled={disabled}
          />
        </div>
        <div className={styles.buttonDisplay}>
          <Button
            value={t(keys.action.CLEAR)}
            size="sm"
            variant="secondary"
            onClick={clearSignature}
          />
          <Button
            value={t(keys.action.SUBMIT)}
            disabled={
              disabled ||
              signatureRef.current?.isEmpty() ||
              !signatureText?.length
            }
            size="sm"
            onClick={saveSignature}
          />
        </div>
      </div>
    );
  },
);

export default function SignatureCanvas({
  onSubmit,
  disabled = false,
  originalAdditional,
}) {
  const { addImageResults } = useInspection();
  const [isSigned, setIsSigned] = useState(disabled);
  const [signatureText, setSignatureText] = useState('');

  const handleSubmitSignature = useCallback(
    (text, file) => {
      addImageResults('', onSubmit, file, {
        signatureText: text,
        originalAdditional,
      });
      setIsSigned(false);
    },
    [addImageResults, onSubmit, originalAdditional],
  );

  return (
    <div className={styles.container}>
      <Text noMargin weight="semiBold">
        {t(keys.assessments.FULL_NAME)} <span className={styles.red}>*</span>
      </Text>
      <Form.Control
        size="sm"
        required
        placeholder={t(keys.action.ENTER, { variable: t(keys.common.NAME) })}
        type="text"
        value={signatureText}
        onChange={(e) => setSignatureText(e.target.value)}
        className={styles.nameInput}
      />
      <Text noMargin weight="semiBold">
        {t(keys.common.SIGNATURE)} <span className={styles.red}>*</span>
      </Text>
      <SignatureField
        onSubmit={handleSubmitSignature}
        disabled={isSigned}
        signatureText={signatureText}
        setSignatureText={setSignatureText}
        onSigned={setIsSigned}
      />
    </div>
  );
}
