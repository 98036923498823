import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useNavigate } from 'react-router-dom';
import React, { useState, useMemo } from 'react';
import { paths } from '../../constants/strings.js';
import { Icon } from '@mui/material';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { getRoute } from '../../constants/strings.js';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useTableComponents } from '../../components/tables/MTableComponents.jsx';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents.jsx';
import { useQueryParams } from '../../hooks/misc.js';
import MTTable from '../../components/tables/MTable.jsx';
import { NetworkStatus } from '@apollo/client';
import { useURLParams } from '../../providers/URLParamProvider.jsx';

const templatesQuery = loader('./IncidentTemplateList.graphql');

export default function IncidentTemplates() {
  const { workspace } = useWorkspace();
  const { page, pageSize, filters, sort } = useURLParams();
  const { t } = useTranslation();
  const { textCell, userCell, userFilterOptions, dateCell, labelCell } =
    useTableComponents();
  const { getParam } = useQueryParams();
  const [dateSelectors, setDateSelectors] = useState({
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
  });

  const navigate = useNavigate();
  const {
    networkStatus,
    previousData: { taskCount: oldCount } = {},
    data: { inspectionTemplates = [], inspectionTemplatesCount = 0 } = {},
  } = useQuery(templatesQuery, {
    skip: !page || !pageSize || !sort,
    variables: {
      options: {
        page,
        pageSize,
        sort,
        filters: [
          ...filters,
          { field: 'templateType', operator: 'eq', value: 'INCIDENT' },
          { field: 'isArchived', operator: 'eq', value: ['false'] },
        ],
      },
    },
  });
  const columns = useMemo(
    () => [
      {
        accessorKey: 'templateType',
        header: undefined,
        enableSorting: false,
        size: 50,
        enableColumnFilter: false,
        Cell: () => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              fontSize: '1.25rem',
              color: 'red',
            }}
          >
            fmd_bad
          </Icon>
        ),
      },
      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        enableSorting: false,
        Cell: ({ cell }) => userCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions('all'),
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        filterVariant: 'select',
        size: 120,
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.DRAFT) },
          { value: 'false', label: t(keys.common.PUBLISHED) },
        ],
        Cell: ({ cell }) =>
          labelCell({
            name: !!cell.getValue()
              ? t(keys.common.DRAFT)
              : t(keys.common.PUBLISHED),
            color: !!cell.getValue() ? 'yellow' : 'green',
          }),
      },
      {
        accessorKey: 'revisionNumber',
        header: t(keys.common.REV),
        grow: true,
        size: 120,
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        Cell: ({ cell }) => dateCell({ cell }, { dateOnly: true }),
        size: 275,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'analysisRequested',
        header: t(keys.templates.SECOND_SIGHT),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.YES) },
          { value: 'false', label: t(keys.common.NO) },
        ],
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              color: cell.getValue() ? '#28a745' : '#6c757d',
              fontSize: '1.25rem',
            }}
          >
            {!!cell.getValue() ? 'check' : 'close'}
          </Icon>
        ),
      },
      {
        accessorKey: 'reviewRequired',
        header: t(keys.templates.APPROVAL_REQUIRED),
        minSize: 150,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.YES) },
          { value: 'false', label: t(keys.common.NO) },
        ],
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              color: cell.getValue() ? '#28a745' : '#6c757d',
              fontSize: '1.25rem',
            }}
          >
            {!!cell.getValue() ? 'check' : 'close'}
          </Icon>
        ),
      },
    ],
    [
      dateCell,
      dateSelectors,
      labelCell,
      t,
      textCell,
      userCell,
      userFilterOptions,
    ],
  );

  return (
    <MTTable
      isLoading={networkStatus === NetworkStatus.loading}
      data={inspectionTemplates || []}
      columns={columns}
      rowCount={inspectionTemplatesCount || oldCount}
      onRowClick={(template) =>
        navigate(getRoute(workspace?.id, paths.templates, template.id))
      }
    />
  );
}
