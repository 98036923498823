import styles from './ToolboxTalkGenerator.module.scss';
import { Text } from '../../components/typography';
import { Form } from 'react-bootstrap';
import { Button } from '../../components';
import RichTextEditor from '../../components/rich_text/RichTextEditor';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  getRandomTopic,
  toolboxTalkContentTypes,
} from '../../utilities/toolboxtalks';
import Modal from '../../components/modals/Modal';
import { LinearProgress } from '@mui/material';
import Icon from '../../components/Icon';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { showToast } from '../../graphql/cache/modal';
import Bugsnag from '@bugsnag/js';
import PdfViewer from '../../components/modals/file_viewer/PdfViewer';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import debounce from 'lodash.debounce';
import ToolboxTalk from './ToolboxTalk';
import { toastLength } from '../../constants/misc';
import ImageDropzone from '../../components/image_uploads/ImageDropzone';
import { useIsMobile } from '../../hooks/misc';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';

const updateToolboxTalkMutation = loader(
  './ToolboxTalkGenerator.update.graphql',
);
const deleteToolboxTalkMutation = loader(
  './ToolboxTalkGenerator.delete.graphql',
);
const toolboxTalkQuery = loader('./ToolboxTalkGenerator.fetch.graphql');

const generateContentMutation = loader(
  './ToolboxTalkGenerator.content.graphql',
);
const UPDATE_DEBOUNCE = 1000;

export default function ToolboxTalkGenerator() {
  const { id } = useParams();
  const tbtId = parseInt(id);
  const navigate = useNavigate();
  const [updateToolboxTalk] = useMutation(updateToolboxTalkMutation);
  const [deleteToolboxTalk] = useMutation(deleteToolboxTalkMutation);
  const [isGenerating, setIsGenerating] = useState(false);
  const [state, setState] = useState({});
  const [error, setError] = useState(undefined);
  const { openModal, openConfirmationModal } = useModal();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const [generateContent, { loading }] = useMutation(generateContentMutation, {
    onError: (error) => {
      setError(error);
      Bugsnag.notify('Error on TBT Content Generation', error);
    },
  });
  const { data: { toolboxTalks: [toolboxTalk] = [{}] } = {} } = useQuery(
    toolboxTalkQuery,
    {
      skip: !tbtId,
      variables: {
        options: {
          filters: [{ field: 'id', operator: 'eq', value: `${tbtId}` }],
        },
      },
      onCompleted: (data) => {
        setState(data?.toolboxTalks?.[0] ?? {});
      },
    },
  );

  const {
    title,
    description,
    content,
    contentType,
    fileUrl,
    isDraft,
    imageUrl,
  } = toolboxTalk || {};

  const ready = useMemo(() => {
    return (
      state.title?.length > 10 &&
      contentType === toolboxTalkContentTypes.RICH_TEXT &&
      !isGenerating &&
      !loading
    );
  }, [contentType, isGenerating, loading, state?.title]);

  useEffect(() => {
    if (!!state.title && state.title?.length < 10) {
      setError(t(keys.toolbox.TOPIC_10_CHAR));
    } else {
      setError('');
    }
  }, [state, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRichText = useCallback(
    debounce((newState) => {
      updateToolboxTalk(
        { variables: { id: tbtId, content: newState } },
        UPDATE_DEBOUNCE,
      );
    }, UPDATE_DEBOUNCE),
    [],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateToolboxTalk = useCallback(
    debounce((title) => {
      updateToolboxTalk({
        variables: { id: tbtId, title },
      });
    }, 500),
    [updateToolboxTalk, tbtId],
  );
  useEffect(() => {
    if (state.title) {
      debouncedUpdateToolboxTalk(state.title);
    }
  }, [state.title, debouncedUpdateToolboxTalk]);

  return !isDraft ? (
    <ToolboxTalk
      toolboxTalk={toolboxTalk}
      deleteToolboxTalk={deleteToolboxTalk}
    />
  ) : (
    <div className={styles.page}>
      <div className={styles.header}>
        <Text size="lg" weight="bold">
          {t(keys.toolbox.TBT_GENERATOR)}
        </Text>
      </div>
      <Text weight="semiBold" color="secondary">
        {t(keys.toolbox.TBT_GENERATOR_MESSAGE)}
      </Text>
      <div className={styles.content}>
        <div className={styles.topLeft}>
          <div className={styles.header}>
            <Text noMargin weight="semiBold">
              {t(keys.toolbox.ENTER_TOPIC)}
            </Text>
            {contentType !== toolboxTalkContentTypes.RICH_TEXT ? (
              <Icon
                hover
                color="blue"
                onClick={() => {
                  openConfirmationModal({
                    title: t(keys.toolbox.CLEAR_TOOLBOX_TALK),
                    variant: 'warning',
                    description: t(keys.toolbox.CLEAR_TBT_MESSAGE),
                    onSubmit: () => {
                      updateToolboxTalk({
                        variables: {
                          id: tbtId,
                          title: null,
                          description: null,
                          contentType: toolboxTalkContentTypes.RICH_TEXT,
                          fileUrl: null,
                          risks: null,
                          conclusion: null,
                          mitigation: null,
                        },
                      });
                    },
                  });
                }}
              >
                loop
              </Icon>
            ) : (
              <Text
                color="accentPrimary"
                noMargin
                hover
                weight="semiBold"
                onClick={() => {
                  const newTitle = getRandomTopic();
                  updateToolboxTalk({
                    variables: { id: tbtId, title: newTitle },
                  });
                }}
              >
                {t(keys.toolbox.RANDOM_TOPIC)}
              </Text>
            )}
          </div>
          <Form.Control
            value={state.title || ''}
            onChange={(e) => {
              setState({ ...state, title: e.target.value });
            }}
            disabled={contentType === toolboxTalkContentTypes.AI}
            isInvalid={!!error}
          />
          {error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          )}

          <div className={styles.header}>
            <Text noMargin weight="semiBold">
              {t(keys.action.ENTER, { variable: t(keys.common.DESCRIPTION) })}
            </Text>
            <div
              className={styles.generate}
              onClick={() => {
                if (ready) {
                  setIsGenerating(true);
                  generateContent({
                    variables: { id: tbtId, title, description },
                  }).then((data) => {
                    if (!!data.errors) {
                      showToast({
                        title: t(keys.toolbox.ERROR_GENERATING_CONTENT),
                        variant: 'danger',
                        length: toastLength.lg,
                      });
                      setError(
                        t(keys.toolbox.ERROR_GENERATING_CONTENT_MESSAGE),
                      );
                    }
                    setIsGenerating(false);
                  });
                }
              }}
            >
              <Text
                noMargin
                color={ready ? 'accentPrimary' : 'secondaryLight'}
                weight="semiBold"
              >
                {t(keys.toolbox.GENERATE_CONTENT)}
              </Text>
              <Icon color={ready ? 'blue' : 'secondaryLight'}>
                auto_fix_high
              </Icon>
            </div>
          </div>
          <Form.Control
            as="textarea"
            value={state.description || ''}
            rows={4}
            onChange={(e) => {
              setState({ ...state, description: e.target.value });
            }}
            onBlur={() =>
              updateToolboxTalk({
                variables: { id: tbtId, description: state.description },
              })
            }
          />
        </div>
        {imageUrl ? (
          <div className={styles.imageContainer}>
            <Icon
              hover
              className={styles.imageIcon}
              align="center"
              color="white"
              onClick={() =>
                updateToolboxTalk({
                  variables: { id: tbtId, imageUrl: null },
                })
              }
            >
              delete
            </Icon>
            <img src={imageUrl} alt="tbt" className={styles.image} />
          </div>
        ) : (
          <ImageDropzone
            onSubmit={(imageUrl) =>
              updateToolboxTalk({ variables: { id: tbtId, imageUrl } })
            }
          />
        )}
      </div>
      {contentType === toolboxTalkContentTypes.AI && (
        <div className={styles.contentContainer}>
          <Text weight="semiBold" noMargin>
            {t(keys.dashboard.RISKS)}
          </Text>
          <Form.Control
            as="textarea"
            rows={3}
            value={state.risks || ''}
            onChange={(e) => setState({ ...state, risks: e.target.value })}
            onBlur={(e) =>
              updateToolboxTalk({
                variables: { id: tbtId, risks: e.target.value },
              })
            }
          />
          <Text noMargin weight="semiBold">
            {t(keys.dashboard.MITIGATION)}
          </Text>
          <Form.Control
            as="textarea"
            rows={5}
            value={state.mitigation || ''}
            onChange={(e) => setState({ ...state, mitigation: e.target.value })}
            onBlur={(e) =>
              updateToolboxTalk({
                variables: { id: tbtId, mitigation: e.target.value },
              })
            }
          />
          <Text noMargin weight="semiBold">
            {t(keys.dashboard.CONCLUSION)}
          </Text>
          <Form.Control
            as="textarea"
            rows={3}
            value={state.conclusion || ''}
            onChange={(e) => setState({ ...state, conclusion: e.target.value })}
            onBlur={(e) =>
              updateToolboxTalk({
                variables: { id: tbtId, conclusion: e.target.value },
              })
            }
          />
        </div>
      )}
      {contentType === toolboxTalkContentTypes.FILE && (
        <div className={styles.filePreview}>
          {fileUrl.split('.').pop() === 'pdf' ? (
            <PdfViewer file={{ url: fileUrl }} width={1000} />
          ) : (
            <img src={fileUrl} alt="TBT" />
          )}
        </div>
      )}
      {contentType === toolboxTalkContentTypes.RICH_TEXT && (
        <div className={styles.richText}>
          <RichTextEditor
            key={id}
            id={id}
            value={content}
            topBarComponent={
              <Button
                outlined
                className={styles.importButton}
                value={
                  !isMobile
                    ? t(keys.action.IMPORT, { variable: null })
                    : undefined
                }
                icon="upload"
                disabled={contentType === toolboxTalkContentTypes.AI}
                onClick={() => {
                  openModal({
                    modalName: modals.fileUpload,
                    variables: {
                      accept: '.pdf,.png,.jpg,.jpeg',
                      onSubmit: ({ url }) => {
                        updateToolboxTalk({
                          variables: {
                            id: tbtId,
                            fileUrl: url,
                            contentType: toolboxTalkContentTypes.FILE,
                          },
                        });
                      },
                      simple: true,
                    },
                  });
                }}
              />
            }
            onChange={(newState) => {
              handleRichText(newState);
            }}
          />
        </div>
      )}
      <div className={styles.buttons}>
        <Button
          value={t(keys.action.DELETE)}
          variant="danger"
          onClick={() => {
            openConfirmationModal({
              title: t(keys.action.DELETE_VARIABLE, {
                variable: t(keys.toolbox.TOOLBOX_TALK),
              }),
              description: t(keys.action.DELETE_CONFIRMATION, {
                variable: t(keys.toolbox.TOOLBOX_TALK),
              }),
              variant: 'danger',
              onSubmit: () =>
                deleteToolboxTalk({ variables: { id: tbtId } }).then(() =>
                  navigate(`/${paths.toolbox}`),
                ),
            });
          }}
        />
        <Button
          value={t(keys.action.PUBLISH)}
          disabled={!title}
          onClick={() =>
            openConfirmationModal({
              title: t(keys.toolbox.PUBLISH_TBT),
              description: t(keys.toolbox.PUBLISH_TBT_MESSAGE),
              variant: 'warning',
              onSubmit: () =>
                updateToolboxTalk({
                  variables: { id: tbtId, isDraft: false },
                }).then(() => {
                  showToast({
                    title: t(keys.toolbox.TBT_PUBLISHED),
                    message: t(keys.toolbox.TBT_PUBLISHED_MESSAGE),
                  });
                }),
            })
          }
        />
      </div>
      <Modal
        open={isGenerating}
        title={t(keys.toolbox.THINKING)}
        hideSubmit
        allowClose={!loading}
        hideCancel
      >
        <LinearProgress className={styles.progress} />
        <br />
        <Text>{t(keys.toolbox.THINKING_MESSAGE)}</Text>
      </Modal>
    </div>
  );
}
