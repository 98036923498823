import React from 'react';
import styles from './InspectionCard.module.scss';
import { Text } from '../../typography';
import LabelsDisplay from '../../LabelsDisplay';
import { getLocalTime } from '../../../utilities/time';
import MultiAvatar from '../../MultiAvatar';
import Icon from '../../Icon';
import { assessmentTypes } from '../../../constants/strings';

export default function InspectionCard({ inspection, onClick }) {
  const {
    vehicle,
    template: { templateType, title },
  } = inspection;

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.topContainer}>
        <div className={styles.horizontalSplit}>
          <div className={styles.leftContainer}>
            <Text noSelect size="md" weight="bold" noMargin>
              {title}
            </Text>
          </div>
          <Text
            noSelect
            size="md"
            weight="semiBold"
            color="accentPrimary"
            noMargin
          >
            {vehicle?.unitNumber || ''}
          </Text>
        </div>
      </div>
      <div className={styles.middleContainer}>
        <Icon
          color={
            templateType === 'VEHICLE'
              ? assessmentTypes['fleet'].color
              : assessmentTypes[templateType.toLowerCase()]?.color
          }
        >
          {templateType === 'VEHICLE'
            ? assessmentTypes['fleet'].icon
            : assessmentTypes[templateType.toLowerCase()]?.icon}
        </Icon>
        <LabelsDisplay labels={inspection.labels} />
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.horizontalSplit}>
          <Text
            noSelect
            size="sm"
            color="secondary"
            noMargin
            weight="semiBold"
          >{`Created ${getLocalTime(inspection.dateCreated).fromNow()} by ${
            inspection.creator?.firstName
          } ${inspection.creator?.lastName}`}</Text>
          <MultiAvatar
            className={styles.avatars}
            users={inspection.participants}
          />
        </div>
      </div>
    </div>
  );
}
