import React, { useState, useEffect, createContext, useContext } from 'react';
import pjson from '../../package.json';
import {
  ApolloProvider,
  ApolloLink,
  ApolloClient,
  HttpLink,
  from,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { handleErrors } from './errors/errorHandler';
import { cache } from './cache';
import { apiEndpoints } from '../constants/endpoints';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { isProd } from '../utilities';
import Bugsnag from '@bugsnag/js';
import { useAuth0 } from '../providers/Auth0Provider';
import { setContext } from '@apollo/client/link/context';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import CustomSpinner from '../components/CustomSpinner';
import { getMainDefinition } from '@apollo/client/utilities';
import { paths } from '../constants/strings';
import localforage from 'localforage';

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  },
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const uri = isProd ? apiEndpoints.prod : apiEndpoints.dev;

const httpLink = new HttpLink({
  uri,
});

const errorLink = onError((error) => {
  Bugsnag.notify(error);
  handleErrors(error);
});

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${uri}?${operation.operationName}`,
  }));
  return forward(operation);
});

const ApolloContext = createContext();

export function useApolloContext() {
  return useContext(ApolloContext);
}

function ApiProvider({ children }) {
  const { getAccessTokenSilently, isLoading, logout } = useAuth0();
  const [client, setClient] = useState();
  const [isInitializing, setIsInitializing] = useState(false);

  const newPersistor = new CachePersistor({
    cache,
    storage: new LocalForageWrapper(localforage),
    debug: !isProd,
    trigger: 'write',
    maxSize: false,
  });

  useEffect(() => {
    async function init() {
      setIsInitializing(true);
      await newPersistor.restore();

      const authLink = setContext(async (_, { headers }) => {
        try {
          const token = await getAccessTokenSilently();
          const user = JSON.parse(localStorage.getItem('user') || '{}');

          return {
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : '',
              user_id: user?.id,
              user_company_id: user?.company?.id,
              'x-test-user-id-e838st765d-816d-40c8-8347-12141d39479a': user?.id
                ? user.id.toString()
                : '',
            },
          };
        } catch (e) {
          console.log({ e });
          return {
            headers: {
              ...headers,
            },
          };
        }
      });

      const wsUri = isProd ? apiEndpoints.prodWs : apiEndpoints.devWs;

      const wsLink = new GraphQLWsLink(
        createClient({
          url: wsUri,
          connectionParams: async () => {
            const token = await getAccessTokenSilently();
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            return {
              Authorization: token ? `Bearer ${token}` : '',
              user_id: user?.id,
              user_company_id: user?.company?.id,
            };
          },
        }),
      );

      const splitLink = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink,
      );

      const client = new ApolloClient({
        cache,
        link: from([errorLink, namedLink, authLink.concat(splitLink)]),
        name: pjson.name,
        version: pjson.version,
        defaultOptions,
        onError: (error) => {
          Bugsnag.notify(error);
          handleErrors(error);
        },
      });

      setClient(client);
      setIsInitializing(false);
    }

    init().catch((error) => {
      console.error('Error initializing Apollo Client:', error);
      setIsInitializing(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  const signOut = async () => {
    if (newPersistor) {
      newPersistor.pause();
      client.resetStore().then(() => {
        newPersistor.purge();
      });
    }
    cache.reset();
    sessionStorage.clear();
    localStorage.clear();
    localforage.clear();
    logout({ returnTo: `${window.location.origin}/${paths.login}` });
  };

  if (!client || isLoading || isInitializing) {
    return <CustomSpinner text={'Initializing App...'} />;
  }

  return (
    <ApolloContext.Provider
      value={{ client, persistor: newPersistor, signOut }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApolloContext.Provider>
  );
}

export default ApiProvider;
