import React from 'react';
import classNames from 'classnames';
import { Text } from '../typography';
import styles from './Label.module.scss';
import { Icon } from '@mui/material';

export default function Label({
  name,
  color,
  rowSized = true,
  className,
  canEdit = false,
  onClose = () => {},
  onClick = () => {},
}) {
  const textSize = rowSized ? 'xxxs' : 'sm';
  return (
    <div
      onClick={onClick}
      data-cy={`label-${name}`}
      className={classNames(
        styles.container,
        styles[color?.toLowerCase()],
        className,
        !rowSized && styles.sm,
      )}
    >
      <Text
        noMargin
        noSelect
        size={textSize}
        textAlign="center"
        color="white"
        weight="semibold"
        truncate
      >
        {name}
      </Text>
      {canEdit && (
        <Icon
          className={styles.closeIcon}
          fontSize="inherit"
          baseClassName="material-icons-outlined"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          close
        </Icon>
      )}
    </div>
  );
}
