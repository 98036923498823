import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { customColors } from '../../misc';

const textPrimary = '#1d1d1f';

const styles = StyleSheet.create({
  tableContainer: {
    width: '100%',
    flexDirection: 'column',
    marginTop: 3,
    marginBottom: 3,
    border: `1px solid ${textPrimary}`,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: `1px solid ${customColors.SECONDARY_LIGHT}`,
    flexWrap: 'wrap',
  },
  cell: {
    padding: 5,
    fontSize: 8,
    border: `0.5px solid ${customColors.SECONDARY_LIGHT}`,
    flexGrow: 1,
  },
});

export const Table = ({ question, answer }) => {
  const numberOfColumns = question?.additionalData?.split('|')?.length || 0;
  const columnWidthPercentage = 100 / numberOfColumns + '%';

  return (
    <View style={styles.tableContainer}>
      <View style={styles.tableRow}>
        {question?.additionalData?.split('|').map((str, index) => (
          <Text
            style={{
              ...styles.cell,
              width: columnWidthPercentage,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            key={`table-headers-${str}-${index}`}
          >
            {str}
          </Text>
        ))}
      </View>

      {answer?.value?.split('|').map((rowValue, rowIndex) => (
        <View key={`table-row-${rowIndex}`} style={styles.tableRow}>
          {rowValue?.split('\n').map((textValue, colIndex) => (
            <Text
              style={{ ...styles.cell, width: columnWidthPercentage }}
              key={`table-content-${rowIndex}-${colIndex}`}
            >
              {textValue}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};
