import styles from './RoleModal.module.scss';
import { Text } from '../../typography';
import Modal from '../Modal';
import { useModal } from '../../../providers/ModalProvider';
import { useTranslation } from 'react-i18next';
import { modals } from '../../../providers/modals';
import { keys } from '../../../utilities/translator/translation_keys';
import { Form } from 'react-bootstrap';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { showToast } from '../../../graphql/cache/modal';
import { toastVariant } from '../../../constants/misc';
import CreatableSelect from 'react-select/creatable';
import Button from '../../Button';
import Icon from '../../Icon';
import { useEffect, useRef, useState } from 'react';

const updateRoleMutation = loader(
  '../../../pages/role_requirement/RoleRequirementPage.updateRole.graphql',
);

const addRoleMutation = loader(
  '../../../pages/settings_pages/EditCompany.addNewRole.graphql',
);

const addRequirementMutation = loader(
  '../../../pages/role_requirement/RoleRequirementPage.addRequirement.graphql',
);

const roleByIdAllRequirementsQuery = loader(
  './RoleModal.getRoleByIdAndGetAllRequirements.graphql',
);

const associateRoleRequirementMutation = loader(
  '../../../pages/role_requirement/RoleRequirementPage.associateRoleRequirement.graphql',
);

const deleteRequirementRoleAssociationMutation = loader(
  '../../../pages/role_requirement/RoleRequirementPage.deleteRequirementRoleAssociation.graphql',
);

export default function RoleModal() {
  const { modalState, closeModal, updateModal } = useModal();
  const { t } = useTranslation();
  const [enableNewRequirement, setAddNewRequirement] = useState(false);

  const [updateRole] = useMutation(updateRoleMutation);
  const [addRole] = useMutation(addRoleMutation);
  const [addRequirement] = useMutation(addRequirementMutation);
  const [associateRoleRequirement] = useMutation(
    associateRoleRequirementMutation,
  );
  const [deleteRoleRequirementAssociation] = useMutation(
    deleteRequirementRoleAssociationMutation,
  );

  const roleTitleRef = useRef(null);
  const roleDescriptionRef = useRef(null);

  const update = (variables) =>
    updateModal({
      modalName: modals.role,
      variables: { ...variables },
    });

  const onHide = () => {
    closeModal({ modalName: modals.role });
    setEnableEditRoleDescription(false);
    setEnableEditRoleTitle(false);
    setAddNewRequirement(false);
  };

  const {
    roleId,
    show,
    roleTitle,
    roleDescription,
    requirementTitle,
    requirementDescription,
    readOnly,
  } = modalState.roleModal;

  const [enableEditRoleTitle, setEnableEditRoleTitle] = useState(false);
  const [enableEditRoleDescription, setEnableEditRoleDescription] =
    useState(false);

  const {
    refetch,
    data: { roles: [role] = [{}], requirements: allRequirements = [] } = {},
  } = useQuery(roleByIdAllRequirementsQuery, {
    variables: {
      getRoleId: `${roleId}`,
    },
    skip: !!!roleId,
    fetchPolicy: 'no-cache',
  });

  const convertToCreatableOptions = (requirements) => {
    return requirements.map((requirement) => ({
      value: requirement.id,
      label: requirement.title,
    }));
  };

  const findMissingById = (arr1, arr2) => {
    return arr1
      .filter((obj1) => !arr2.find((obj2) => obj2.id === obj1.id))
      .map((obj) => obj.id);
  };

  const onBlurRoleInput = () => {
    if (!!roleId) {
      updateRole({
        variables: {
          updateRoleId: roleId,
          title: roleTitle,
          description: roleDescription,
        },
      }).then(({ errors }) => {
        errors &&
          showToast({
            title: t(keys.common.ERROR),
            message: t(keys.tasks.ERROR_EXISTS),
            variant: toastVariant.warning,
          });
        showToast({
          title: 'Role Successfully Updated',
          message: `Role has been successfully Updated!`,
          variant: toastVariant.info,
        });
        setEnableEditRoleDescription(false);
        setEnableEditRoleTitle(false);
      });
    }
  };

  useEffect(() => {
    if (enableEditRoleTitle && !!roleTitleRef.current) {
      roleTitleRef.current.focus();
    }
    if (enableEditRoleDescription && !!roleDescriptionRef.current) {
      roleDescriptionRef.current.focus();
    }
  }, [enableEditRoleTitle, enableEditRoleDescription]);

  const roleEditableForm = () => {
    return (
      <div>
        <div>
          <div>
            <Text noMargin weight="semibold">
              {t(keys.common.DYNAMIC_TITLE, {
                variable: t(keys.common.ROLE),
              })}
            </Text>
          </div>
          <div className={styles.spacerSm} />
          {!!!roleId || enableEditRoleTitle ? (
            <div>
              <Form.Control
                required
                type="text"
                placeholder={t(keys.roles.ROLE_NAME_INPUT_PLACEHOLDER)}
                value={roleTitle}
                ref={roleTitleRef}
                onChange={(e) => {
                  update({
                    roleId,
                    show,
                    roleTitle: e.target.value,
                    roleDescription,
                    readOnly,
                  });
                }}
                onBlur={() => {
                  onBlurRoleInput();
                }}
                size="sm"
              />
            </div>
          ) : (
            <div className={styles.addRequirmentContainer}>
              <Text noMargin>{roleTitle}</Text>
              <Icon
                className={styles.editIcon}
                baseClassName="material-icons-outlined"
                onClick={() => setEnableEditRoleTitle(true)}
              >
                edit
              </Icon>
            </div>
          )}
          <div className={styles.spacerSm} />
          <div className={styles.editContainer}>
            <Text noMargin weight="semibold">
              {t(keys.common.DYNAMIC_DESCRIPTION, {
                variable: t(keys.common.ROLE),
              })}
            </Text>
          </div>
          <div className={styles.spacerSm} />
          {!!!roleId || enableEditRoleDescription ? (
            <Form.Control
              required
              rows={2}
              as="textarea"
              placeholder={t(keys.roles.ROLE_DESCRIPTION_INPUT_PLACEHOLDER)}
              value={roleDescription}
              ref={roleDescriptionRef}
              onChange={(e) => {
                update({
                  roleId,
                  show,
                  roleTitle,
                  roleDescription: e.target.value,
                  readOnly,
                });
              }}
              onBlur={() => {
                onBlurRoleInput();
              }}
              size="sm"
            />
          ) : (
            <div className={styles.addRequirmentContainer}>
              <Text noMargin>{roleDescription}</Text>
              <Icon
                className={styles.editIcon}
                baseClassName="material-icons-outlined"
                onClick={() => setEnableEditRoleDescription(true)}
              >
                edit
              </Icon>
            </div>
          )}
          <div className={styles.spacer} />
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={show || false}
      onClose={onHide}
      variant={'primary'}
      title={
        !!roleId
          ? t(keys.common.ROLE)
          : t(keys.action.CREATE_VARIABLE, { variable: t(keys.common.ROLE) })
      }
      subtitle={t(keys.roles.EDIT_ROLE_INFO)}
      hideCancel
      titleImage={
        <img
          className={styles.taskImage}
          src={'https://app.opasmobile.com/assets/graphics/role_graphic.png'}
          alt="task_png"
        />
      }
      submitText={roleId ? t(keys.action.UPDATE) : t(keys.action.SUBMIT)}
      hideSubmit={!!roleId}
      submitDisabled={
        !!!roleId
          ? roleTitle === ''
          : roleTitle === '' ||
            (!enableEditRoleDescription && !enableEditRoleTitle)
      }
      disableCloseOnSubmit={true}
      onSubmit={() => {
        addRole({
          variables: { title: roleTitle, description: roleDescription },
        }).then(
          ({
            data: {
              addRole: { id },
            },
          }) => {
            update({
              roleId: id,
              roleTitle,
              roleDescription,
            });
          },
        );
      }}
      className={styles.modalMinWidth}
    >
      <div className={styles.content}>
        {roleEditableForm()}
        {!!roleId && (
          <div>
            <div className={styles.titleContainer}>
              <div className={styles.leftTitle}>
                <div>
                  <Text noMargin noSelect size="lg" weight="semiBold">
                    {t(keys.roles.REQUIREMENTS_TITLE)}
                  </Text>
                  <Text noMargin color="secondary" size="sm" weight="semiBold">
                    {t(keys.roles.REQUIREMENTS_DESCRIPTION)}
                  </Text>
                </div>
              </div>
            </div>
            <div className={styles.spacerSm} />
            <CreatableSelect
              isMulti
              placeholder={t(keys.action.SEARCH, {
                variable: t(keys.roles.REQUIREMENTS_TITLE),
              })}
              getNewOptionData={() => {
                return false;
              }} // this is not to show the create option...
              options={convertToCreatableOptions(allRequirements)}
              value={
                role.requirements
                  ? convertToCreatableOptions(role.requirements)
                  : []
              }
              onChange={(selectedOptions) => {
                const updatedRequirements = selectedOptions.map((option) => ({
                  id: option.value,
                  title: option.label,
                }));
                if (updatedRequirements > role.requirements) {
                  associateRoleRequirement({
                    variables: {
                      roleId,
                      requirementId: updatedRequirements.at(-1).id,
                    },
                  }).then(() => {
                    refetch();
                  });
                } else if (updatedRequirements < role.requirements) {
                  const missingRequirementId = findMissingById(
                    role.requirements,
                    updatedRequirements,
                  );
                  deleteRoleRequirementAssociation({
                    variables: {
                      roleId: role.id,
                      requirementId: missingRequirementId[0],
                    },
                  }).then(() => {
                    refetch();
                  });
                }
              }}
            />
            <div className={styles.spacerSm} />
            <div className={styles.addRequirmentContainer}>
              <Text noMargin weight="semibold">
                {t(keys.roles.CREATE_REQUIREMENT)}
              </Text>
              <Icon
                data-cy="label-selector-append-label"
                className={styles.addIcon}
                onClick={() => {
                  setAddNewRequirement(!enableNewRequirement);
                }}
              >
                add
              </Icon>
            </div>
            <div className={styles.spacerSm} />
            {enableNewRequirement ? (
              <div>
                <Text noMargin weight="semibold">
                  {t(keys.common.DYNAMIC_TITLE, {
                    variable: t(keys.roles.REQUIREMENT),
                  })}
                </Text>
                <div className={styles.spacerSm} />
                <Form.Control
                  required
                  type="text"
                  placeholder={t(keys.roles.ROLE_NAME_INPUT_PLACEHOLDER)}
                  value={requirementTitle}
                  onChange={(e) => {
                    update({
                      roleId,
                      show,
                      requirementTitle: e.target.value,
                      requirementDescription,
                      roleTitle,
                      roleDescription,
                      readOnly,
                    });
                  }}
                  size="sm"
                />
                <div className={styles.spacerSm} />
                <Text noMargin weight="semibold">
                  {t(keys.common.DYNAMIC_DESCRIPTION, {
                    variable: t(keys.roles.REQUIREMENT),
                  })}
                </Text>
                <div className={styles.spacerSm} />
                <Form.Control
                  required
                  rows={2}
                  as="textarea"
                  placeholder={t(keys.roles.ROLE_DESCRIPTION_INPUT_PLACEHOLDER)}
                  value={requirementDescription}
                  onChange={(e) => {
                    update({
                      roleId,
                      show,
                      roleTitle,
                      roleDescription,
                      requirementTitle,
                      requirementDescription: e.target.value,
                      readOnly,
                    });
                  }}
                  size="sm"
                />
                <div className={styles.spacerSm} />
                <div className={styles.buttonDiv}>
                  <Button
                    outlined
                    variant="primary"
                    disabled={!!!requirementTitle}
                    onClick={async () => {
                      const {
                        data: { addRequirement: newRequirement },
                      } = await addRequirement({
                        variables: {
                          title: requirementTitle,
                          description: requirementDescription,
                        },
                      });

                      await associateRoleRequirement({
                        variables: {
                          roleId,
                          requirementId: newRequirement.id,
                        },
                      });
                      refetch();
                      await update({
                        roleId,
                        show,
                        requirementTitle: '',
                        requirementDescription: '',
                        roleTitle,
                        roleDescription,
                        readOnly,
                      });
                      refetch();
                    }}
                    value={t(keys.roles.CREATE_REQUIREMENT)}
                  />
                </div>

                <div className={styles.spacer} />
              </div>
            ) : null}
          </div>
        )}
      </div>
      <br />
    </Modal>
  );
}
