import styles from './EditCompany.module.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { Text } from '../../components/typography';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState, useMemo } from 'react';
import Icon from '../../components/Icon';
import { Button } from '../../components';
import { useModal } from '../../providers/ModalProvider';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { showToast } from '../../graphql/cache/modal';

const updateWorkspaceMutation = loader(
  '../../../src/pages/workspace/WorkspaceDetailsPage.updateWorkspace.graphql',
);

export default function EditCompanySettings({
  setCompany,
  company,
  workspaces,
  companyLocations,
}) {
  const { auditsAndCorrectiveActions } = useFlags();
  const { t } = useTranslation();
  const [location, setLocation] = useState('');
  const [locations, setLocations] = useState([]);
  const [updateWorkspace] = useMutation(updateWorkspaceMutation);

  const { openConfirmationModal } = useModal();

  useEffect(() => {
    if (!!companyLocations) {
      setLocations(companyLocations);
    }
  }, [companyLocations]);

  const handleAddLocation = () => {
    if (location.trim() === '') return;
    const updatedLocations = [...locations, location];
    setLocations(updatedLocations);
    setCompany({ ...company, locations: updatedLocations.join('|') });
    setLocation('');
  };

  const handleRemoveLocation = (indexToRemove) => {
    const updatedLocations = locations.filter(
      (_, index) => index !== indexToRemove,
    );
    setLocations(updatedLocations);
    setCompany({ ...company, locations: updatedLocations.join('|') });
  };

  const disableButton = useMemo(() => {
    const companyLoc = companyLocations.join('|');
    const allWorkspacesMatch = workspaces.every(
      (workspace) => workspace.locations === companyLoc,
    );
    return !companyLocations.length || allWorkspacesMatch;
  }, [companyLocations, workspaces]);

  return (
    <div>
      <div className={styles.header}>
        <Text size="lg" weight="semiBold" noMargin>
          Company Locations
        </Text>
        {!!locations?.length && (
          <Button
            size="sm"
            type="button"
            align="right"
            disabled={disableButton}
            value="Apply to all Workspaces"
            onClick={() => {
              openConfirmationModal({
                variant: 'warning',
                title: 'Set Locations for all Workspaces',
                description:
                  'Are you sure you want to set locations for all Workspaces?',
                onSubmit: () => {
                  for (let workspace of workspaces) {
                    updateWorkspace({
                      variables: {
                        id: workspace.id,
                        locations: locations.join('|'),
                      },
                    }).then(() =>
                      showToast({
                        title: 'All Workspaces Updated',
                        message:
                          'All Workspaces have been updated to use All Company Locations. To Edit, you can go to the Workspaces tab.',
                      }),
                    );
                  }
                },
              });
            }}
          />
        )}
      </div>
      <Text size="sm" color="secondary">
        These will be used in the Incident and Observation report. If none are
        provided, it will default to a text input.
      </Text>
      <div className={styles.locationList}>
        {!!locations?.length ? (
          locations.map((loc, index) => (
            <div key={index} className={styles.locationItem}>
              <Text noMargin weight="semiBold">
                {loc}
              </Text>
              <Icon
                hover
                style={{ fontSize: '1rem' }}
                variant="link"
                onClick={() => handleRemoveLocation(index)}
                className={styles.removeButton}
              >
                close
              </Icon>
            </div>
          ))
        ) : (
          <Text color="secondary" noMargin>
            {t(keys.common.NONE)}
          </Text>
        )}
      </div>
      <br />
      <Row className="mb-3">
        <Form.Group as={Col}>
          <div className={styles.header}>
            <Form.Control
              required
              type="text"
              placeholder={t(keys.action.ADD, {
                variable: t(keys.common.LOCATION),
              })}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              onBlur={() => {
                handleAddLocation();
              }}
            />
          </div>
        </Form.Group>
      </Row>

      <Text size="lg" weight="semiBold" noMargin>
        Module Name Details
      </Text>
      <Text size="sm" color="secondary">
        This allows you to customize the names of modules
      </Text>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Text className={styles.label} noMargin weight="bold">
            {t(keys.observations.OBSERVATIONS)}
          </Text>
          <Form.Control
            required
            type="text"
            placeholder={t(keys.action.ENTER, {
              variable: t(keys.settings.OBSERVATIONNAVINFO),
            })}
            value={company?.observationName || undefined}
            onChange={(e) =>
              setCompany({ ...company, observationName: e.target.value })
            }
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Text className={styles.label} noMargin weight="bold">
            {t(keys.incidents.INCIDENTS)}
          </Text>
          <Form.Control
            required
            type="text"
            placeholder={t(keys.action.ENTER, {
              variable: t(keys.settings.INCIDENTNAVINFO),
            })}
            value={company?.incidentName || undefined}
            onChange={(e) =>
              setCompany({ ...company, incidentName: e.target.value })
            }
          />
        </Form.Group>
      </Row>
      {auditsAndCorrectiveActions ? (
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Text className={styles.label} noMargin weight="bold">
              Audits
            </Text>
            <Form.Control
              required
              type="text"
              name="audits"
              placeholder={t(keys.action.ENTER, {
                variable: t(keys.settings.AUDIT_NAVIGATION),
              })}
              value={company?.auditName || undefined}
              onChange={(e) =>
                setCompany({ ...company, auditName: e.target.value })
              }
            />
          </Form.Group>
        </Row>
      ) : null}
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Text className={styles.label} noMargin weight="bold">
            Assessments
          </Text>
          <Form.Control
            required
            type="text"
            name="assessments"
            placeholder={t(keys.action.ENTER, {
              variable: 'Assessment Name',
            })}
            value={company?.assessmentName || undefined}
            onChange={(e) =>
              setCompany({ ...company, assessmentName: e.target.value })
            }
          />
        </Form.Group>
      </Row>
    </div>
  );
}
