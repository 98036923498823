import classNames from 'classnames';
import React, { useMemo } from 'react';
import { getFileSizeString } from '../../utilities';
import findFileIcon from '../../utilities/files';
import UpdateIcon from '@mui/icons-material/Update';
import AlarmIcon from '@mui/icons-material/Alarm';
import moment from 'moment';
import { getLocalTime } from '../../utilities/time';
import { Text } from '../typography';
import styles from './FileCard.module.scss';
import { fileExpiryStatus } from '../../constants/misc';
import FileOptionsMenu from './FileOptionsMenu.jsx';
import { Icon } from '@mui/material';

export default function FileCard({
  file,
  directory,
  onClick,
  selected,
  refetchFiles = () => {},
  openDetails = () => {},
  global,
  className,
  simple = false,
  readOnly = false,
  onDelete,
  onRename,
}) {
  const { name, size, dateCreated, extension } = file || directory;

  const expiryStatus = useMemo(() => {
    if (!file?.expires) {
      return fileExpiryStatus.none;
    }

    const currentDateObj = getLocalTime(moment().toDate().getTime());
    const expiryDateObj = getLocalTime(file.expires);
    const diffDays = expiryDateObj.diff(currentDateObj, 'days');

    if (diffDays < 0) {
      return fileExpiryStatus.expired;
    } else if (diffDays >= 0 && diffDays < 28) {
      return fileExpiryStatus.expiring;
    }
    return fileExpiryStatus.none;
  }, [file?.expires]);

  return (
    <div
      className={classNames(
        styles.container,
        selected && styles.selected,
        className,
      )}
      onClick={onClick}
    >
      <img
        className={styles.icon}
        alt={`${extension} file`}
        src={findFileIcon(
          !file.url ? (global ? 'global-folder' : 'folder') : file.extension,
        )}
      />
      <div className={styles.rightContainer}>
        <div className={styles.header}>
          <div className={styles.iconText}>
            {expiryStatus === fileExpiryStatus.expired && (
              <AlarmIcon fontSize="inherit" className={styles.alarmIcon} />
            )}
            {expiryStatus === fileExpiryStatus.expiring && (
              <UpdateIcon fontSize="inherit" className={styles.updateIcon} />
            )}
            <div className={styles.title}>
              <Text truncate noSelect weight="semiBold" noMargin>
                {name}
              </Text>
            </div>
          </div>
          <FileOptionsMenu
            file={file}
            refetchFiles={refetchFiles}
            openDetails={openDetails}
            simple={simple}
            readOnly={readOnly}
            onDelete={onDelete}
            onRename={onRename}
          />
        </div>
        <div className={styles.footer}>
          <Text noSelect weight="semiBold" color="secondary" noMargin>
            {getLocalTime(dateCreated).format('MM/DD/YYYY')}
          </Text>
          {file?.hidden && (
            <Icon
              baseClassName="material-icons-outlined"
              className={styles.hiddenIcon}
            >
              visibility
            </Icon>
          )}
          {!directory && (
            <Text noSelect weight="semiBold" color="secondary" noMargin>
              {getFileSizeString(size)}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}
