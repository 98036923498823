import React from 'react';
import { Text, View, Image, Link } from '@react-pdf/renderer';
import { styles } from './assessment_styles.js';
import { keys } from '../../translator/translation_keys';
import { inputTypes } from '../../../constants/strings';
import { AssessmentComments, FailReport } from './assessment_comments';
import { VehicleCard } from '../vehicle';
import { Table } from './table';
import { useTranslation } from 'react-i18next';
import noImage from '../../../assets/clipboard_task.png';

export const Answer = ({
  answer,
  children,
  inputType,
  additional,
  question,
}) => {
  const { t } = useTranslation();
  let style = styles.text;
  const numberLimit =
    !!answer && !!children && !!additional && inputType === inputTypes.number;

  if (children === 'PASS' || (numberLimit && !answer?.failed)) {
    style = styles.textPass;
  } else if (
    children === 'FAIL' ||
    (numberLimit && answer?.failed) ||
    answer?.failed
  ) {
    style = styles.textFail;
  }

  let component;
  if (children) {
    switch (inputType) {
      case 'USER_MULTI':
        component = (
          <Text style={style}>
            {answer?.users
              ?.map((user) => `${user.firstName} ${user.lastName}`)
              .join(', ')}
          </Text>
        );
        break;
      case 'MATRIX':
        component = (
          <View>
            <Text style={style}>Impact: {answer?.impact}/5</Text>
            <Text style={style}>Probability: {answer?.probability}/5</Text>
          </View>
        );
        break;
      case 'SIGNATURE_SINGLE':
        component = (
          <View wrap={false}>
            <Text style={style}>{answer?.additional}</Text>
            <Image style={styles.signature} src={answer?.value || noImage} />
          </View>
        );
        break;
      case 'SIGNATURE_MULTI':
        component = (
          <View>
            {children?.split('|').map((child, index) => (
              <View key={child + index} wrap={false}>
                {!!answer?.additional && (
                  <Text key={child + index} style={style}>
                    {answer?.additional?.split('|')[index]}
                  </Text>
                )}
                <Image
                  key={child + index}
                  style={styles.signature}
                  src={child || noImage}
                />
              </View>
            ))}
          </View>
        );
        break;
      case 'IMAGE':
        component = (
          <View style={styles.imageContainer}>
            <Image style={styles.image} src={answer?.value || noImage} />
          </View>
        );
        break;
      case 'IMAGE_MULTI':
        component = (
          <View style={styles.imageContainer}>
            {children?.split('|').map((child, index) => (
              <Image
                key={child + index}
                style={styles.image}
                src={child || noImage}
              />
            ))}
          </View>
        );
        break;
      case 'USER':
        component = (
          <Text
            style={styles.text}
          >{`${answer?.user?.firstName} ${answer?.user?.lastName}`}</Text>
        );
        break;
      case 'DATE':
        component = (
          <Text style={styles.text}>{answer?.value?.split('T')[0]}</Text>
        );
        break;
      case 'SELECT_MULTI':
        component = (
          <Text style={style}>{children?.split('|').join(', ')}</Text>
        );
        break;
      case 'FILE_MULTI':
        component = (
          <View wrap={false}>
            {children?.split('|').map((file, index) => (
              <Link src={file} style={styles.link} key={file + index}>
                {answer?.additional?.split('|')[index]}
              </Link>
            ))}
          </View>
        );
        break;
      case 'FILE_SINGLE':
        component = (
          <Link src={children} style={styles.link}>
            {answer?.additional}
          </Link>
        );
        break;
      case 'TEST':
        component = (
          <View>
            <Text style={style}>
              {children === 'NOT_APPLICABLE' ? 'N/A' : children}
            </Text>
          </View>
        );
        break;
      case 'TABLE':
        component = (
          <View>
            <Table question={question} answer={answer} />
          </View>
        );

        break;
      case 'AUDIO':
        component = (
          <View>
            <Text style={style}>{children}</Text>
            <Link src={children} style={styles.link}>
              {t(keys.assessments.VOICE_RECORDING)}
            </Link>
          </View>
        );
        break;
      case 'ASSET':
        component = <VehicleCard vehicle={answer.vehicle} />;
        break;
      case undefined:
        break;
      default:
        component = <Text style={style}>{children}</Text>;
        break;
    }
  } else {
    component = (
      <Text style={style}>{t(keys.assessments.NO_ANSWER_PROVIDED)}</Text>
    );
  }
  return (
    <View>
      {component}
      <View>
        <FailReport answer={answer} />
        <AssessmentComments answer={answer} />
      </View>
    </View>
  );
};
