import React from 'react';
import {
  Auth0Provider as RealAuth0Provider,
  useAuth0 as realUseAuth0,
} from '@auth0/auth0-react';
import {
  Auth0ProviderMock,
  useAuth0 as mockUseAuth0,
} from '../__mocks__/Auth0ProviderMock';
import CustomSpinner from '../components/CustomSpinner';
import { areWeTestingWithCypress } from '../utilities';

const Auth0ProviderComponent = areWeTestingWithCypress()
  ? Auth0ProviderMock
  : RealAuth0Provider;
const useAuth0 = areWeTestingWithCypress() ? mockUseAuth0 : realUseAuth0;

export { useAuth0 };

export default function Auth0ProviderWithHistory({ children }) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const callback = process.env.REACT_APP_AUTH0_CALLBACK;

  if (!areWeTestingWithCypress() && (!domain || !clientId)) {
    throw new Error(
      'Please set REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID env. variables',
    );
  }

  return (
    <Auth0ProviderComponent
      domain={domain}
      clientId={clientId}
      redirectUri={callback}
      audience={audience}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <Auth0ProviderWrapper>{children}</Auth0ProviderWrapper>
    </Auth0ProviderComponent>
  );
}

function Auth0ProviderWrapper({ children }) {
  const { isLoading } = useAuth0();

  return isLoading ? <CustomSpinner text={'Authorizing...'} /> : children;
}
