import { useModal } from '../../../providers/ModalProvider';
import Modal from '../Modal';
import { modals } from '../../../providers/modals';
import { Text } from '../../typography';
import { Form } from 'react-bootstrap';
import { DropdownText } from '../../dropdowns/DropdownText';
import { incidentTypes } from '../../../utilities/incidents';
import UserMultiSelector from '../../UserMultiSelector';
import { useWorkspace } from '../../../providers/WorkspaceProvider';
import styles from './CreateIncidentModal.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../../utilities/translator/translation_keys';
import Icon from '../../Icon';
import { useIncidents } from '../../../hooks/offline-hooks/incidentHook';
import { showToast } from '../../../graphql/cache/modal';
import QuickFileDropzone from '../../image_uploads/QuickFileDropzone';
import { useState } from 'react';
import findFileIcon, { imageExtensions } from '../../../utilities/files.js';
import ImageWrapper from '../../offline-wrapper-functions/image-wrapper.js';
import { openFileViewer } from '../../../graphql/cache/modal';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

const addImageMutation = loader(
  '../../../pages/incidents/IncidentReport.addImage.graphql',
);

export default function CreateIncidentModal() {
  const { updateModal, closeModal, modalState } = useModal();
  const [loading, setLoading] = useState(false);
  const onHide = () => closeModal({ modalName: modals.createIncident });
  const { workspaceId, workspace } = useWorkspace();
  const {
    show,
    modalTitle,
    type,
    subtype,
    participants,
    observation,
    description,
    dateOfIncident,
    location,
    images = [],
  } = modalState.createIncidentModal;
  const { t } = useTranslation();
  const { addIncident } = useIncidents();
  const update = (variables) =>
    updateModal({
      modalName: modals.createIncident,
      variables,
    });
  const incident = modalState.createIncidentModal;
  const [addImage] = useMutation(addImageMutation);

  return (
    <Modal
      open={show || false}
      title={t(keys.incidents.REPORT_INCIDENT) || modalTitle}
      submitDisabled={!type || !subtype || !description || !dateOfIncident}
      onClose={onHide}
      submitText={t(keys.action.SUBMIT)}
      titleImage={
        <Icon style={{ fontSize: '2rem' }} color="red">
          fmd_bad
        </Icon>
      }
      onSubmit={() => {
        addIncident({
          variables: {
            ...incident,
            participants: participants?.map((user) => user?.id) || null,
            status: 'INITIATED',
            workspaceId,
            observationId: observation?.id ?? null,
          },
        }).then(({ data: { addIncident } }) => {
          if (images?.length) {
            for (let image of images) {
              addImage({
                variables: {
                  incidentId: addIncident.id,
                  description: 'Initial Report',
                  type: 'INITIAL',
                  imageUrl: image.url,
                },
              });
            }
          }
          showToast({
            title: t(keys.incidents.SUBMITTED),
            message: t(keys.incidents.SUBMITTED_MESSAGE),
          });
        });
      }}
      className={styles.maxWidth}
    >
      <div className={styles.field}>
        <Text noMargin weight="semiBold">
          {`${t(keys.incidents.DATE_OF_INCIDENT)}*`}
        </Text>
        <input
          className={styles.date}
          type="datetime-local"
          max={new Date().toISOString().slice(0, 16)}
          value={dateOfIncident}
          onChange={(e) => {
            const newValue = e.target.value;
            update({ dateOfIncident: newValue });
          }}
        />
      </div>
      <div className={styles.field}>
        <Text noMargin weight="semiBold">
          {`${t(keys.common.LOCATION)}*`}
        </Text>
        {!!workspace?.locations ? (
          <DropdownText
            placeholder={'Location'}
            selected={location}
            items={workspace.locations.split('|')}
            onChange={(location) => update({ location })}
            onRemove={() => update({ location: null })}
          />
        ) : (
          <Form.Control
            as="textarea"
            rows={2}
            value={location}
            onChange={(e) => update({ location: e.target.value })}
          />
        )}
      </div>
      <div className={styles.field}>
        <Text noMargin weight="semiBold">
          {`${t(keys.common.DESCRIPTION)}*`}
        </Text>
        <Form.Control
          as="textarea"
          rows={4}
          value={description}
          onChange={(e) => update({ description: e.target.value })}
        />
      </div>
      <DropdownText
        selected={type}
        highlight
        required={true}
        onChange={(newType) => update({ type: newType, subtype: null })}
        items={Object.keys(incidentTypes)}
        title={`${t(keys.incidents.TYPE)}*`}
      />
      <DropdownText
        highlight
        required={true}
        selected={subtype}
        onChange={(newSubtype) => update({ subtype: newSubtype })}
        items={incidentTypes[type]}
        title={`${t(keys.incidents.SUBTYPE)}*`}
        disabled={!type}
      />
      <div className={styles.field}>
        <Text weight="semiBold" noMargin>
          {t(keys.common.PARTICIPANTS)}
        </Text>
        <UserMultiSelector
          selected={participants}
          type="users"
          onUserAdded={(user) => {
            update({ participants: [...participants, user] });
          }}
          onUserRemoved={(user) => {
            update({
              participants: participants?.filter((u) => u.id !== user.id),
            });
          }}
        />
        <Text weight="semiBold" noMargin>
          {t(keys.common.ATTACHMENTS)}
        </Text>
        <QuickFileDropzone
          onSubmit={(image) => update({ images: [...images, image] })}
          loading={loading}
          setLoading={setLoading}
          icon={!!images.length}
          iconText={t(keys.action.ADD, { variable: t(keys.common.FILE) })}
        />
        <div className={styles.images}>
          {images.map(({ extension, url }, index) => {
            const isImage = imageExtensions.includes(extension);
            const src = isImage ? url : findFileIcon(extension);
            const file = {
              fileType: extension,
              url,
              imageUrl: url,
              downloadAllowed: true,
            };
            return (
              <div key={`image-${url}-${index}}`}>
                <ImageWrapper
                  key={extension}
                  alt={`observation-${index}`}
                  style={{ height: '8rem', width: 'auto' }}
                  image={src}
                  className={isImage ? styles.image : styles.file}
                  onClick={() => openFileViewer(file)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Text textAlign="center" color="secondary" weight="semiBold">
        {workspace?.incidentEmergencyMessage ?? ''}
      </Text>
    </Modal>
  );
}
