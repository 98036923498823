import { InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
  typePolicies: {
    Task: {
      fields: {
        assigneeInfo: {
          merge: (_existing = [], incoming) => incoming,
        },
      },
    },
    ActionItem: {
      fields: {
        labels: {
          merge: (_existing, incoming) => incoming,
          read(existing, { readField }) {
            return [...(existing || [])].sort((aRef, bRef) => {
              const aName = readField('name', aRef);
              const bName = readField('name', bRef);

              const aNameLower = aName ? aName.toLowerCase() : '';
              const bNameLower = bName ? bName.toLowerCase() : '';

              return aNameLower.localeCompare(bNameLower);
            });
          },
        },
      },
    },
    Query: {
      fields: {
        notifiers: {
          keyArgs: false,
          merge: (existing = [], incoming) => {
            const deduplicatedIncoming = incoming.filter(
              (notifier) =>
                !existing.some(
                  (existingNotifier) =>
                    existingNotifier.__ref === notifier.__ref,
                ),
            );
            return [...existing, ...deduplicatedIncoming];
          },
        },
      },
    },
  },
});
