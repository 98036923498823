import React, { useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  actionItemModalVar,
  networkModalVar,
  csvImportModalVar,
  defaultCsvImportModalState,
  editVehicleModalVar,
  failureModalVar,
  hideActionItemModal,
  hideEditVehicleModal,
  hideLabelCreationModal,
  labelCreationModalVar,
  hideEditVehicleFeaturesModal,
  editVehicleFeaturesModalVar,
  filterTableModalVar,
  hideTableFilterModal,
  hideAssetFilterModal,
  filterAssetModalVar,
  fullPageImageModalVar,
  hideFullPageImageModal,
  toolboxTalkModalVar,
  hideToolboxTalkModal,
  signatureModalVar,
  analysisModalVar,
  hideAnalysisModal,
  fileViewerVar,
  closeFileViewer,
  dailyReportVar,
} from '../graphql/cache/modal';
import NetworkModal from '../components/modals/NetworkModal';
import ImageUploadModal from '../components/modals/ImageUploadModal';
import TemplateCsvModal from '../components/modals/TemplateCsvModal';
import ActionItemModal from '../components/modals/ActionItemModal';
import LabelCreationModal from '../components/modals/LabelCreationModal';
import FailureModal from '../components/modals/FailureModal';
import EditVehicleModal from '../components/modals/EditVehicleModal';
import AddVehicleFeaturesModal from '../components/modals/AddVehicleFeaturesModal';
import TableFilterModal from '../components/modals/TableFilterModal';
import TextInputModal from '../components/modals/TextInputModal';
import AssetFilterModal from '../components/modals/AssetFilterModal';
import FullPageImageModal from '../components/modals/FullPageImageModal';
import ToolboxTalkModal from '../components/modals/ToolboxTalkModal';
import SignatureModal from '../components/modals/SignatureModal';
import AnalysisModal from '../components/modals/AnalysisModal';
import { backgroundScrollLock } from '../utilities';
import { useQueryParams } from '../hooks/misc';
import { searchParamKeys } from '../constants/strings';
import FileViewerModal from '../components/modals/file_viewer/FileViewerModal';
import DailyReportModal from '../components/modals/DailyReportModal';
import { useAuth0 } from './Auth0Provider';

export default function ConfirmationModalProvider({ children }) {
  const { getParam } = useQueryParams();
  const actionItemId = parseInt(getParam(searchParamKeys.actionItem), 10);
  const showActionItemModal = !!getParam(searchParamKeys.actionItem);
  const networkModalProps = useReactiveVar(networkModalVar);
  const { isAuthenticated } = useAuth0();
  const {
    show: showCsvImportModal,
    template,
    refetch,
  } = useReactiveVar(csvImportModalVar);

  const failureModalState = useReactiveVar(failureModalVar);

  const {
    show: showLabelCreationModal,
    labels,
    onCompleted,
  } = useReactiveVar(labelCreationModalVar);

  const {
    title: signatureModalTitle,
    message: signatureModalMessage,
    variant: signatureModalVariant,
    buttonText: signatureModalButtonText,
    onSubmit: signatureModalOnSubmit,
    onCancel: signatureModalOnCancel,
    show: signatureModalShow,
  } = useReactiveVar(signatureModalVar);

  const { onComplete: actionItemOnComplete } =
    useReactiveVar(actionItemModalVar);

  const { show: showEditVehicleModal, vehicle } =
    useReactiveVar(editVehicleModalVar);
  const {
    show: showEditVehicleFeaturesModal,
    features,
    refetch: refetchVehicleFeatures,
  } = useReactiveVar(editVehicleFeaturesModalVar);

  const { show: showDailyReportModal } = useReactiveVar(dailyReportVar);

  const {
    show: showTableFilterModal,
    filters: tableFilters,
    filterOptions: tableFilterOptions,
    statusOptions,
    priorityOptions,
    onSave: tableFilterOnSave,
    userQuery,
  } = useReactiveVar(filterTableModalVar);

  const {
    show: showAssetFilterModal,
    filters,
    filterOptions,
    onSave,
    assetType,
  } = useReactiveVar(filterAssetModalVar);

  const { show: showFullPageImageModal, image } = useReactiveVar(
    fullPageImageModalVar,
  );

  const { show: showToolboxTalkModal, info } =
    useReactiveVar(toolboxTalkModalVar);
  const {
    show: showAnalysisModal,
    onSubmit: analysisModalOnSubmit,
    inspection,
  } = useReactiveVar(analysisModalVar);

  const {
    show: showFileViewer,
    file: fileViewerFile,
    onDetailsClick: fileViewerDetailsClick,
  } = useReactiveVar(fileViewerVar);

  // This makes me want to 🤮, there must be a better way
  const oneOrMoreModalsAreOpen = useMemo(
    () =>
      showAnalysisModal ||
      showToolboxTalkModal ||
      showFullPageImageModal ||
      showAssetFilterModal ||
      showTableFilterModal ||
      showEditVehicleFeaturesModal ||
      showEditVehicleModal ||
      signatureModalShow ||
      showLabelCreationModal ||
      showCsvImportModal ||
      showActionItemModal ||
      networkModalProps.show ||
      showDailyReportModal,
    [
      networkModalProps.show,
      showActionItemModal,
      showAnalysisModal,
      showCsvImportModal,
      showEditVehicleFeaturesModal,
      showEditVehicleModal,
      showFullPageImageModal,
      showLabelCreationModal,
      showTableFilterModal,
      showToolboxTalkModal,
      showAssetFilterModal,
      signatureModalShow,
      showDailyReportModal,
    ],
  );

  useEffect(() => {
    backgroundScrollLock(oneOrMoreModalsAreOpen);
  }, [oneOrMoreModalsAreOpen]);

  return isAuthenticated ? (
    <>
      <AnalysisModal
        show={showAnalysisModal}
        onCancel={() => hideAnalysisModal()}
        onSubmit={() => analysisModalOnSubmit()}
        inspection={inspection}
      />
      <DailyReportModal show={showDailyReportModal} />
      <NetworkModal {...networkModalProps} />
      <TemplateCsvModal
        show={showCsvImportModal}
        template={template}
        refetch={refetch}
        onHide={() => csvImportModalVar(defaultCsvImportModalState)}
      />
      <ImageUploadModal />
      <TextInputModal />
      <SignatureModal
        title={signatureModalTitle}
        message={signatureModalMessage}
        variant={signatureModalVariant}
        buttonText={signatureModalButtonText}
        onSubmit={signatureModalOnSubmit}
        onCancel={signatureModalOnCancel}
        show={signatureModalShow}
      />
      <FailureModal
        show={failureModalState.show}
        title={failureModalState.title}
        description={failureModalState.description}
        onSubmit={failureModalState.onSubmit}
      />
      <ActionItemModal
        id={actionItemId}
        show={showActionItemModal}
        onHide={() => {
          hideActionItemModal();
          if (
            actionItemOnComplete &&
            typeof actionItemOnComplete === 'function'
          ) {
            actionItemOnComplete();
          }
        }}
      />
      <EditVehicleModal
        vehicle={vehicle}
        show={showEditVehicleModal}
        onHide={() => hideEditVehicleModal()}
      />
      <AddVehicleFeaturesModal
        features={features}
        show={showEditVehicleFeaturesModal}
        onHide={() => hideEditVehicleFeaturesModal()}
        refetch={refetchVehicleFeatures}
      />
      <TableFilterModal
        show={showTableFilterModal}
        onHide={() => hideTableFilterModal()}
        filters={tableFilters}
        filterOptions={tableFilterOptions}
        statusOptions={statusOptions}
        priorityOptions={priorityOptions}
        onSave={tableFilterOnSave}
        userQuery={userQuery}
      />
      <AssetFilterModal
        show={showAssetFilterModal}
        onHide={() => hideAssetFilterModal()}
        filters={filters}
        filterOptions={filterOptions}
        onSave={onSave}
        assetType={assetType}
      />
      <FullPageImageModal
        show={showFullPageImageModal}
        image={image}
        onHide={() => hideFullPageImageModal()}
      />
      <FileViewerModal
        show={showFileViewer}
        onDetailsClick={fileViewerDetailsClick}
        file={fileViewerFile}
        onClose={() => closeFileViewer()}
      />
      <ToolboxTalkModal
        show={showToolboxTalkModal}
        info={info}
        onHide={() => hideToolboxTalkModal}
      />

      <LabelCreationModal
        show={showLabelCreationModal}
        labels={labels}
        onCompleted={onCompleted}
        onHide={() => hideLabelCreationModal()}
      />
      {children}
    </>
  ) : (
    <>{children}</>
  );
}
