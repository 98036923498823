import React, { useMemo, useState } from 'react';
import TabSystem from '../../components/TabSystem';
import { Text } from '../../components/typography';
import styles from './Observations.module.scss';
import { useQueryParams } from '../../hooks/misc';
import { searchParamKeys } from '../../constants/strings';
import ObservationList from './ObservationList.jsx';
import Button from '../../components/Button.jsx';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import { useModal } from '../../providers/ModalProvider.jsx';
import { modals } from '../../providers/modals.js';
import { assessmentTypes } from '../../constants/strings';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { useCurrentUser } from '../../providers/UserProvider.jsx';
import moment from 'moment';
import { loader } from 'graphql.macro';
import { gql } from '@apollo/client';
import { fetchAllData } from '../../utilities/excel_export/table_excel_export.js';
import { useApolloClient } from '@apollo/client';
import { useURLParams } from '../../providers/URLParamProvider.jsx';
import { showToast } from '../../graphql/cache/modal.js';
import { toastVariant } from '../../constants/misc.js';
import { exportToExcel } from '../../components/excel/ExcelExport.jsx';
import {
  excelHeaderLookup,
  formatObservation,
} from '../../utilities/excel_export/data_converter.js';
import format from 'date-fns/format';
import ProgressModal from '../../components/modals/ProgressModal.jsx';
import TemplatesList from '../fleet/TemplatesList.jsx';

const defaultTab = 'observations';
const observationExportQuery = loader('./Observations.fetch.graphql');

export default function Observations({ disabled = false }) {
  const { getParam } = useQueryParams();
  const tab = getParam(searchParamKeys.tab, defaultTab);
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { workspaceId, observationTemplate, isWorkspaceAdmin } = useWorkspace();
  const { user, isAdmin } = useCurrentUser();
  const client = useApolloClient();
  const [isFetchingAllData, setIsFetchingAllData] = useState(false);
  const [progress, setProgress] = useState(0);
  const { filters } = useURLParams();

  const options = useMemo(() => {
    return [
      {
        title:
          user.company?.observationName || t(keys.observations.OBSERVATIONS),
        element: ObservationList,
        key: 'observations',
      },
      ...(isAdmin
        ? [
            {
              title: t(keys.common.TEMPLATES),
              element: TemplatesList,
              key: 'templates',
            },
          ]
        : []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleExport = async () => {
    const query = gql`
      ${observationExportQuery}
    `;
    await fetchAllData({
      query,
      client,
      setIsFetching: setIsFetchingAllData,
      table: 'observations',
      count: 'observationCount',
      filters,
      setProgress,
    }).then((response, errors) => {
      if (errors?.length) {
        showToast({
          title: 'Error Exporting Data',
          variant: toastVariant.danger,
          message: `An error occurred: ${errors[0].message}`,
        });
      }
      if (response?.length) {
        exportToExcel({
          data: response.map((d) => formatObservation(d)),
          sheetName: 'Observation Data',
          exportedBy: `${user.firstName} ${user.lastName}`,
          type: 'Observation',
          fileName: `Observation_Export_${format(new Date(), 'yyyy-MM-dd')}`,
          reportType: ['Report Type', 'Observation Table Export'],
          headerMap: excelHeaderLookup,
        });
      }
    });
  };

  return (
    <div className={disabled ? styles.disabled : null}>
      <div className={styles.headerContainer}>
        <Text size="lg" weight="bold">
          {user.company?.observationName || t(keys.observations.OBSERVATIONS)}
        </Text>
        <div className={styles.buttons}>
          <Button
            value={t(keys.common.NEW)}
            variant="primary"
            icon="add"
            testId="observation"
            size="md"
            onClick={() => {
              switch (tab) {
                case 'observations':
                  openModal({
                    modalName: modals.observation,
                    variables: {
                      participants: [],
                      workspaceId,
                      templateId: observationTemplate?.id,
                      questions:
                        observationTemplate?.categories[0]?.questions || [],
                      dateTime: new moment().format('YYYY-MM-DD HH:mm'),
                    },
                  });
                  break;
                case 'templates': {
                  openModal({
                    modalName: modals.createTemplate,
                    variables: {
                      types: [assessmentTypes.observation],
                      type: assessmentTypes.observation,
                      allWorkspaces: true,
                      options: {
                        allWorkspaces: false,
                        secondSight: false,
                        approvalRequired: false,
                      },
                    },
                  });
                  break;
                }
                default:
                  throw new Error(`Invalid type ${tab}`);
              }
            }}
          />
          {tab === defaultTab && isWorkspaceAdmin && (
            <Button
              outlined
              icon="file_upload"
              value={t(keys.action.EXPORT)}
              onClick={handleExport}
              testId="observation"
            />
          )}
        </div>
      </div>
      <TabSystem type={tab} options={options} />
      <ProgressModal
        show={isFetchingAllData}
        progress={progress}
        title="Exporting Data"
        message="One moment while we fetch your data. Please don't close this window!"
        icon="import_export"
      />
    </div>
  );
}
