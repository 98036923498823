import { useQuery, useMutation } from '@apollo/client';
import { Icon } from '@mui/material';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Text } from '../typography';
import NotificationCard from './NotificationCard';
import styles from './NotificationList.module.scss';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { Spinner, Form } from 'react-bootstrap';

const notifierQuery = loader('./NotificationList.graphql');
const updateNotifierMutation = loader('./NotificationList.update.graphql');

export default function NotificationList({
  show,
  onHide,
  refetchCount,
  containerRef,
  expand = false,
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectCards, setSelectCards] = useState(false);
  const [showFullText, setShowFullText] = useState(expand);
  const page = 1;
  const pageSize = 50;
  const { t } = useTranslation();

  const {
    refetch,
    loading,
    data: { notifiers = [] } = {},
  } = useQuery(notifierQuery, {
    skip: !show,
    variables: {
      page,
      pageSize,
    },
    fetchPolicy: 'no-cache',
    onCompleted: () => refetchCount(),
  });

  const [updateNotifier] = useMutation(updateNotifierMutation);

  // option is the field to be updated ie. isArchived or seen
  const updateNotifiers = (selected, option) => {
    updateNotifier({
      variables: { notifierIds: selected, [option]: true },
    }).then(() => refetch());
    setSelectCards(false);
    setSelected([]);
    refetchCount();
  };

  const closeNotificationList = () => {
    onHide();
    setSelectAll(false);
    setSelectCards(false);
    setSelected([]);
  };

  useEffect(() => {
    if (!show) {
      setSelectAll(false);
      setSelectCards(false);
      setSelected([]);
    }
  }, [show]);

  return show ? (
    <div
      ref={containerRef}
      className={classNames(
        styles.container,
        show ? styles.show : styles.hide,
        showFullText ? styles.extendedContainer : styles.collapsedContainer,
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <div>
        <div className={styles.header}>
          <div className={styles.options}>
            <Text
              className={classNames(
                styles.option,
                notifiers?.length ? styles.enabled : '',
                selectCards && styles.activated,
              )}
              size="sm"
              weight="semiBold"
              noMargin
              onClick={() => notifiers?.length && setSelectCards(!selectCards)}
            >
              {t(keys.action.SELECT)}
            </Text>
            <Text
              className={classNames(
                styles.option,
                selectCards ? styles.enabled : '',
              )}
              size="sm"
              weight="semiBold"
              noMargin
              onClick={() => selectCards && updateNotifiers(selected, 'seen')}
            >
              {t(keys.notifications.MARK_AS_READ)}
            </Text>
            <Text
              className={classNames(
                styles.option,
                selectCards ? styles.enabled : '',
              )}
              size="sm"
              weight="semiBold"
              noMargin
              onClick={() =>
                selectCards && updateNotifiers(selected, 'isArchived')
              }
            >
              {t(keys.action.DELETE)}
            </Text>
          </div>

          <div className={styles.buttonContainer}>
            <Icon
              onClick={() => setShowFullText(!showFullText)}
              fontSize="0.5rem"
            >
              {showFullText ? 'expand_less' : 'expand_more'}
            </Icon>
            <Icon
              fontSize="inherit"
              baseClassName="material-icons-outlined"
              className={styles.closeButton}
              onClick={closeNotificationList}
            >
              close
            </Icon>
          </div>
        </div>
        {selectCards && (
          <div className={styles.selectAll}>
            <Form.Check
              checked={selectAll}
              value={selectAll}
              onChange={(e) => {
                e.stopPropagation();
                if (selectAll) {
                  setSelected([]);
                } else {
                  setSelected(notifiers.map(({ id }) => id));
                }
                setSelectAll(!selectAll);
              }}
            />
            <Text noMargin weight="semiBold" color="secondary" size="sm">
              {t(keys.notifications.SELECT_ALL)}
            </Text>
          </div>
        )}
      </div>
      <div
        className={classNames(
          styles.notificationContainer,
          showFullText
            ? styles.extendedNotificationContainer
            : styles.collapsedNotificationContainer,
        )}
      >
        {notifiers?.length ? (
          notifiers
            .filter((n) => n)
            .map((notifier) => {
              return (
                <NotificationCard
                  onClick={() => {
                    updateNotifiers([notifier?.id], 'seen');
                    closeNotificationList();
                  }}
                  key={notifier.id}
                  notifier={notifier}
                  showFullText={showFullText}
                  selectActivated={selectCards}
                  onSelected={() => {
                    if (selected?.includes(notifier?.id)) {
                      setSelected(selected.filter((n) => n !== notifier.id));
                    } else {
                      setSelected([...selected, notifier?.id]);
                    }
                  }}
                  selected={selected.includes(notifier?.id)}
                />
              );
            })
        ) : loading ? (
          <div className={styles.secondaryContainer}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className={styles.secondaryContainer}>
            <Text noMargin weight="semiBold" color="secondary">
              {t(keys.action.NOT_FOUND, {
                variable: t(keys.notifications.NOTIFICATIONS),
              })}
            </Text>
          </div>
        )}
      </div>
    </div>
  ) : null;
}
