import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from '@mui/material';
import styles from './ActionItemModal.module.scss';
import { Text } from '../typography';
import { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import messageGraphic from '../../assets/message.svg';
import { loader } from 'graphql.macro';
import {
  priorityStringLookup,
  statusStringLookup,
} from '../action_items/action_item_utilities';
import SimpleUserCard from '../SimpleUserCard';
import { useQueryParams } from '../../hooks/misc';
import Button from '../Button';
import ActionItemComment from '../action_items/ActionItemComment';
import UserSelector from '../UserSelector';
import LabelSelector from '../LabelSelector';
import { actionItemModalVar, showToast } from '../../graphql/cache/modal';
import { toastLength, toastVariant } from '../../constants/misc';
import { useNavigate } from 'react-router-dom';
import { paths, searchParamKeys, userRoles } from '../../constants/strings';
import { getRoute } from '../../constants/strings';
import AddAssetModal from './AddAssetModal';
import { useCurrentUser } from '../../providers/UserProvider';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { getLocalTime } from '../../utilities/time';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import { t } from 'i18next';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useModal } from '../../providers/ModalProvider.jsx';
import { modals } from '../../providers/modals.js';
import ActionItemHistory from '../action_items/ActionItemHistory.jsx';
import UserMultiSelector from '../UserMultiSelector.jsx';
import QuickFileDropzone from '../image_uploads/QuickFileDropzone.jsx';
import { useChangeWorkspace } from '../../hooks/misc';
import SimpleFileCard from '../files/SimpleFileCard.jsx';
import { checkDueDateColor } from '../tables/MTableComponents.jsx';
import { useIsMobile } from '../../hooks/misc';
import { customColors } from '../../utilities/misc.js';
import { useWatcher } from '../../providers/WatcherProvider.jsx';

const addActionItemLabelMutation = loader(
  './ActionItemModal.createActionItemLabel.graphql',
);
const deleteActionItemLabelMutation = loader(
  './ActionItemModal.deleteActionItemLabel.graphql',
);
const actionItemQuery = loader('./ActionItemModal.fetch.graphql');
const commentMutation = loader('./ActionItemModal.createComment.graphql');
const actionItemCreateMutation = loader(
  './ActionItemModal.createActionItem.graphql',
);

const actionItemUpdateMutation = loader(
  './ActionItemModal.updateActionItem.graphql',
);
const deleteActionItemMutation = loader(
  './ActionItemModal.deleteActionItem.graphql',
);

const removeParticipantMutation = loader(
  './ActionItemModal.removeParticipant.graphql',
);
const addParticipantMutation = loader(
  './ActionItemModal.addParticipant.graphql',
);

const addAttachmentMutation = loader('./ActionItemModal.addAttachment.graphql');
const deleteAttachmentMutation = loader(
  './ActionItemModal.deleteAttachment.graphql',
);

const emptyState = {
  title: '',
  description: '',
  priority: 'LOW',
  status: 'TODO',
  comments: [],
  labels: [],
  attachments: [],
  participants: [],
  watchers: [],
};

function Comments({
  state,
  activity,
  commentsEndRef,
  addComment,
  comment,
  user,
  setComment,
}) {
  const isMobile = useIsMobile();

  return (
    <div className={styles.commentBoxContainer}>
      <div className={styles.commentBox}>
        <img
          className={styles.userAvatar}
          src={user?.avatarUrl}
          alt="your avatar"
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            addComment();
          }}
        >
          <Form.Control
            className={styles.commentInput}
            as={'textarea'}
            value={comment}
            placeholder={t(keys.action_items.COMMENT)}
            data-cy="input-action-item-modal-comment"
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
        </Form>
        {isMobile ? (
          <Icon
            testid="action-item-comment"
            onClick={() => {
              if (comment.length && state.id) {
                addComment();
              }
            }}
            sx={{
              fontSize: '1rem',
              color:
                comment.length && state.id
                  ? customColors.BLUE
                  : customColors.SECONDARY_LIGHT,
            }}
          >
            send_rounded
          </Icon>
        ) : (
          <Button
            testid="action-item-comment"
            onClick={() => {
              addComment();
            }}
            size="sm"
            disabled={!comment?.length || !state.id}
            className={styles.sendButton}
            value={t(keys.action_items.SEND)}
          />
        )}
      </div>
      <div className={styles.commentContainer}>
        {activity?.length ? (
          <div className={styles.commentList}>
            {activity.map((event) =>
              event.__typename === 'ActionItemComment' ? (
                <ActionItemComment
                  key={`${event.__typename}:${event.id}`}
                  comment={event}
                />
              ) : (
                <ActionItemHistory
                  key={`${event.__typename}:${event.id}`}
                  event={event}
                />
              ),
            )}
          </div>
        ) : (
          <div className={styles.emptyStateContainer}>
            <img
              alt="letter"
              className={styles.messageGraphic}
              src={messageGraphic}
            />
            <Text
              className={styles.noBreak}
              size="lg"
              color="accentPrimary"
              textAlign="center"
              weight="bold"
              noSelect
              noMargin
            >
              {t(keys.action_items.CONVERSATION)}
            </Text>
            <Text
              className={styles.noBreak}
              size="md"
              weight="semiBold"
              textAlign="center"
              noMargin
              noSelect
            >
              {t(keys.action_items.MESSAGE)}
            </Text>
          </div>
        )}
        <div ref={commentsEndRef} />
      </div>
    </div>
  );
}

const Attachments = ({ attachments, onDelete, readOnly }) => {
  const [hideExtras, setHideExtras] = useState(true);

  const { amountHidden, attachmentsArray } = useMemo(() => {
    if (attachments.length > 3 && hideExtras) {
      return {
        amountHidden: attachments?.length - 4,
        attachmentsArray: attachments.slice(0, 4),
      };
    } else {
      return {
        amountHidden: 0,
        attachmentsArray: attachments,
      };
    }
  }, [attachments, hideExtras]);

  return (
    <div>
      <div className={styles.attachmentList}>
        {attachmentsArray.map((attachment, index) => {
          const file = {
            ...attachment,
            downloadAllowed: true,
            fileType: attachment.extension,
            url: attachment.url,
          };
          return (
            <SimpleFileCard
              key={`${attachment.id}-${index}`}
              file={file}
              fileName={attachment.name}
              extension={attachment.extension}
              onDelete={() => onDelete(attachment)}
              readOnly={readOnly}
            />
          );
        })}
      </div>
      {attachments.length > 4 && (
        <Text
          className={styles.showMoreAttachments}
          noMargin
          color="accentPrimary"
          size="sm"
          weight="semiBold"
          onClick={() => setHideExtras(!hideExtras)}
        >
          {hideExtras
            ? t(keys.action_items.HIDDEN_ATTACHMENTS, { count: amountHidden })
            : t(keys.action.HIDE)}
        </Text>
      )}
    </div>
  );
};

export default function ActionItemModal({
  id = null,
  show = false,
  onHide,
  onComplete = () => {},
}) {
  // Hooks
  const { deleteParam, upsertParam } = useQueryParams();
  const { user } = useCurrentUser();
  const { workspace, isWorkspaceAdmin, workspaceVehicles } = useWorkspace();
  const { openConfirmationModal, closeModal } = useModal();
  const commentsEndRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { changeWorkspace } = useChangeWorkspace();
  const { FollowButton } = useWatcher();

  // State
  const [state, setState] = useState(emptyState);
  const [comment, setComment] = useState('');
  const [isEditingDescription, setIsEditingDescription] = useState(
    state.id ? false : true,
  );
  const [showDetails, setShowDetails] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [assetModalOpen, setAssetModalOpen] = useState(false);
  const [closeLabels, setCloseLabels] = useState(false);
  const [editingDate, setEditingDate] = useState(false);
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [editingOutcome, setEditingOutcome] = useState(false);

  // Mutations
  const [deleteActionItem] = useMutation(deleteActionItemMutation, {
    update(cache, { data }) {
      if (data && data.deleteActionItem) {
        const deletedItemId = data.deleteActionItem.id;
        cache.modify({
          fields: {
            actionItems(existingActionItems = [], { readField }) {
              return existingActionItems?.filter(
                (actionItemRef) =>
                  deletedItemId !== readField('id', actionItemRef),
              );
            },
          },
        });
      }
    },
  });
  const [addActionItemLabel] = useMutation(addActionItemLabelMutation);
  const [deleteActionItemLabel] = useMutation(deleteActionItemLabelMutation);
  const [createComment] = useMutation(commentMutation);
  const [addActionItem] = useMutation(actionItemCreateMutation);
  const [updateActionItem] = useMutation(actionItemUpdateMutation);
  const [addParticipant] = useMutation(addParticipantMutation);
  const [removeParticipant] = useMutation(removeParticipantMutation);
  const [addAttachment] = useMutation(addAttachmentMutation);
  const [deleteAttachment] = useMutation(deleteAttachmentMutation);

  // Queries
  const { data, refetch } = useQuery(actionItemQuery, {
    skip: isNaN(id) || !show,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: `${id}`,
    },
  });

  // useMemos
  const activity = useMemo(() => {
    const activityEvents = data?.events || [];
    const activityComments = state?.comments || [];

    const mappedEvents = activityEvents.map((event) => ({
      ...event,
      moment: getLocalTime(event.dateCreated),
    }));

    const mappedComments = activityComments.map((comment) => ({
      ...comment,
      moment: getLocalTime(comment.dateCreated),
    }));

    const mergedArray = [
      ...(showDetails ? mappedEvents : []),
      ...mappedComments,
    ];

    mergedArray.sort((a, b) => a.moment - b.moment);

    return mergedArray;
  }, [data?.events, state?.comments, showDetails]);

  const readOnly = useMemo(() => {
    if (!id || !data?.actionItems?.length || !user?.id) {
      return false;
    }

    const [actionItem] = data?.actionItems;

    if (
      isWorkspaceAdmin ||
      actionItem.creator.id === user.id ||
      actionItem.assignee?.id === user.id
    ) {
      return false;
    }

    return true;
  }, [id, data?.actionItems, user.id, isWorkspaceAdmin]);

  const canSave = useMemo(() => {
    return state.title && state.title !== 'Title';
  }, [state.title]);

  const isDraft = useMemo(() => !state?.id, [state]);

  useEffect(() => {
    if (!readOnly && !id) {
      setIsEditingDescription(true);
      setIsEditingTitle(true);
    } else {
      setIsEditingDescription(false);
      setIsEditingTitle(false);
    }
  }, [id, readOnly, show]);

  useEffect(() => {
    if (!data?.actionItems?.length) {
      return;
    }

    const {
      actionItems: [actionItem],
    } = data;

    setState(actionItem);
  }, [data]);

  useEffect(() => {
    if (
      show &&
      !!state.workspace &&
      workspace?.id !== state?.workspace?.id &&
      !isNaN(state?.workspace?.id)
    ) {
      changeWorkspace(state.workspace.id);
    }
  }, [changeWorkspace, show, state, workspace]);

  // util functions
  const addComment = () => {
    createComment({
      variables: {
        actionItemId: state.id,
        text: comment,
      },
    }).then(() => {
      refetch({ id: `${state.id}` }).then(() => {
        setComment('');
      });
    });
  };

  const onClose = () => {
    deleteParam(searchParamKeys.actionItem);
    onComplete();
    setIsEditingDescription(false);
    setIsEditingTitle(false);
    setComment('');
    setState(emptyState);
    onHide();
    deleteParam(searchParamKeys.actionItem);
  };

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (isEditingTitle && !!titleRef.current) {
      titleRef.current.focus();
    }
    if (isEditingDescription && !!descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, [isEditingDescription, isEditingTitle]);

  return show ? (
    <div className={styles.show}>
      <div className={styles.background}>
        <div
          className={classNames(
            styles.container,
            show ? styles.showContainer : styles.hideContainer,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.mainHeader}>
            <>
              {isEditingTitle || !state.id ? (
                <Form.Control
                  className={styles.titleInput}
                  type="text"
                  required
                  data-cy="input-action-item-title"
                  placeholder={t(keys.action_items.TITLE_PLACEHOLDER)}
                  value={state.title}
                  ref={titleRef}
                  onChange={(e) =>
                    setState({
                      ...state,
                      title: e.target.value,
                    })
                  }
                  onBlur={() => {
                    if (state.id) {
                      updateActionItem({
                        variables: {
                          id: state.id,
                          title: state.title,
                        },
                      });
                      setIsEditingTitle(false);
                    }
                  }}
                />
              ) : (
                <div className={styles.titleContainer}>
                  <Text size="lg" noSelect weight="bold" noMargin>
                    {state.title}
                  </Text>
                  {!readOnly && !isEditingTitle && (
                    <Icon
                      baseClassName="material-icons-outlined"
                      data-cy="action-item-title-edit"
                      className={styles.iconButton}
                      onClick={() => {
                        setIsEditingTitle(true);
                      }}
                      sx={{ fontSize: '1.2rem' }}
                    >
                      edit
                    </Icon>
                  )}
                </div>
              )}
            </>
            <Icon
              baseClassName="material-icons-outlined"
              className={styles.closeIcon}
              data-cy="close-action-item-modal"
              onClick={() => {
                setCloseLabels(true);
                if (!state.id) {
                  openConfirmationModal({
                    title: t(keys.action_items.CLOSE_ACTION_ITEM),
                    description: t(keys.action_items.CLOSE_ACTION_ITEM_MESSAGE),
                    variant: 'danger',
                    buttonText: t(keys.action_items.CLOSE),
                    onSubmit: () => {
                      onClose();
                    },
                  });
                } else {
                  onClose();
                }
              }}
            >
              close
            </Icon>
          </div>
          <div className={styles.splitContainer}>
            <div className={styles.leftContainer}>
              <div className={styles.subButtonContainer}>
                <div className={styles.watchButton}>
                  <FollowButton
                    type={'actionItem'}
                    id={state?.id}
                    userId={user?.id}
                    disabled={isDraft}
                  />
                </div>
                <div className={styles.privateCheck}>
                  <Form.Check
                    disabled={isDraft || readOnly}
                    onChange={(e) => {
                      if (state?.id) {
                        state?.private
                          ? openConfirmationModal({
                              title: t(keys.action_items.MAKE_PUBLIC_TITLE),
                              description: t(
                                keys.action_items.MAKE_PUBLIC_TEXT,
                              ),
                              variant: 'warning',
                              buttonText: t(keys.action.CONFIRM),
                              onSubmit: () => {
                                updateActionItem({
                                  variables: {
                                    id: state.id,
                                    private: false,
                                  },
                                });
                              },
                            })
                          : updateActionItem({
                              variables: {
                                id: state.id,
                                private: e.target.checked,
                              },
                            });
                      } else {
                        setState({ ...state, private: e.target.checked });
                      }
                    }}
                    checked={state?.private}
                  />
                  <Text
                    noMargin
                    weight="semiBold"
                    color={isDraft || readOnly ? 'secondaryLight' : 'regular'}
                  >
                    {t(keys.action_items.PRIVATE_TITLE)}
                  </Text>
                </div>
              </div>
              {state?.private && (
                <Text noMargin color="secondary" size="sm">
                  {t(keys.action_items.PRIVATE_TEXT)}
                </Text>
              )}
              <div className={styles.subheaderContainer}>
                <Text size="md" color="secondary" noSelect noMargin>
                  {t(keys.common.DESCRIPTION)}
                </Text>
                {!readOnly && !isEditingDescription ? (
                  <Icon
                    baseClassName="material-icons-outlined"
                    className={styles.iconButton}
                    data-cy="action-item-description-edit"
                    onClick={() => {
                      setIsEditingDescription(true);
                    }}
                    sx={{ fontSize: '1.2rem' }}
                  >
                    edit
                  </Icon>
                ) : null}
              </div>
              <div className={styles.leftSection}>
                {isEditingDescription ? (
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Form.Control
                      className={styles.descriptionInput}
                      as="textarea"
                      rows={5}
                      data-cy="input-action-item-description"
                      placeholder={t(keys.action_items.DESCRIPTION_PLACEHOLDER)}
                      value={state.description}
                      ref={descriptionRef}
                      onChange={(e) =>
                        setState({
                          ...state,
                          description: e.target.value,
                        })
                      }
                      onBlur={() => {
                        if (state.id) {
                          updateActionItem({
                            variables: {
                              id: state.id,
                              description: state.description,
                            },
                          });
                        }
                        setIsEditingDescription(false);
                      }}
                    />
                  </Form>
                ) : (
                  <div className={styles.readOnlyDescription}>
                    <Text weight="semiBold" size="md" noMargin>
                      {!!state?.description
                        ? `${state.description}`
                        : t(keys.common.NONE)}
                    </Text>
                  </div>
                )}
              </div>
              <div className={styles.subheaderContainer}>
                <Text size="md" color="secondary" noSelect noMargin>
                  Action Taken
                </Text>
                {!readOnly && !editingOutcome && (
                  <Icon
                    sx={{ fontSize: '1.2rem' }}
                    className={styles.iconButton}
                    onClick={() => setEditingOutcome(true)}
                  >
                    edit
                  </Icon>
                )}
              </div>
              {editingOutcome ? (
                <Form.Control
                  as="textarea"
                  value={state.outcome}
                  rows={5}
                  onChange={(e) => {
                    setState({ ...state, outcome: e.target.value });
                  }}
                  onBlur={() =>
                    updateActionItem({
                      variables: {
                        id,
                        outcome: state.outcome,
                        status:
                          state.status === statusStringLookup.TODO.key
                            ? statusStringLookup.IN_PROGRESS.key
                            : undefined,
                      },
                    }).then(() => setEditingOutcome(false))
                  }
                />
              ) : (
                <div className={styles.readOnlyDescription}>
                  <Text weight="semiBold" noMargin>
                    {state.outcome ?? 'No answer provided'}
                  </Text>
                </div>
              )}
              {isDraft ? (
                <></>
              ) : (
                <>
                  <div className={styles.subheaderContainer}>
                    <div className={styles.linkContainer}>
                      <Text size="md" color="secondary" noSelect noMargin>
                        {t(keys.common.ATTACHMENTS)}
                      </Text>
                      <Icon
                        baseClassName="material-icons-outlined"
                        className={styles.attachmentIcon}
                        sx={{ fontSize: '1.2rem' }}
                      >
                        attach_file
                      </Icon>
                    </div>
                  </div>
                  <div className={styles.leftSection}>
                    <div className={styles.attachmentContainer}>
                      {!readOnly && (
                        <QuickFileDropzone
                          setLoading={setLoadingAttachment}
                          className={styles.fileDrop}
                          loading={loadingAttachment}
                          icon={state?.attachments?.length > 0}
                          iconText={t(keys.action.ADD, {
                            variable: t(keys.assessments.ATTACHMENT),
                          })}
                          size="md"
                          onSubmit={({ url, name, extension }) =>
                            addAttachment({
                              variables: {
                                actionItemId: state.id,
                                url,
                                extension,
                                name,
                              },
                            })
                          }
                        />
                      )}
                      {state?.attachments?.length > 0 ? (
                        <Attachments
                          attachments={state?.attachments}
                          readOnly={readOnly}
                          onDelete={(attachment) =>
                            openConfirmationModal({
                              title: t(keys.action.DELETE_VARIABLE, {
                                variable: t(keys.common.FILE),
                              }),
                              description: t(keys.action.DELETE_CONFIRMATION, {
                                variable: `${t(keys.common.FILE)} ${
                                  attachment.name
                                }`,
                              }),
                              variant: 'danger',
                              onSubmit: () => {
                                deleteAttachment({
                                  variables: {
                                    attachmentId: attachment.id,
                                    actionItemId: state?.id,
                                  },
                                });
                              },
                            })
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.subheaderContainer}>
                    <Text size="md" color="secondary" noMargin noSelect>
                      {`${t(keys.action_items.ACTIVITY)} ${
                        activity.length ? `(${activity.length})` : ''
                      }`}
                    </Text>
                    <Text
                      size="sm"
                      className={styles.link}
                      weight="semiBold"
                      color="accentPrimary"
                      noMargin
                      noSelect
                      onClick={() => {
                        setShowDetails(!showDetails);
                      }}
                    >
                      {showDetails
                        ? t(keys.action.HIDE_VARIABLE, {
                            variable: t(keys.common.DETAILS),
                          })
                        : t(keys.action.SHOW_VARIABLE, {
                            variable: t(keys.common.DETAILS),
                          })}
                    </Text>
                  </div>
                </>
              )}
              {state.id && (
                <div className={styles.leftSection}>
                  <Comments
                    state={state}
                    activity={activity}
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                    comment={comment}
                    commentsEndRef={commentsEndRef}
                    addComment={addComment}
                    setComment={setComment}
                    user={user}
                  />
                </div>
              )}
            </div>
            <div className={styles.rightContainer}>
              <div className={styles.rightSection}>
                <Text
                  weight="semiBold"
                  noMargin
                  noSelect
                  color="secondary"
                  size="sm"
                  className={styles.subtitle}
                >
                  {t(keys.common.STATUS)}
                </Text>
                <Dropdown
                  className={styles.dropdown}
                  onSelect={(val) => {
                    const newStatus = val.slice(1);
                    setState({ ...state, status: newStatus });

                    if (state.id) {
                      updateActionItem({
                        variables: {
                          id: state.id,
                          status: newStatus,
                        },
                      });
                    }
                  }}
                >
                  <Dropdown.Toggle
                    variant={statusStringLookup[state.status].variant}
                    disabled={readOnly}
                    id="dropdown-basic"
                  >
                    {t(statusStringLookup[state.status].title)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className={styles.dropdown} href="#BACKLOG">
                      {t(keys.common.BACKLOG)}
                    </Dropdown.Item>
                    <Dropdown.Item className={styles.dropdown} href="#TODO">
                      {t(keys.common.TODO)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.dropdown}
                      href="#IN_PROGRESS"
                    >
                      {t(keys.common.IN_PROGRESS)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.dropdown}
                      onClick={() => setEditingOutcome(true)}
                      href="#DONE"
                    >
                      {t(keys.common.COMPLETED)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.dropdown}
                      href="#DUPLICATE"
                    >
                      {t(keys.action_items.DUPLICATE)}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className={styles.rightSection}>
                <Text
                  size="sm"
                  weight="semiBold"
                  noSelect
                  color="secondary"
                  noMargin
                  className={styles.subtitle}
                >
                  {t(keys.common.ASSIGNEE)}
                </Text>
                {!readOnly ? (
                  <UserSelector
                    className={styles.avatarBox}
                    disableRemove={readOnly}
                    type="assignee"
                    selected={state.assignee}
                    onClose={() => {
                      openConfirmationModal({
                        title: 'Change Assignee',
                        description:
                          'Are you sure you want to change this assignee? This will notify the selected user.',
                        variant: 'danger',
                        onSubmit: () => {
                          setState({ ...state, assignee: undefined });
                          updateActionItem({
                            variables: { id: state.id, assigneeId: null },
                          });
                        },
                      });
                    }}
                    onSelectionChanged={(user) => {
                      setState({
                        ...state,
                        assigneeId: user ? user.id : null,
                        assignee: user,
                      });
                      if (state.id) {
                        updateActionItem({
                          variables: {
                            id: state.id,
                            assigneeId: user ? user.id : 0,
                          },
                        });
                      }
                    }}
                    usersToLeaveOut={state?.participants}
                  />
                ) : state.assignee ? (
                  <>
                    <SimpleUserCard user={state.assignee} />
                  </>
                ) : (
                  <Text>{t(keys.common.NONE)}</Text>
                )}
              </div>
              <div className={styles.rightSection}>
                <Text
                  size="sm"
                  noSelect
                  weight="semiBold"
                  noMargin
                  className={styles.subtitle}
                  color="secondary"
                >
                  {t(keys.common.PARTICIPANTS)}
                </Text>
                {!isDraft ? (
                  !readOnly ? (
                    <UserMultiSelector
                      readOnly={readOnly}
                      selected={state?.participants}
                      onUserAdded={(user) =>
                        addParticipant({
                          variables: {
                            actionItemId: state?.id,
                            userId: user?.id,
                          },
                        })
                      }
                      onUserRemoved={(user) =>
                        removeParticipant({
                          variables: {
                            actionItemId: state?.id,
                            userId: user?.id,
                          },
                        })
                      }
                      usersToLeaveOut={state?.assignee ? [state?.assignee] : []}
                    />
                  ) : state?.participants?.length > 0 ? (
                    state?.participants.map((user) => (
                      <SimpleUserCard user={user} />
                    ))
                  ) : (
                    <Text noMargin>{t(keys.common.NONE)}</Text>
                  )
                ) : (
                  <Text noMargin color="secondary" size="sm">
                    {t(keys.action_items.CREATION_PARTICIPANTS)}
                  </Text>
                )}
              </div>
              <div className={styles.rightSection}>
                <Text
                  size="sm"
                  noSelect
                  weight="semiBold"
                  noMargin
                  className={styles.subtitle}
                  color="secondary"
                >
                  {t(keys.action_items.PRIORITY)}
                </Text>
                <Dropdown
                  className={styles.dropdown}
                  onSelect={(val) => {
                    const newPriority = val.slice(1);

                    setState({
                      ...state,
                      priority: newPriority,
                    });

                    if (state.id) {
                      updateActionItem({
                        variables: {
                          id: state.id,
                          priority: newPriority,
                        },
                      });
                    }
                  }}
                >
                  <Dropdown.Toggle
                    disabled={readOnly}
                    variant={
                      state.priority === 'LOW'
                        ? 'success'
                        : state.priority === 'MEDIUM'
                        ? 'warning'
                        : 'danger'
                    }
                    id="dropdown-basic"
                    value={state.priority}
                  >
                    {t(priorityStringLookup[state.priority]?.name)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className={styles.dropdown} href="#HIGH">
                      {t(keys.action_items.HIGH)}
                    </Dropdown.Item>
                    <Dropdown.Item className={styles.dropdown} href="#MEDIUM">
                      {t(keys.action_items.MEDIUM)}
                    </Dropdown.Item>
                    <Dropdown.Item className={styles.dropdown} href="#LOW">
                      {t(keys.action_items.LOW)}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className={styles.rightSection}>
                <div className={styles.dueDate}>
                  <Text
                    size="sm"
                    weight="semiBold"
                    color="secondary"
                    noSelect
                    noMargin
                    className={styles.subtitle}
                  >
                    {t(keys.action_items.DUE_DATE)}
                  </Text>
                  {!editingDate ? (
                    <div className={styles.header}>
                      <Text
                        noMargin
                        weight="semiBold"
                        color={checkDueDateColor({
                          dueDate: state.dueDate,
                          status: state.status,
                        })}
                      >
                        {state?.dueDate
                          ? state.id
                            ? getLocalTime(state.dueDate).format('MMM D, YYYY')
                            : moment(state.dueDate).format('MMM D, YYYY')
                          : t(keys.common.NONE)}
                      </Text>
                      {!readOnly && (
                        <Icon
                          className={styles.iconButton}
                          baseClassName="material-icons-outlined"
                          sx={{ fontSize: '1.2rem' }}
                          onClick={() => setEditingDate(!editingDate)}
                        >
                          edit
                        </Icon>
                      )}
                    </div>
                  ) : (
                    <>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          disabled={readOnly}
                          slotProps={{
                            textField: {
                              error: false,
                              InputProps: {
                                style: {
                                  height: '2.5rem',
                                  backgroundColor: 'white',
                                  width: '100%',
                                },
                              },
                            },
                            actionBar: {
                              actions: ['clear'],
                            },
                          }}
                          sx={{ width: '100%' }}
                          value={moment(parseInt(state.dueDate))}
                          onChange={(newMoment) => {
                            setState({
                              ...state,
                              dueDate: newMoment,
                            });
                            if (state?.id) {
                              updateActionItem({
                                variables: {
                                  id: state.id,
                                  dueDate: newMoment,
                                },
                              });
                            }
                            setEditingDate(false);
                          }}
                        />
                      </LocalizationProvider>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.rightSection}>
                <Text
                  weight="semiBold"
                  noMargin
                  className={styles.subtitle}
                  size="sm"
                  color="secondary"
                >
                  {t(keys.common.WORKSPACE)}
                </Text>
                <Text noMargin weight="semiBold">
                  {!state?.id ? workspace.title : state?.workspace?.title}
                </Text>
              </div>

              <div className={styles.rightSection}>
                <div className={styles.addAssetButton}>
                  <Text
                    size="sm"
                    weight="semiBold"
                    color="secondary"
                    noMargin
                    className={styles.subtitle}
                  >
                    {t(keys.common.ASSET)}
                  </Text>
                  {!readOnly && (
                    <Icon
                      onClick={() => setAssetModalOpen(true)}
                      color="primary"
                      className={styles.hover}
                    >
                      add
                    </Icon>
                  )}
                </div>

                {!!state?.vehicle ? (
                  <div className={styles.linkContainer}>
                    <Text
                      color="accentPrimary"
                      weight="semiBold"
                      noMargin
                      hover
                      onClick={() => {
                        onClose();
                        navigate(
                          getRoute(
                            workspace?.id,
                            paths.asset,
                            state.vehicle?.id,
                          ),
                        );
                      }}
                    >
                      {`${state.vehicle?.make} ${state.vehicle?.model}
                          ${state.vehicle?.unitNumber || ''}`}
                    </Text>
                    {!readOnly && (
                      <Icon
                        baseClassName="material-icons-outlined"
                        onClick={() => {
                          if (state?.id) {
                            updateActionItem({
                              variables: { id: state.id, vehicleId: null },
                            });
                          } else {
                            setState({ ...state, vehicle: null });
                          }
                        }}
                        sx={{ fontSize: '1.2rem' }}
                        className={styles.hover}
                        color="primary"
                      >
                        close
                      </Icon>
                    )}
                  </div>
                ) : (
                  <Text noMargin>{t(keys.common.NONE)}</Text>
                )}
                {assetModalOpen ? (
                  <AddAssetModal
                    onHide={() => setAssetModalOpen(false)}
                    show={assetModalOpen}
                    target={state}
                    targetKey={'id'}
                    title={t(keys.action_items.ADD_ASSET)}
                    addAsset={(asset) => {
                      if (state?.id) {
                        updateActionItem({
                          variables: { id: state.id, vehicleId: asset.id },
                        });
                      } else {
                        setState({ ...state, vehicle: asset });
                      }
                    }}
                    assets={workspaceVehicles}
                  />
                ) : null}
              </div>

              {!!state.inspection ? (
                <div className={styles.rightSection}>
                  <Text
                    size="sm"
                    weight="semiBold"
                    noMargin
                    className={styles.subtitle}
                    color="secondary"
                  >
                    {t(keys.common.ASSESSMENT)}
                  </Text>
                  <Text
                    size="md"
                    color="accentPrimary"
                    weight="semiBold"
                    noMargin
                    hover
                    onClick={() => {
                      onClose();
                      navigate(
                        getRoute(
                          workspace.id,
                          paths.assessment,
                          state.inspection?.id,
                        ),
                      );
                    }}
                  >
                    {state.inspection?.template.title}
                  </Text>
                </div>
              ) : null}
              <div className={styles.rightSection}>
                <Text
                  size="sm"
                  weight="semiBold"
                  noSelect
                  noMargin
                  className={styles.subtitle}
                  color="secondary"
                >
                  {t(keys.common.LABELS)}
                </Text>
                <LabelSelector
                  testId="action-item-modal"
                  onClose={closeLabels}
                  setOnClose={setCloseLabels}
                  readOnly={
                    user?.role !== userRoles.admin &&
                    user?.id !== state?.creator?.id
                  }
                  className={styles.labelBox}
                  selected={state.labels}
                  onSelectionChanged={(action, label) => {
                    if (action === 'ADD') {
                      const newLabels = [...state.labels, label].sort(
                        (a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        },
                      );
                      setState({
                        ...state,
                        labels: newLabels,
                      });
                      if (state.id) {
                        addActionItemLabel({
                          variables: {
                            actionItemId: state.id,
                            labelId: label.id,
                          },
                        });
                      }
                    } else if (action === 'REMOVE') {
                      setState({
                        ...state,
                        labels: state.labels.filter((l) => l.id !== label.id),
                      });
                      if (state.id) {
                        deleteActionItemLabel({
                          variables: {
                            labelId: label.id,
                            actionItemId: state.id,
                          },
                        }).then(() => {
                          refetch();
                        });
                      }
                    }
                  }}
                />
              </div>
              {state.id && (
                <>
                  <div className={styles.rightSection}>
                    {state?.dateCreated && (
                      <Text noMargin size="sm" color="secondary">{`${t(
                        keys.common.CREATED_BY,
                      )} ${state?.creator?.firstName} ${
                        state?.creator?.lastName
                      } ${t(keys.common.ON)} ${getLocalTime(
                        state.dateCreated,
                      ).format('MMM Do, YYYY')}`}</Text>
                    )}
                    {state?.dateCompleted &&
                      (state?.completer ? (
                        <Text noMargin size="sm" color="secondary">{`${t(
                          keys.common.COMPLETED_BY,
                        )} ${state?.completer?.firstName} ${
                          state?.completer?.lastName
                        } ${t(keys.common.ON)} ${getLocalTime(
                          state.dateCompleted,
                        ).format('MMM Do, YYYY')}`}</Text>
                      ) : (
                        <Text noMargin size="sm" color="secondary">{`${t(
                          keys.common.COMPLETED,
                        )} ${t(keys.common.ON)} ${getLocalTime(
                          state.dateCompleted,
                        ).format('MMM Do, YYYY')}`}</Text>
                      ))}
                  </div>
                  <Button
                    disabled={
                      !isWorkspaceAdmin && user?.id !== state.creator?.id
                    }
                    variant="danger"
                    outlined
                    onClick={() =>
                      openConfirmationModal({
                        title: t(keys.action.DELETE_VARIABLE, {
                          variable: t(keys.common.ACTION_ITEM),
                        }),
                        description: t(keys.action.DELETE_CONFIRMATION, {
                          variable: `${t(keys.common.ACTION_ITEM)} ${
                            state.title
                          }`,
                        }),
                        variant: 'danger',
                        onSubmit: () => {
                          deleteActionItem({
                            variables: { id: state.id },
                          }).then(() => {
                            setState(emptyState);
                            closeModal({ modalName: modals.confirmation });
                            onClose();
                          });
                          setTimeout(() => {
                            showToast({
                              title: state.title,
                              message: t(keys.action.DELETED, {
                                variable: state.title,
                              }),
                              variant: toastVariant.info,
                              time: toastLength.md,
                            });
                          }, 500);
                        },
                      })
                    }
                    className={styles.actionButton}
                    icon="delete"
                    value={t(keys.common.DELETE, { variable: null })}
                  />
                  {state.status !== statusStringLookup.DONE.key && (
                    <Button
                      value={t(keys.common.COMPLETE)}
                      icon="check_circle"
                      variant="success"
                      onClick={() => {
                        updateActionItem({
                          variables: {
                            id: state.id,
                            status: statusStringLookup.DONE.key,
                          },
                        });
                      }}
                    />
                  )}
                </>
              )}

              {!state.id ? (
                <div className={styles.createButtonDisplay}>
                  <Button
                    className={styles.createButton}
                    disabled={!canSave}
                    testId="action-item-modal"
                    value={t(keys.action.CREATE)}
                    onClick={() => {
                      addActionItem({
                        variables: {
                          ...state,
                          type: actionItemModalVar().type,
                          labels: state.labels.map((l) => l.id),
                          workspaceId: workspace?.id,
                          vehicleId: state?.vehicle?.id,
                        },
                      }).then(({ data }) => {
                        if (!data?.addActionItem?.id) {
                          showToast({
                            title: t(keys.action_items.OOPS),
                            message: t(keys.action_items.PROBLEM),
                            variant: 'danger',
                            time: toastLength.lg,
                          });
                        } else {
                          setState({
                            ...state,
                            id: data.addActionItem.id,
                          });

                          upsertParam(
                            searchParamKeys.actionItem,
                            data.addActionItem.id,
                          );

                          setCloseLabels(true);
                        }
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
