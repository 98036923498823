import { useOnlineStatus } from './offline-misc';
import { useApolloClient, useMutation } from '@apollo/client';
import { useCachedMutations } from './offline-misc';
import { v4 as uuidv4 } from 'uuid';
import { loader } from 'graphql.macro';
import { useCurrentUser } from '../../providers/UserProvider';
import { cleanIndexedDB } from '../../utilities';
import moment from 'moment';

const createIncidentMutation = loader(
  '../../components/modals/provider_modals/CreateIncidentModal.graphql',
);

const incidentQuery = loader(
  '../../pages/incidents/IncidentReport.fetch.graphql',
);

const incidentListQuery = loader(
  '../../pages/incidents/IncidentTable.fetch.graphql',
);

const userFragment = loader('./User.userFragment.graphql');

const incidentUpdateQuery = loader(
  '../../pages/incidents/IncidentReport.update.graphql',
);

export const useIncidents = () => {
  const online = useOnlineStatus();
  const { addMutation } = useCachedMutations();
  const { user: currentUser } = useCurrentUser();
  const [createIncident] = useMutation(createIncidentMutation);
  const [mutateIncident] = useMutation(incidentUpdateQuery);
  const client = useApolloClient();

  const addIncident = async (options) => {
    if (online) {
      return createIncident(options);
    } else {
      const uuid = uuidv4().toString();

      const participantsFragementData = options.variables.participants.map(
        (participant) => {
          const participantData = client.readFragment({
            id: `User:${participant}`,
            fragment: userFragment,
          });

          return participantData;
        },
      );

      const mockResult = {
        incidents: [
          {
            __typename: 'Incident',
            id: `${uuid}`,
            type: options.variables.type,
            subtype: options.variables.subtype,
            status: options.variables.status,
            dateOfIncident: null,
            location: null,
            weather: null,
            description: null,
            rootCause: null,
            notes: null,
            lostTime: options.variables.lostTime,
            lostTimeHrs: 0,
            cost: null,
            title: options.variables.title,
            timeOfDay: null,
            nearMiss: options.variables.nearMiss,
            impact: null,
            probability: null,
            potentialFatality: options.variables.potentialFatality,
            closerSignatureUrl: null,
            closedAt: null,
            vehicle: null,
            closer: null,
            employee: null,
            externalEmployee: null,
            dateCreated: null,
            participants: participantsFragementData,
            dateModified: null,
            creator: currentUser,
            labels: [],
            actionItems: [],
            observation: null,
            reviewers: [],
          },
        ],
      };

      await addMutation(
        createIncidentMutation,
        cleanIndexedDB(options),
        uuid,
        mockResult,
        [''],
        'addIncident.id',
      );

      client.writeQuery({
        query: incidentQuery,
        variables: { id: `${uuid}` },
        data: mockResult,
      });

      const filters = [
        {
          field: 'workspaceId',
          operator: 'or',
          value: [`${options.variables.workspaceId}`, 'null'],
        },
      ];

      const incidentList = await client.readQuery({
        query: incidentListQuery,
        variables: {
          options: {
            page: 1,
            pageSize: 11,
            sort: [{ field: 'dateCreated', order: 'desc' }],
            filters,
          },
        },
      });

      const mockresultforIncidentList = {
        __typename: 'Incident',
        id: `${uuid}`,
        type: options.variables.type,
        subtype: options.variables.subtype,
        status: options.variables.status,
        dateOfIncident: null,
        location: null,
        weather: null,
        description: null,
        rootCause: null,
        lostTime: options.variables.lostTime,
        lostTimeHrs: 0,
        cost: null,
        title: options.variables.title,
        nearMiss: options.variables.nearMiss,
        impact: null,
        probability: null,
        potentialFatality: options.variables.potentialFatality,
        dateCreated: null,
        dateModified: null,
        creator: currentUser,
        externalEmployee: null,
        private: true,
      };

      client.writeQuery({
        query: incidentListQuery,
        variables: {
          options: {
            page: 1,
            pageSize: 11,
            sort: [{ field: 'dateCreated', order: 'desc' }],
            filters,
          },
        },
        data: {
          incidents: [
            mockresultforIncidentList,
            ...(incidentList?.incidents || []),
          ],
          incidentsCount: incidentList?.incidentsCount + 1 || 1,
        },
      });

      return new Promise((resolve, reject) => {
        const responseData = {
          data: {
            addIncident: {
              id: uuid,
            },
          },
        };
        resolve(responseData);
      });
    }
  };

  const updateIncident = async (options) => {
    if (online) {
      return mutateIncident(options);
    } else {
      const { incidents: [incident] = [{}] } = client.readQuery({
        query: incidentQuery,
        variables: { id: options.variables.id.toString() },
      });

      if (options.variables.dateOfIncident) {
        options.variables.dateOfIncident = moment(
          options.variables.dateOfIncident,
        ).format('YYYY-MM-DD HH:mm:ss.SSS Z');
      }

      const mockResult = {
        updateIncident: {
          id: options.variables.id.toString(),
          dateOfIncident: incident.dateOfIncident,
          location: incident.location,
          weather: incident.weather,
          description: incident.description,
          rootCause: incident.rootCause,
          lostTime: incident.lostTime,
          notes: incident.notes,
          nearMiss: incident.nearMiss,
          lostTimeHrs: incident.lostTimeHrs,
          cost: incident.cost,
          timeOfDay: incident.timeOfDay,
          probability: incident.probability,
          impact: incident.impact,
          potentialFatality: incident.potentialFatality,
          externalEmployee: incident.externalEmployee,
          employee: incident.employee,
          vehicle: incident.vehicle,
          __typename: 'Incident',
          ...options.variables,
        },
      };

      const updatedIncident = {
        ...incident,
        ...mockResult.updateIncident,
      };

      await addMutation(
        incidentUpdateQuery,
        cleanIndexedDB(options),
        null,
        mockResult.updateIncident,
        [''],
        '',
      );

      client.writeQuery({
        query: incidentQuery,
        variables: { id: options.variables.id.toString() },
        data: {
          incidents: [updatedIncident],
        },
      });

      return new Promise((resolve, reject) => {
        const responseData = {
          data: mockResult,
        };
        resolve(responseData);
      });
    }
  };

  return {
    addIncident,
    updateIncident,
  };
};
